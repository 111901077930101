<section class="pt-2" [ngBusy]="busy">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" style="padding-left: 5px; padding-right: 5px;">
        <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
          <div class="card-body">
            <div class="row align-items-center">
              <div *ngIf="user?.profile_img" class="col-lg-4 col-md-3 text-md-start text-center">
                <img src="{{filesUrl + user?.profile_img?.path}}" alt=""
                  class="avatar avatar-large rounded-circle shadow d-block mx-auto">
              </div>
              <div class="userLetters avatar m-auto shadow-lg rounded-pill" *ngIf="!user?.profile_img">
                <p *ngIf="user?.first_name && user?.last_name">{{firstLetters(user)}}</p>
              </div>

              <div class="col-lg-8 col-md-9">
                <div class="row align-items-end">
                  <div class="col-md-9 text-md-start text-center mt-4 mt-sm-0">
                    <h3 class="title mb-0"> {{user?.first_name}} {{user?.last_name}} </h3>
                    <small class="text-muted h6 me-2" *ngIf="chatInfo.length > 0">
                      <div *ngFor="let info of chatInfo">
                        {{info}}
                      </div>
                    </small>
                  </div>
                  <div class="col-md-5 text-md-end text-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section mt-2" style="background-color: white; padding-left: 5px; padding-right: 5px; padding-top: 0px;">
  <div class="container mt-lg-3">
    <div class="row">
      <div class="col-lg-12 col-12 rounded shadow">
        <div class="pb-4">
          <div class="row">
            <div class="col-md-6 mt-4">
              <h5> {{'PersonalDetails' | translate}} </h5>
              <div class="mt-4">
                <div class="d-flex align-items-center mb-3">
                  <a href="javascript:void(0)" class="form-icons">
                    <i class="fa fa-envelope"></i>
                  </a>
                  <div class="flex-1">
                    <h6 class="text-primary mb-0 pb-0">Email :</h6><a href="javascript:void(0)" class="text-muted">
                      {{user?.email}} </a>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <a href="javascript:void(0)" class="form-icons">
                    <i class="fa fa-phone"></i>
                  </a>
                  <div class="flex-1">
                    <h6 class="text-primary mb-0 pb-0"> {{'HOME.PHONE' | translate}} :</h6><a href="javascript:void(0)"
                      class="text-muted">
                      {{user?.mobile}} </a>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <a href="javascript:void(0)" class="form-icons">
                    <i class="fa fa-globe"></i>
                  </a>
                  <div class="flex-1">
                    <h6 class="text-primary mb-0 pb-0"> {{'HOME.COUNTRY' | translate}} :</h6><a
                      href="javascript:void(0)" class="text-muted">
                      {{user?.country?.name_fr}} </a>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <a href="javascript:void(0)" class="form-icons">
                    <i class="fa fa-venus-double"></i>
                  </a>
                  <div class="flex-1" *ngIf="user">
                    <h6 class="text-primary mb-0 pb-0"> {{'HOME.GENDER' | translate}} :</h6><a href="javascript:void(0)"
                      class="text-muted">
                      {{getValueTranslate(user?.gender)}} </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-4 pt-2 pt-sm-0" *ngIf="user?.responses?.length > 0">
              <h5> {{'OtherDetails' | translate}} </h5>
              <div class="mt-4">
                <div *ngFor="let response of user?.responses">
                  <div class="d-flex align-items-center mb-3"
                    *ngIf="(response.response != null && response.response != '') || response.values.length>0">
                    <div class="flex-1">
                      <h6 class="text-primary mb-0 pb-0"> {{ response?.form_input?.label }} :</h6>
                      <a href="javascript:void(0)" class="text-muted"
                        *ngIf="response.response != null && response.response != '' && response.form_input.type.name !== 'image' && !response.response.includes('http')">
                        {{response.response}}
                      </a>
                      <a href="{{response.response}}" class="text-muted"
                        *ngIf="response.response != null && response.response != '' && response.form_input.type.name !== 'image' && response.response.includes('http')">
                        {{response.response}}
                      </a>
                      <a href="javascript:void(0)" class="text-muted" *ngIf="response.form_input.type.name === 'image'">
                        <a class="lienimage" href="{{filesUrl + response.response}}">
                          {{'FilesImages' | translate}}
                        </a>
                      </a>
                      <a href="javascript:void(0)" class="text-muted"
                        *ngIf="(response.response != null || response.response != '') && (response.form_input.form_input_type_id== 6 || response.form_input.form_input_type_id== 8)">
                        <div *ngFor="let value of response.values; let i=index">
                          {{value.val.value}}
                        </div>
                      </a>
                      <a href="javascript:void(0)" class="text-muted"
                        *ngIf="(response.response != null || response.response != '') && (response.form_input.form_input_type_id== 7 || response.form_input.form_input_type_id== 9)">
                        <div>
                          {{ response.values[0]?.val.value }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4 pt-2 d-flex justify-content-center">
        <a (click)="redirectToEdit()" class="btn btn-primary edit-btn">
          {{'SHARED.EDIT' | translate}}
        </a>
      </div>
    </div>
  </div>
</section>