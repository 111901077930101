import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import { StorageService } from '../storage.service';
import { User } from '../../models/user';
import { UserService } from '../user.service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  user: User
  url;
  congressId: number;

  constructor(private authService: AuthService,
    private router: Router,
    private storageService:StorageService, 
    private userService: UserService,
    private route: ActivatedRoute) {}

  canActivate(route:ActivatedRouteSnapshot,state: RouterStateSnapshot) {
     this.url = state.url.indexOf("?") !== -1 ? state.url.slice(0, state.url.indexOf("?")): state.url;
    if (this.authService.isLoggedIn())
        return true;
    // Pass token in url params to login auto
    this.storageService.write(StorageService.REDIRECT_URL,this.url);
    this.congressId = route.params.congressId;
    if (this.congressId) {
      this.storageService.write(StorageService.CONGRESS_ID, this.congressId);
    }
    if (route.queryParams.token) {
      this.userService.getUserByEmailAndQrCode(route.queryParams.token, route.queryParams.email).subscribe((data: any) => {
        this.user = data.user;
        this.userService.loggedUser = data.user;
        this.storageService.write(StorageService.USER_KEY, data.user);
        this.storageService.write(StorageService.USER_TOKEN_KEY, data.token);
        let url = this.storageService.read(StorageService.REDIRECT_URL)
        if (url) {
          this.router.navigate([url]);
          this.storageService.remove(StorageService.REDIRECT_URL);
        }
        return true;
      }, (error) => {
        if (this.congressId) {
          this.router.navigate(['/landingpage/' + this.congressId + '/login']);
        }
        return false;
      });
    }
    this.storageService.write(StorageService.REDIRECT_URL,this.url);
    if (this.congressId) {
      this.router.navigate(['/landingpage/' + this.congressId + '/login']);
    }
    return false;
  }
}
