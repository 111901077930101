import { Component, Input, OnInit } from '@angular/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {
  @Input() configLandingPage: ConfigLandignPage;
  @Input() lang: string;
  url_streaming: string;
  constructor() { }

  ngOnInit(): void {
    this.url_streaming = this.lang == 'ar' ? this.configLandingPage.live_link_ar : this.configLandingPage.live_link;
  }

  ngOnChanges() {
    this.url_streaming = this.lang == 'ar' ? this.configLandingPage.live_link_ar : this.configLandingPage.live_link;
  }

  getValueTranslate(columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }
}
