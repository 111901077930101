// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://dev.app.eventizer.io/#',
  baseUrl: 'https://dev.api.eventizer.io/api',
  meetUrl: 'meet.eventizer.io',
  isMultiSubscriptionByCongressAllowed: true,
  backOfficeUrl: 'https://dev.organizer.eventizer.io/#',
  pwaUrl: 'https://dev.participant.eventizer.io/#',
  baseUrlFrontOffice: "https://dev.app.eventizer.io/#",
  numberAffilie: '1221687013',
  baseUrlSPS: 'https://clictopay.monetiquetunisie.com',
  firebase: {
    apiKey: 'AIzaSyByyz8HRzYLQHK3ttyGd40l88fsMm0GXAo',
    authDomain: 'eventizer-dev.firebaseapp.com',
    databaseURL: 'https://eventizer-dev.firebaseio.com',
    projectId: 'eventizer-dev',
    storageBucket: 'eventizer-dev.appspot.com',
    messagingSenderId: '1060423498159',
    appId: '1:1060423498159:web:34411e703b2643aa63575a',
    measurementId: 'G-KSJDWZYJ5X'
  },
  gaTrackingID: 'G-300P5SMYJT',
  filesUrl: 'https://eventizer-dev.s3.eu-west-3.amazonaws.com/',
  elasticProxyUrl: ' https://proxy.elastic.tracking.master.vayetek.com',
  agoraAPPID  : 'a6c74d414176481c83a31e5927539abc',
  elasticRUMUrl: 'https://apm.prod.master.vayetek.com',
  environment: 'dev',
  imgUrl: 'https://eventizer-dev.s3.eu-west-3.amazonaws.com/',
  changerates:"b211af0d2ae74f7c8d6692d73ff4a75a",
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
