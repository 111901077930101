<div class="float-right mr-3" *ngIf="congress?.congress_type_id == 1 || forSponsors">
  <span *ngIf="user?.price > 0" style="float: right;">
    {{'PaiceToPay' | translate }} : {{user?.price}} {{currency ? currency : congress?.config?.currency_code}}
  </span>
  <br>
  <span *ngIf="user?.price > 0 && convertedPrice && (currency  ==='TND') && (user.country_id  !='TUN')">
    {{'ApproximatePrice' | translate }} : {{ convertedPrice | number:'1.0-2'}} €
  </span>
  </div>
 
<div class="next-prev">
  <button type="button" class="prev" (click)="emitPreviousStep()">
    <i class="fa-solid fa-arrow-left"></i>
    {{'PreviousStep' | translate}}
  </button>
  <button type="button" id="step2btn" class="next" (click)="emitNextStep()">
    {{'Next' | translate}}
    <i class="fa-solid fa-arrow-right"></i></button>
</div>