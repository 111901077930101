import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {Credentials} from '../models/credentials';
import {StorageService} from './storage.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';


@Injectable()
export class AuthService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  login(credentials: Credentials) {
    const url = environment.baseUrl + '/auth/login/user';
    return this.http.post<any>(url, credentials)
      .pipe(catchError(this.handleErrors));
  }


  isLoggedIn() {
    return this.storageService.read(StorageService.USER_TOKEN_KEY) != null;
  }

  requestReset(body): Observable<any> {
    return this.http.post(`${environment.baseUrl}/guest/forgotPassword`, body);
  }

  refreshToken() {
    return this.http.get(environment.baseUrl + "/auth/refreshtoken");
  }

   logout() {
    return this.http.get(environment.baseUrl + "/auth/logout");
  }
}
