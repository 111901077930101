import { Congress } from './../../models/congress';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils';

@Component({
  selector: 'app-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.css']
})
export class ItemEventComponent implements OnInit {
  @Input() congress: Congress;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl; 
  lang: string;

  constructor(private translate: TranslateService) { 
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
  }

  getValueTranslate(columnName: string) {
    return Utils.getValueTranslate(this.lang, this.congress, columnName);
  }

}
