import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../services/storage.service';
import { Utils } from '../../utils';

declare const jQuery: any;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  @Input()
  maxFileCount?: number;

  @Input()
  previewFiles: string[] = [];

  @Input()
  url? = environment.baseUrl + '/files/upload-resource';

  @Input()
  showRemove: boolean;

  @Input()
  showUpload: boolean;

  @Input()
  disabled = false;

  @Input()
  translateEmptyFiles?: string;

  @Input()
  uploadType: string;

  @Input()
  profilePic = false;

  @Input()
  accept = null;

  @Input()
  allowedFileExtensions = [];

  @Output()
  onUpload: EventEmitter<any> = new EventEmitter();

  @Output()
  onDelete: EventEmitter<any> = new EventEmitter();

  @Output()
  onPhotoUploaded: EventEmitter<any> = new EventEmitter();
  className: string;
  uploadCrop: any;

  constructor(private storageService: StorageService ) { 
    this.className = Utils.getRandomString();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.uploadType === 'file-input') {
      this.initUploadFile();
    }
  }

  initUploadFile() {
    const baseContext = this;
    Utils.initializeUploadFile(this.url, this.storageService.read(StorageService.USER_TOKEN_KEY), "."+ this.className, 
    this.showRemove, this.showUpload, this.maxFileCount, this.previewFiles, Utils.getInitialPreviewConfig(this.previewFiles,this.storageService.read(StorageService.USER_TOKEN_KEY)), this.translateEmptyFiles, this.allowedFileExtensions);
    jQuery('.'+ baseContext.className).change(function () {
    }).on('fileuploaded', function (event, data) {
        baseContext.onUpload.emit(data.response);
    }).on('filedeleted', function (event, key, jqXHR, data) {
        baseContext.onDelete.emit(jqXHR);
    });
  }

  setPhoto(event, path = null) {
    if (this.uploadType === 'croppie') {
      let file = null;
      if (this.uploadCrop !== undefined) {
        this.uploadCrop.croppie('destroy');
      }
      if (event !== null) {
        file = event.target.files[0]
      }      
      this.uploadCrop = Utils.initCroppie('upload-demo', file, path);
    if (!path) {
      this.onPhotoUploaded.emit(this.uploadCrop);
    }
  } else if (this.uploadType === 'normal') {
    this.onPhotoUploaded.emit(event.target.files[0]);
  }
  }

}
