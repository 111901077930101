<div class="online-room">
  <h1>{{seo?.first_title}}</h1>
  <div *ngIf="!url_streaming && !access.recorder_url" style="text-align:center;">
    <div id="meet">
      <app-ui-videoconf *ngIf="agoraOptions?.token" [channelName]="roomName" [token]="agoraOptions.token" [uid]="agoraOptions.uid" [congressId]="congressId" [congress]="congress"></app-ui-videoconf>
    </div>
  </div>
  <div *ngIf="access.recorder_url">
    <video class="center-video" controls>
      <source src="{{access.recorder_url}}" type="video/mp4">
    </video>
  </div>

  <div *ngIf="!access.recorder_url && url_streaming" class="page">
    <iframe [src]="url_streaming | safe :'resourceUrl'" class="full-page" allowfullscreen>
    </iframe>
  </div>
</div>
<app-tracking [congressId]="congressId" [action]="'ENTRY_LEAVE'" [type]="type" [name]="name"></app-tracking>
<app-online-room-quiz [congressId]="congressId" [accessId]="accessId" [user]="user"></app-online-room-quiz>
