import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-validation-devis',
  templateUrl: './validation-devis.component.html',
  styleUrls: ['./validation-devis.component.css']
})
export class ValidationDevisComponent {

  busy: Subscription;
  lang;
  sponsorId: number;
  congressId: number;
  status: number;

  constructor(private route: ActivatedRoute, private translate: TranslateService) {
    this.sponsorId = parseInt(this.route.snapshot.paramMap.get('sponsorId'), 0);
    this.congressId = parseInt(this.route.snapshot.paramMap.get('congressId'), 0);
    this.status = parseInt(this.route.snapshot.paramMap.get('status'), 0);
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
  }

  getImageSource(): string {
    if (this.status === 2) {
      return 'assets/images/succes.png';
    } else if (this.status === 3) {
      return 'assets/images/fail.png';
    }
    return '';
  }

}
