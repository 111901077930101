import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  @Input() configLandingPage: ConfigLandignPage;
  @Output() toRegister: EventEmitter<null> = new EventEmitter();
  filesUrl = environment.filesUrl ;
  @Input() lang: string;
  dirMode: string;
  imgUrl = environment.imgUrl; 
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  redirectToRegister() {
    if (this.configLandingPage.prp_link) {
      window.open(this.configLandingPage.prp_link);
    } else {
      this.toRegister.emit();
    }
}
  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  transformHtml(htmlTextWithStyle): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
