<ul class="list-group sortable-author">
  <div class="list-group-item mt-3 box2 list-authors" *ngFor="let item of items; let i= index" id="{{i}}">
    <img class="img-responsive img-author fit-image float-left pt-3" [src]="imgUrl+'DtAsM3eVt7t96HeYkNamr0VknqyRZH9N8EpFnyXP.svg'" alt="img">
    <div class="d-flex">
      <div class="d-flex flex-column pt-3 right-border pb-3 pr-4">

        <div class="d-flex flex-row">
          <div class="select1 ml-4">
            <input name="last_name{{i}}" placeholder="{{'SHARED.LAST_NAME'|translate}}"
              class="list-group-item form-control " type="text" required [(ngModel)]="item.last_name"
              [ngClass]="{'is-invalid': isSubmitted && !item.last_name}" [ngClass]="{'valid-green-box': lastName && lastName.valid && !lastName.untouched
                 && item.last_name!== '' && item.last_name!== undefined}" #lastName="ngModel">
          </div>

          <div class="select1 ">
            <input name="first_name{{i}}" placeholder="{{'SHARED.FIRST_NAME'|translate}}"
              class="list-group-item form-control " type="text" [(ngModel)]="item.first_name"
              [ngClass]="{'is-invalid': isSubmitted && !item.first_name}" [ngClass]="{'valid-green-box': firstName && firstName.valid && !firstName.untouched &&
                  item.first_name!== '' && item.first_name!== undefined}" #firstName="ngModel">
          </div>
        </div>

        <div class="select2 mb-2 mt-2 ml-4">
          <input name="email{{i}}" placeholder="Email" class="list-group-item form-control " type="email"
            [(ngModel)]="item.email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            [ngClass]="{'is-invalid': isSubmitted && !item.email}" [ngClass]="{'valid-green-box': email && email.valid && !email.untouched &&
               item.email!== '' && item.email!== undefined}" #email="ngModel">
        </div>
        <div class="select2 mb-2 mt-2 ml-4">
          <input name="rank{{i}}" placeholder="{{'HOME.Rank'|translate}}" class="list-group-item form-control "
            type="number" [(ngModel)]="item.rank" [ngClass]="{'is-invalid': isSubmitted && !item.rank}" #rank="ngModel">
        </div>

        <div class="select2 mb-2 mt-2 ml-4">
          <app-ng-select2  [placeHolder]="'ChooseService'" [objects]="services"
            [stringOption]="'label'" [idOption]="'service_id'"   [(selection)]="item.services"  [multiple]="true">
          </app-ng-select2>
          <input *ngIf="item.services?.includes('-1')" placeholder="{{'SUBMISSION.OtherService'|translate}}"
            [(ngModel)]="item.otherService" class="list-group-item form-control mt-2" type="text">
        </div>
        <div class="select2 mb-2 mt-2 ml-4">
          <app-ng-select2 [(selection)]="item.etablissement_id" [defaultValue]="'ChooseEstablishment'"
            [objects]="establishments" [stringOption]="'label'" [idOption]="'etablissement_id'">
          </app-ng-select2>
          <input *ngIf="item.etablissement_id == -1" placeholder="{{'SUBMISSION.OtherEstablishment'|translate}}"
            class="list-group-item form-control mt-2" type="text" [(ngModel)]="item.otherEstablishment">
        </div>
        </div>
      
      <div class="ml-auto p-2 ">
        <a class="fa fa-remove pull-right" aria-hidden="true" (click)="removeItem(i)"> </a>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button (click)="addNewItem()" class="btn btn-warning button ">
      {{'SUBMISSION.ADD_CO_AUTHOR'|translate}}
      <div class="fa fa-plus"></div>
    </button>
  </div>
</ul>