import { StorageService } from 'src/app/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Meeting } from '../models/meeting';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeetingService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  requestMeeting(meeting: Meeting, user_receive_id: number, congress_Id: number) {
    const url = environment.baseUrl + `/meetings/add?congress_id=` + congress_Id + `&user_received_id=` + user_receive_id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, meeting, {
      headers: headers,
    });
  }


  getUserMeetingById(user_id: number, congress_id: string, meetingStatus: number,perPage:number=null,page:number=null) {
    const url = environment.baseUrl + '/meetings/'+ congress_id + '?status=' + meetingStatus +'&user_id=' + user_id + `&page=${page}&perPage=${perPage}`;
    return this.http.get<Meeting[]>(url);
  }

  updateMeeting(meetingId: Number, status: number) {
    const url = environment.baseUrl + `/meetings/` + meetingId + `/update-status`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put(url, {status : status}, {
      headers: headers,
    });
  }
  makeOrganizerPresent(meeting_id) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/statMeetingOrganizer';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, { is_organizer_present: 1
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
  
  makeParticipantPresent(meeting_id,user_id) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/statMeetingParticipant';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, 
    { is_participant_present: 1,
      user_id:user_id

    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getFixTables(congressId: string, page: number, perPage: number, search: string, filterBy: number){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/meetings/` + congressId + `/get-fixs-tables?page=${page}&perPage=${perPage}&search=${search}&filterBy=${filterBy}`;
    return this.http.get(url, {
      headers: headers
    })
  }

  getNumberWaitingMeeting(congressId: string){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/meetings/` + congressId + `/get-number-waiting-meeting`;
    return this.http.get(url, {
      headers: headers
    });
  }
  
  getMeetingDates(congress_id: number) {
    const url = environment.baseUrl + "/meetings/" + congress_id + "/get-meeting-dates" ;
    return this.http.get(url);
  }
}
