import { FAQ } from './FAQ';
import { Organization } from './organization';
import { STag } from './STag';
export class Stand {
    stand_id: number;
    name: string;
    congress_id: number;
    organization_id: number;
    status: number;
    url_streaming: string;
    docs: any[]= [];
    faq: FAQ[]= [];
    organization: Organization = new Organization();
    stags : STag[]=[];

}
