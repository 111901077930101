import { Component, Input, OnInit } from '@angular/core';
import { FirebaseData } from 'src/app/shared/models/FirebaseData';
import { FirebaseUser, QuestionQuiz, ResponseQuestion } from 'src/app/shared/models/Quiz';
import { User } from 'src/app/shared/models/user';
import { ChatService } from 'src/app/shared/services/chat.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { Utils } from 'src/app/shared/utils';

declare var jQuery: any;

@Component({
  selector: 'app-online-room-quiz',
  templateUrl: './online-room-quiz.component.html',
  styleUrls: ['./online-room-quiz.component.css']
})
export class OnlineRoomQuizComponent implements OnInit {
  questions: QuestionQuiz[] = [];
  status = false;
  statusKey: string;
  questionNb = -1;
  userResponses = [];
  currentResponse: ResponseQuestion = new ResponseQuestion();
  firebaseUser: FirebaseUser = new FirebaseUser();
  userKey: string;
  nbCorrectresponses: number;
  notePerResponse: number;
  timeLeft: number;
  isUserResponsible = false;
  myInterval: any;
  roomName: string;
  @Input() congressId: string;
  @Input() accessId: number;
  @Input() user: User;
  usersData = [];
  usersKeys: string [] = [];

  constructor(private quizService: QuizService,
    private chatService: ChatService) { }

  ngOnInit(): void {
    this.roomName = Utils.getRoomName(this.congressId, this.accessId);
    this.getUsersData();
    this.isResponsible();
    this.getQuestions();
    this.getStatus();
  }

  getQuestions() {
    this.quizService.getAllQuestions(this.roomName).subscribe((actions: FirebaseData[]) => {
      this.questions = [];
      actions.forEach((action: any) => {
        this.questions.push(action.payload.val());
        this.questions[this.questions.length - 1].key = action.key;
        this.questions[this.questions.length - 1].isReponses = true;
      });
    });
  }

  getStatus() {
    this.quizService.getStatus(this.roomName).subscribe((actions: FirebaseData[]) => {
      if (actions.length > 0) {
        this.status = actions[0].payload.val().status;
        this.questionNb = actions[0].payload.val().questionNb;
        let answered = this.checkIfUserAnswered();
        this.statusKey = actions[0].key;
        if (this.status && !this.isUserResponsible && !answered) {
          jQuery('#rightResponseModal').modal('hide');
          jQuery('#questionModal').modal('show');
          this.startTimer(this.questions[this.questionNb].duration);
          this.calculateNotePerResponse();
        } else if (!this.status && this.questionNb != -1 && !this.isUserResponsible && this.firebaseUser.responses && this.firebaseUser.responses[this.questionNb] && !this.firebaseUser.responses[this.questionNb].seeResponse) {
          jQuery('#questionModal').modal('hide');
          jQuery('#rightResponseModal').modal('show');
        } else if (!this.status && this.questionNb != -1) {
          jQuery('#questionModal').modal('hide');
        }
      }
    });
  }

  seeResponse() {
    this.firebaseUser.responses[this.questionNb].seeResponse = true;
    this.quizService.saveResponse(this.roomName, this.userKey, { responses: this.firebaseUser.responses });
    jQuery('#rightResponseModal').modal('hide');
  }

  isResponsible() {
    this.quizService.getResponsibleById(this.roomName, this.user.user_id.toString()).subscribe((actions: FirebaseData[]) => {
      this.isUserResponsible = actions.length > 0 ? true : false;
    });
  }

  addResponse(event) {
    if (event.target.checked) {
      this.userResponses.push(parseInt(event.target.value));
    } else if (!event.target.checked && this.userResponses.length > 0) {
      const index = this.userResponses.indexOf(parseInt(event.target.value));
      if (index != -1) {
        this.userResponses.splice(index, 1)
      }
    }
  }
  
  getUsersData() {
    this.chatService.getUsersData(this.roomName).subscribe( (data: any) => {
      this.usersData = data;
      this.usersKeys = data ? Object.keys(data).map( val => {
        return val;
      }) : [] ;
      const isUser = this.findUser(this.user.user_id);
      if (isUser !== undefined) {
        this.firebaseUser = isUser.user;
        this.userKey = isUser.key;
      }
    });
  }

  findUser(userId) {
    let user;
    for (const userKey of this.usersKeys) {
      if (this.usersData[userKey].userId && this.usersData[userKey].userId === userId) {
        const userData = this.usersData[userKey];
        user = {
          user: userData,
          key: userKey
        };
        break;
      }
    }
    return user;
  }

  checkIfUserAnswered() {
    if (this.questionNb >= 0) {
      const that = this;
      let rep = false;
      if (this.firebaseUser.responses) {
        this.firebaseUser.responses.findIndex(function (response, index) {
          if (response.questionId == that.questions[that.questionNb].questionId) {
            rep = true;
          }
        });
      }
      return rep;
    }
  }

  saveResponse() {
    if (this.questions[this.questionNb].isMultiple == 0) {
      this.findResponse(this.userResponses);
      let note = this.currentResponse.isCorrect == 1 ? 1 : 0;
      this.saveUserResponses(this.userResponses, note);
    } else {
      let wrongAwnsewer = false;
      let note = null;
      for (let response of this.userResponses) {
        this.findResponse(response);
        if (this.currentResponse.isCorrect == 0) {
          note = 0;
          wrongAwnsewer = true;
        } else {
          note += this.notePerResponse;
        }
      }
      this.saveUserResponses(this.userResponses, note, wrongAwnsewer);
    }
  }

  findResponse(repId) {
    let that = this;
    this.questions[this.questionNb].responses.findIndex(function (response, index) {
      if (response.responseId == repId) {
        that.currentResponse = response;
        response.votes++;
        that.questions[that.questionNb].totalVotes++;
      }
    });
  }

  saveUserResponses(userResponses, note, wrongAwnsewer=null) {
    jQuery('#questionModal').modal('hide');
    if (!this.firebaseUser.responses) {
      this.firebaseUser.responses = [];
    }
    if (wrongAwnsewer) {
      note = 0;
    }
    this.firebaseUser.responses.push({ responseIds: userResponses, note: note, questionId: this.questions[this.questionNb].questionId });
    this.quizService.saveResponse(this.roomName, this.userKey, { responses: this.firebaseUser.responses });
    this.quizService.updateQuestion(this.roomName, this.questions[this.questionNb].key, { responses: this.questions[this.questionNb].responses, totalVotes: this.questions[this.questionNb].totalVotes });
    this.userResponses = [];
  }

  calculateNotePerResponse() {
    this.nbCorrectresponses = 0;
    for (let response of this.questions[this.questionNb].responses) {
      if (response.isCorrect == 1)
        this.nbCorrectresponses++;
    }
    let val = 1 / this.nbCorrectresponses;
    let roundedVal = val.toFixed(2);
    this.notePerResponse = Number(roundedVal);
  }

  startTimer(duration: number) {
    this.timeLeft = duration;
    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }
}