<section class="bg-light" id="live" *ngIf="configLandingPage.live_link || configLandingPage.live_link_ar">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title pb-2" style="margin-top: 30px;">
                    <h4 class="title mb-4"> {{getValueTranslate('live_title')}}</h4>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="team text-center rounded border-0">
                <div class="card-body">
                    <iframe [src]="url_streaming | safe :'resourceUrl'" class="full-page" allowfullscreen
                        class="live-frame"
                    >
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</section>