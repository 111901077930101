<section class="section bg-light" *ngIf="!isPartners && (configLandingPage?.sponsor_title ||configLandingPage?.sponsor_title_en) || isPartners && (configLandingPage?.partners_title ||configLandingPage?.partners_title_en)" id="{{isPartners ? 'partners' : 'sponsors'}}">
<div class="container mt-50">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">{{isPartners ? getValueTranslate('partners_title') : getValueTranslate('sponsor_title')}}</h4>
        <p class="text-muted para-desc mx-auto mb-0">{{isPartners ? getValueTranslate('partners_description') : getValueTranslate('sponsor_description')}}
      </p>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2" *ngFor="let sponsor of sponsors ">
      <img src="{{url}}{{sponsor?.logo}}" class="avatar avatar-ex-md" alt="">
    </div>
  </div>
</div>
</section>
