declare let jQuery: any;

export class UIUtils {

  static resizeCamera(initialHeight, track = null, multipleScreens = true, fullScreen = false) {
    function area(Increment, Count, Width, Height, Margin = 10) {
      let i = 0, w = 0;
      let h = Increment * 0.75 + (Margin * 2);
      while (i < (Count)) {
        if ((w + Increment) > Width) {
          w = 0;
          h = h + (Increment * 0.75) + (Margin * 2);
        }
        w = w + Increment + (Margin * 2);
        i++;
      }
      if (h > Height) {
        return false;
      } else {
        return Increment;
      }
    }

    function setWidth(width, margin) {
      jQuery('.Camera').css('width', width + 'px');
      jQuery('.Camera').css('max-width', width + 'px');
      jQuery('.Camera').css('min-width', '180px');
      jQuery('.Camera').css('margin', margin + 'px');
      jQuery('.Camera').css('height', (width * 0.6) + 'px');
      jQuery('.Camera').css('max-height', (width * 0.6) + 'px');
      jQuery('.Camera').css('min-height', '110px');
    }

    setTimeout(() => {
      // variables:
      const Margin = 2;
      const Scenary = jQuery('#Dish');
      const Width = !multipleScreens && !fullScreen ? 400 : Scenary.outerWidth() - (Margin * 2);
      const Height = Scenary.outerHeight() > initialHeight ? initialHeight - 55 : Scenary.outerHeight() - (Margin * 2);
      const Cameras = jQuery('.Camera');
      let max = 0;

      // loop (i recommend you optimize this)
      let i = 1;
      while (i < 5000) {
        const w = area(i, Cameras.length, Width, Height, Margin);
        if (w === false) {
          max = i - 1;
          break;
        }
        i++;
      }

      // set styles
      max = max - (Margin * 2);
      setWidth(max, Margin);
    }, 200);
  }

  public static editPlayersStyles(id, width, maxWidth, maxHeight, marginLeft, marginRight) {
    jQuery(id).css('width', width);
    jQuery(id).css('max-width', maxWidth);
    jQuery(id).css('margin', '2px');
    jQuery(id).css('max-height', maxHeight);
    jQuery(id).css('margin-left', marginLeft);
    jQuery(id).css('margin-right', marginRight);
  }
}
