<div class="header-soumission-add">
<p class="text-submission"><a  (click)="rtn()"><i _ngcontent-vlu-c112="" class="fa-arrow-left fas"></i></a>Soumettre un travail</p>
<div _ngcontent-iic-c62="" class="card public-profile border-0 rounded shadow" style="z-index: 1;">
  <div _ngcontent-iic-c62="" class="card-body">
    <div _ngcontent-iic-c62="" class="row align-items-center">
      <div _ngcontent-iic-c62="" class="col-lg-3 col-md-3 text-md-start text-center no-padding">
        <img *ngIf="firstAuthor.profile_pic != undefined" _ngcontent-iic-c62="" src="{{filesUrl + firstAuthor.profile_pic}}" alt="" class="avatar avatar-large rounded-circle shadow d-block mx-auto">
        <img *ngIf="firstAuthor.profile_pic == undefined" class="avatar avatar-large rounded-circle shadow d-block mx-auto" src="{{imgUrl}}DtAsM3eVt7t96HeYkNamr0VknqyRZH9N8EpFnyXP.svg" alt="img">
      </div>
      <div _ngcontent-iic-c62="" class="col-lg-9 col-md-9">
        <div _ngcontent-iic-c62="" class="row align-items-end">
          <div _ngcontent-iic-c62="" class="col-md-7 firstauthor mt-4 mt-sm-0">
            <h3 _ngcontent-iic-c62="" class="title mb-0 flname">{{firstAuthor.first_name}} {{firstAuthor.last_name}}</h3>
            <small _ngcontent-iic-c62="" class="text-muted h6 me-2">Email : {{firstAuthor.email}}</small><br>
            <small _ngcontent-iic-c62="" class="text-muted h6 me-2">Mobile : {{firstAuthor.mobile}}</small>  
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="card page" [ngBusy]="busy">
  <div id="accordion" class="panel-group ">
    <div class="display_title mb-6 mt-2">
      <h4 *ngIf="addExternalFiles" class="card-title add-file"><a aria-expanded="true" data-toggle="collapse" href="#collapse1"
          class="collapseWill">
          {{'SUBMISSION.SUBMISSION_ADD_FILES'|translate}} </a></h4>

          
      <h4 *ngIf="isEditMode && !addExternalFiles" class="card-title submission-title-h"><a aria-expanded="true" data-toggle="collapse"
          href="#collapse1" class="collapseWill">
          <div *ngIf="canEdit">{{'SUBMISSION.SUBMISSION_EDIT_YOURS' |translate}}</div>
          <div *ngIf="!canEdit">{{'SUBMISSION.SUBMISSION_UNABLE_TO_EDIT'|translate}}</div>
        </a></h4>
    </div>
    <div id="collapse1" *ngIf="!addExternalFiles" class="panel-collapse collapse show ">
      <div [ngBusy]="busy2" class="card-body">
        <div class="ql-snow ql-disabled ">
          <div class="ql-editor">
            <div *ngIf="congress?.config_submission?.explanatory_paragraph || congress?.config_submission?.explanatory_paragraph_en"
              [innerHTML]="transformHtml(congress?.config_submission?.explanatory_paragraph, congress?.config_submission?.explanatory_paragraph_en)">
            </div>
          </div>
        </div>
        <div *ngIf="isLoaded">
          <app-submission-header [themes]="themes" [congress]="congress"></app-submission-header>
        </div>
        <form #editForm="ngForm">

          <div class="form-group">
            <label class="col-form-label titles_submission"
              for="title">{{'SUBMISSION.SUBMISSION_TITLE'|translate}}</label>
            <input [disabled]="!canEdit" type="text" class="form-control inputs-submission" id="title"
              placeholder="{{'SUBMISSION.SUBMISSION_TITLE'|translate}}" name="title" required
              (focusout)="titleFocusOut()" [ngClass]="{'is-invalid': isSubmitted && !submission.title }"
              [ngClass]="{'valid-green-box': title.valid}" (ngModelChange)="titleChange($event)"
              [(ngModel)]="submission.title" #title="ngModel">
            <div class="invalid-feedback">
              {{'SHARED.REQUIRED_FIELD'|translate}}
            </div>
            <div class="show-info" *ngIf="false">
              {{'SUBMISSION.SUBMISSION_TITLE_HINT'|translate}}
            </div>
          </div>

          <form>
            <div class="form-group" *ngIf="ready && establishmentsReady && servicesReady">
              <label class="col-form-label titles_submission">{{'SUBMISSION.PRESENTATION_AUTHORS'|translate}}</label>
              <app-author-list *ngIf="ready" [placeholder]="'SUBMISSION.PRESENTATION_ADD_AUTHOR'|translate" [congressId]="congress?.congress_id"
                [services]="services" [establishments]="establishments" [(items)]="authors" [firstAuthor]="firstAuthor">
              </app-author-list>
            </div>
          </form>



          <div class="form-group">
            <label class="col-form-label titles_submission" for="abstract_type">Abstract</label>
            <select name="abstract_type" id="abstract_type" class="form-control inputs-submission"
              [(ngModel)]="abstractType" required>
              <option value="0">{{'SUBMISSION.ABSTRACT_SERIE'|translate}}</option>
              <option value="1">{{'SUBMISSION.ABSTRACT_CAS_CLINIQUE'|translate}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="col-form-label titles_submission"
              for="abstract_form">{{'SUBMISSION.PRESENTATION_FORM'|translate}}</label>
            <select name="abstract_form" id="abstract_form" class="form-control inputs-submission" required
              [(ngModel)]="submission.communication_type_id">
              <option *ngFor="let type of communicationTypes" [value]="type.communication_type_id">
                {{type.label}}</option>

            </select>
          </div>
          <div class="form-group">
            <label class="col-form-label titles_submission" for="abstract_theme">{{'Theme' | translate}}</label>
            <select name="abstract_theme" id="abstract_theme" class="form-control inputs-submission" required
              [(ngModel)]="submission.theme_id">
              <option *ngFor="let theme of themes" value="{{theme.theme_id}}">{{translateTheme(theme) }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="col-form-label titles_submission" for="key_words">{{'SUBMISSION.Keywords'|translate}}</label>
            <textarea [disabled]="!canEdit"  class="form-control inputs-submission" id="key_words"
              name="key_words" [(ngModel)]="submission.key_words" ></textarea>
          </div>
          <div class="form-group">
            <div *ngFor="let abstract of abstractVariants[abstractType]">
              <label for="abstract{{abstract.id}}" class="titles_submission">{{'SUBMISSION.' +
                abstract.title|translate}}</label>
              <textarea [disabled]="!canEdit" type="text" class="form-control inputs-submission"
                id="abstract{{abstract.id}}" placeholder="" required [(ngModel)]="abstract.value"
                name="abstract{{abstract.id}}" rows="10" #area="ngModel"
                [ngClass]="{'is-invalid': isSubmitted && abstract.value.length===0 }"
                [ngClass]="{'valid-green-box': area.valid}"></textarea>
              <div class="invalid-feedback">
                {{'SHARED.REQUIRED_FIELD'|translate}}
              </div>
            </div>
            <div class="show-info">
              {{'SUBMISSION.CONTENT_MAX_PREFIX'|translate}} 
              ({{getNumberOfWord()}}/{{congress?.config_submission?.max_words}}) 
              {{'SUBMISSION.CONTENT_MAX_POSTFIX'|translate}}
            </div>
          </div>
          <!-- <div class="form-group" *ngIf="this.submissionRequest.abstract.length===0">
               <label class="col-form-label" for="description">Abstract</label>
               <textarea [disabled]="!canEdit" type="text" class="form-control" id="description" placeholder="Abstract"
                         [(ngModel)]="submissionRequest.description"
                         name="description" rows="15"
                         [ngClass]="{'is-invalid': isSubmitted && !submissionRequest.description}" required></textarea>
               <div class="show-info">
                 {{'SUBMISSION.CONTENT_MAX_PREFIX'|translate}}  {{'SUBMISSION.CONTENT_MAX_POSTFIX'|translate}}
               </div>
               <div class="invalid-feedback">
                 {{'SHARED.REQUIRED_FIELD'|translate}}
               </div>
             </div>-->
        </form>
      </div>
    </div>



      <div class="form-group pt-3 " *ngIf="congress?.config_submission?.show_file_upload==1 || (submission && submission?.status==4)">
        <label class="col-form-label pl-3 titles_submission">{{'SUBMISSION.DATA_ROW'|translate}}</label>
        <!--[ngClass]="{'is-invalid ': isSubmitted && !submissionRequest.documents[0]}"-->
        <div class="col-lg-12" *ngIf="filesLoaded">
          <app-upload-file [uploadType]="'file-input'" [disabled]="!canEdit" [maxFileCount]="1" [previewFiles]="pathsResouces" [accept]="'.pdf'" [allowedFileExtensions]="['pdf']" (onUpload)="onUploadFile($event)" (onDelete)="onDeleteFile($event)"></app-upload-file>
        </div>
      </div>
    <div *ngIf="isEditMode">

      <h4>{{'Comments' | translate}}</h4>
      <div *ngIf="submission.comments.length > 0">
        <div *ngFor="let c of submission.comments; ;let i=index;" class="content-section">
          <span style="font-weight: bold;"> {{'Comment' | translate}}</span> {{i+1}}: {{c.description}}
        </div>
      </div>
      <div *ngIf="submission.comments.length == 0" class="content-section">{{'NoComments' | translate}}</div>


    </div>
    <div class="mt-4 d-flex justify-content-center">

      <div class="mr-3 mt-4">
        <a class="btn btn-outline-primary d-flex align-center buttons_add_cancel" (click)="onCancel()">
          <div class="col text-muted">{{'SHARED.CANCEL'|translate}}</div>
          <div class="fa fa-times ml-auto text-muted"></div>
        </a>
      </div>
      <div class= "mr-3 mt-4">
        <a class="btn btn-success d-flex align-center button_add buttons_add_cancel" 
        (click)="correction()">
        <div class="col">{{'HOME.Spellcheck' | translate}}</div>
        <div class="fa fa-check ml-auto"></div>
      </a>
        </div>

      <div class="mt-4">
        <a class="btn btn-primary d-flex align-center button_add buttons_add_cancel" *ngIf="!addExternalFiles"
          (click)="editForm.ngSubmit.emit();onAddSubmission()">
          <div class="col ">{{(isEditMode && !addExternalFiles) ? ('SHARED.EDIT'|translate) :
            ('SUBMISSION.CONFIRM_AND_SEND'|translate) }}</div>
          <div class="fa fa-check ml-auto"></div>
        </a>
        <a class="btn btn-primary d-flex align-center button_add buttons_add_cancel"
          [disabled]="resourceIds.length === 0 || isSubmitted" *ngIf="addExternalFiles" (click)="onAddSubmission()">
          <div class="col "> {{'SUBMISSION.CONFIRM_AND_SEND'|translate}} </div>
          <div class="fa fa-check ml-auto"></div>
        </a>
      </div>

  </div>
</div>


<div class="modal fade" id="decriptionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'HOME.Spellcheck' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div id="diff-container">
                </div>
            </div>

            <div class="modal-footer">
        
            </div>
        </div>
    </div>
</div>