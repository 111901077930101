<body>
    <main
        [ngStyle]="{'background-image': congress?.config?.backg_form ?  'url(' +filesUrl + congress?.config?.backg_form+ ')':'url('+ backgroundImageUrl+')' }">
        <div class="inner">
            <header>
                <div class="logo" >
                    <div class="logo-icon">
                        <img *ngIf="congress?.config?.logo" class="event-logo"
                            [src]="filesUrl + getValueTranslate('logo')" alt="BeRifma">
                    </div>
                    <div class="logo-text">
                        {{congress?.name}}
                        <br>
                        <div class="logo" style="float: left;">
                            {{congress?.start_date | date: 'dd/MM/YYYY'}} - {{congress?.end_date | date: 'dd/MM/YYYY'}}
                        </div>
                    </div>
                </div>
                <div class="bar-end">
                </div>
            </header>

            <div class="container" *ngIf="congress?.config?.status == 1 ">
                <div class="wrapper steps">
                    <article>
                        <div class="main-heading">
                            {{ congress?.config?.access_title ? getValueTranslate(congress?.config, 'access_title') :
                            ('Ateliers' |
                            translate)}}
                        </div>
                    </article>
                    <!--form-->
                    <section>
                        <article>
                            <div class="main-heading">
                                {{formInputs[0]?.section?.name}}
                            </div>
                        </article>
                        <div class="container">
                            <div class="align-items-center row d-flex justify-content-center">
                                <div *ngFor="let form_input of formInputs; let i = index" class="col-md-12">
                                    <div class="form-input-description" *ngIf="form_input?.form_input_type_id==12"
                                        [innerHTML]="transformHtmlDescriptionFormInput(form_input)"></div>
                                    <ng-container *ngIf="form_input?.visible == 1">
                                        <div *ngIf="(form_input?.question_reference && form_input?.question_reference?.length == 0) || form_input?.show"
                                            [ngClass]="{'radio-box': form_input?.form_input_type_id == 9,
            'input-field': form_input?.form_input_type_id != 9}">
                                            <label
                                                *ngIf="(form_input?.groupe_id && i == 0)  || (form_input?.groupe_id && i > 0 && formInputs[i - 1].groupe_id != form_input?.groupe_id)"
                                                class="control-label"
                                                style="font-size: 25px;color: #214af3;font-weight: 400;">{{
                                                form_input?.groupe ? getValueTranslate('title', form_input?.groupe) : ''
                                                }}
                                            </label>
                                            <div *ngIf="form_input?.is_external == 1">
                                                <label class="d-flex mt-1" *ngIf="form_input.form_input_type_id != 12">
                                                    {{getValueTranslate('label', form_input)}}
                                                    <div *ngIf="form_input?.required" class="ml-2">*</div>
                                                </label>
                                                <app-ng-generic class="input-custom" [type]="form_input?.type?.name"
                                                    [options]="form_input?.values" [lang]="lang"
                                                    [values]="form_input?.response"
                                                    (answer)="handleAnswers($event, form_input)"
                                                    [required]="form_input?.required" [submitted]="submitted">
                                                </app-ng-generic>
                                            </div>
                                            <label *ngIf="submitted && form_input?.required && !form_input?.response"
                                                class="validation-error-label">
                                                {{'required' | translate}}
                                            </label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="button" id="step2btn" class="btn btn-primary next circle-button"
                                (click)="saveFormInputWithResponses()">
                                {{ 'Validate' | translate }}
                            </button>
                        </div>

                    </section>
                    <!--form-->

                </div>
            </div>

            <footer>
                <div class="bar-end ml-auto">
                    <h3 style="color:black;">
                        <i class="fa-solid fa-square-phone-flip">
                        </i> {{'NeedHelp' | translate}} ?
                        <span> {{'CallExpert' | translate}}{{congress?.config?.mobile_technical}}</span>
                    </h3>
                </div>
            </footer>
        </div>
    </main>
</body>