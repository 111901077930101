import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-price',
  templateUrl: './filter-price.component.html',
  styleUrls: ['./filter-price.component.css']
})
export class FilterPriceComponent implements OnInit {
  @Output() sendPrice: EventEmitter<any> = new EventEmitter();
  @Input() options
  hidePrice:boolean = false;
  @Output() sendStatus: EventEmitter<any> = new EventEmitter();
  lang : string;
  constructor( private translate: TranslateService) {
    this.lang = this.translate.currentLang;
   }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang
    })
  }

  Reset() {
    this.options.filter[0].nom = ''
    this.options.filter[1].date = ''
    this.options.filter[2].date = ''
    this.options.filter[3].price = ''
    this.options.filter[4].price = ''
    this.options.filter[5].type=''
    this.hidePrice = false;
  }

  searchData() {
    if (this.options.filter[5].type == "2" || this.options.filter[5].type == "3") {
      this.options.filter[3].price = ''
      this.options.filter[4].price = ''
    }
    this.sendPrice.emit(this.options);
  }

  checkStauts() {
    if (this.options.filter[5].type == "1") {
      this.hidePrice = true;
      this.options.filter[3].price = '1'
      this.options.filter[4].price = '999'
    } else {
      this.options.filter[3].price = ''
      this.options.filter[4].price = ''
      this.hidePrice = false;
    }
    this.sendStatus.emit(this.options);

  }

}
