<section class="mt-4 mb-4 w-100 d-table" *ngIf="configLandingPage.specific_bnr || configLandingPage.specific_bnr_two" [id]="bannerTwo?'specificBannerTwo':'specificBanner'"
  data-jarallax='{"speed": 0.5}' [ngBusy]="busy" style="padding-bottom: 0px;">
  <div class="container mb-4">
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <div class="title-heading"
          [ngClass]="{'title-margin': configLandingPage?.specific_bnr_title || configLandingPage?.specific_bnr_title_description || configLandingPage.specific_bnr_two_title || configLandingPage.specific_bnr_two_description }">
          <h1 class="title mb-4">
            {{getValueTranslate(bannerTwo? 'specific_bnr_two_title': 'specific_bnr_title')}}
          </h1>
          <p class="para-desc title-dark mx-auto">
            {{getValueTranslate(bannerTwo? 'specific_bnr_two_description': 'specific_bnr_description')}}</p>
        </div>

      </div>
    </div>
  </div>
  <img *ngIf="!bannerTwo"  [ngStyle]="{
    width:'100%',
    padding:screenWidth>991 ? 0 : ''}" [src]="filesUrl + getValueTranslate('specific_bnr')" alt="">

    <img *ngIf="bannerTwo"  [ngStyle]="{
      width:'100%',
      padding:screenWidth>991 ? 0 : ''}" [src]="filesUrl + getValueTranslate('specific_bnr_two')" alt="">
</section>