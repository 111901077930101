
import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Congress } from 'src/app/shared/models/congress';
import { Form } from 'src/app/shared/models/form';
import { FormInput } from 'src/app/shared/models/formInput';
import { User } from 'src/app/shared/models/user';
import { CongressService } from 'src/app/shared/services/congress.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
@Component({
  selector: 'app-register-external-questions',
  templateUrl: './register-external-questions.component.html',
  styleUrls: ['./register-external-questions.component.css']
})
export class RegisterExternalQuestionsComponent implements OnInit, AfterViewInit {
  formInputs: any[] = [];
  busy: Subscription;
  email: string;
  form: Form = new Form();
  congress: Congress;
  submitted: boolean = false;
  questionPriceSum: number = 0;
  oldSum: number;
  isEditRegistration: boolean;
  formId: number;
  userId: number;
  userCode: string;
  congressId: number;
  congressReady: boolean = false;
  lang = { lang: "FR", lg: "fr" };
  filesUrl = environment.filesUrl;
  backgroundImageUrl: string;
  user: User = new User();
  tableFormInput: any[] = [];
  redirectUrl: string;
  message: string;
  constructor(private sanitizer: DomSanitizer,
    private translate: TranslateService, private route: ActivatedRoute,
    private congressService: CongressService,
    private storageService: StorageService,
    private userService: UserService,

    private router: Router) {
      this.redirectUrl = this.storageService.read(StorageService.REDIRECT_URL);
    this.route.queryParams.subscribe(params => {
      this.congressId = params['congressId'];
      this.userId = params['userId'];
      this.userCode = params['userCode'];
      this.email = this.route.snapshot.queryParamMap.get('email');
      if (this.email) {
        this.userService.getUserByEmailPublic(this.email).subscribe(data => {
          this.user = data;
          this.user.accessIds = []
          this.storageService.write(StorageService.USER_KEY, data);
        });
      }
    });
    this.setBackgroundImage();
  }


  async ngOnInit(): Promise<void> {
    this.congressId = parseInt(this.route.snapshot.paramMap.get('id_congress'), 0);
    this.formId = parseInt(this.route.snapshot.paramMap.get('id_form'), 0);
    this.email = this.route.snapshot.queryParamMap.get('email');
    await this.getCongressById();
    await this.getFormById();
    await this.getFormInputByFormId()
    this.initInputs();
  }

  getFormById(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.congressService.getFormById(this.formId).subscribe((data: Form) => {
        this.form = data;
        this.user.privilege_id = this.form.privilege_id;
        resolve();
      });
    });
  }

  getFormInputByFormId(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.congressService.getFormInputByFormId(this.formId).subscribe((data: any[]) => {
        this.formInputs = Object.entries(data);
        this.formInputs = this.formInputs[0][1]
        resolve();
      });
    });
  }

  getCongressById(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.congressService.getMinCongressPublic(this.congressId).subscribe((data: Congress) => {
        this.congress = data;
        this.congressReady = true;
        if (this.congress.config.default_lang != null && this.congress.config.default_lang != this.lang.lang) {
          this.lang.lang = this.congress.config.default_lang;
          this.lang.lg = this.congress.config.default_lang.toLowerCase();
        }
        resolve();
      });
    });
  }
  ngAfterViewInit(): void {
    this.replaceFormInputs();
  }

  initInputs() {
    this.formInputs.forEach(res => {
      res.oldResponses = [];

      if (!res.response) {
        res.response = res.form_input_type_id == 6 ? [] : null;
      }
    });
  }

  replaceFormInputs() {
    if (this.user.responses.length > 0) {
      for (let i = 0; i < this.formInputs.length; i++) {
        const formInput = this.formInputs[i];
        if (formInput.form_input_type_id == 6 && !formInput.response) {
          formInput.response = [];
        }
        const userInput = this.user.responses.find((input) => input.form_input_id === formInput.form_input_id);
        if (userInput) {
          this.formInputs[i] = userInput;
        }
      }
    }
  }

  transformHtmlDescriptionFormInput(formInput): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(Utils.getValueTranslate("Fr", formInput, 'label'));
  }

  getValueTranslate(item, groupe) {
    return Utils.getValueTranslate("fr", groupe, item);
  }

  handleAnswers(event, index) {
    const formInputId = index.form_input_id;
    const existingIndex = this.tableFormInput.findIndex(item => item.formInputIndex.form_input_id === formInputId);
    if (existingIndex !== -1) {
      this.tableFormInput.splice(existingIndex, 1);
    }
    this.tableFormInput.push({ formInputIndex: index, values: event });
  }
  

  setBackgroundImage() {
    const pixelRatio = window.devicePixelRatio || 1;
    this.backgroundImageUrl = pixelRatio > 1.5 ? "../../../assets/images/trimba/bg-dark.jpg" : "../../../assets/images/trimba/bg.jpg";
  }

  saveFormInputWithResponses() {
    if (!this.validateFormInputs()) {
      Utils.swalMessage('SWAL.ERROR', 'HOME.FieldsError', 'error', this.translate);
      return;
    }
  
    this.busy = this.userService.saveFormInputWithResponses(this.congressId, this.email, this.tableFormInput).subscribe({
      next: (data: any) => {
        this.router.navigate(['/landingpage/' + data[0] + '/thank-you/' + this.congressId]);
      },
      error: error => {
        if (error.status === 405) {
          Utils.swalMessage('SWAL.ERROR', 'AlreadyRegistered', 'warning', this.translate);
        } else {
          Utils.swalMessage('SWAL.ERROR', 'ErrorAdding', 'error', this.translate);
        }
      }
    });
  }
  
  validateFormInputs(): boolean {
    const externalFormInputs = this.getExternalFormInputs();
    for (const formInput of externalFormInputs) {
      const response = this.tableFormInput.find(item => item.formInputIndex.form_input_id === formInput.form_input_id);
      if (!response || !response.values || response.values.length === 0) {
        return false;
      }
    }
    return true;
  }
  
  
  getExternalFormInputs(): any[] {
    return this.formInputs.filter(input => input.is_external === 1);
  }

   

  groupResponsesBySection(responses) {
    const groupedResponses = responses.reduce((result, currentResponse) => {
      const sectionId = currentResponse.form_section_id;
      if (!result[sectionId]) {
        result[sectionId] = [];
      }
      result[sectionId].push(currentResponse);
      return result;
    }, {});
    return groupedResponses;
  }

}

