import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class QuizService extends GenericService {

  constructor(
    private db: AngularFireDatabase,
    private dbStore: AngularFirestore,
  ) {
    super();
  }

  getAllQuestions(roomId: string) {
    return this.db.list( 'chat/' + roomId +'/quiz/questions' ).snapshotChanges(); 
  }

  updateStatus(roomId: string, key, updates) {
    return this.db.list('chat/' + roomId +'/quiz/status' ).update(key, updates); 
  }

  getStatus(roomId: string) {
    return this.db.list('chat/' + roomId +'/quiz/status' ).snapshotChanges(); 
  }

   getResponsibleById(roomId: string, userId: string) {
    return this.db.list('chat/' + roomId +'/quiz/responsibles',  ref =>
    ref.orderByChild('userId').equalTo(userId)
    ).snapshotChanges(); 
  }

  getUserById(roomId: string, userId: string) {
    return this.db.list('chat/' + roomId +'/users_data',  ref =>
    ref.orderByChild('userId').equalTo(userId)
    ).snapshotChanges(); 
  }

  addUser(roomId : string, user) {
    this.db.list('chat/' + roomId +'/users_data').push(user);
  }

  saveResponse(roomId : string, key: string, responses) {
    this.db.list('chat/' + roomId +'/users_data').update(key, responses);
  }

  updateQuestion(roomId : string, key: string, updates) {
    return this.db.list('chat/' + roomId +'/quiz/questions').update(key, updates);
  }

}
