import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tank-you-page-access',
  templateUrl: './tank-you-page-access.component.html',
  styleUrls: ['./tank-you-page-access.component.css']
})
export class ThankYouPageAccessComponent {
  busy: Subscription;
}
