import {Pack} from './Pack';
import {User} from './user';


export class Access {
  access_id: number;
  name: string;
  price: number;
  duration: number;
  max_places: number;
  total_present_in_congress: number;
  seuil: number;
  room: string;
  description: string;
  real_start_date: Date;
  start_date: Date;
  end_date: Date;
  packless: number;
  show_in_program: number;
  show_in_register: number;
  with_attestation: number;
  parent_id: number;
  congress_id: number;
  topic_id: number;
  access_type_id: number;
  is_online: number;
  recorder_url: string;
  packs: Pack[];
  speaker:User
  speakers: User[];
  chairs: User[];
  banner:string;
  isSelected: boolean;
  participant_count: number;
  nb_participants : number;
}

export class AccessGroupedByTime {
  public start_date: string;
  public accesss: Access [] = [];
}
