import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from '../../utils';

declare var jQuery: any;

@Component({
  selector: 'app-ng-radiobox',
  templateUrl: './ng-radiobox.component.html',
  styleUrls: ['./ng-radiobox.component.css']
})
export class NgRadioboxComponent implements OnInit, AfterViewInit {
  className: string;

  @Input()
  basicStyle?: boolean;

  @Input()
  label: string;

  @Output()
  selectionChange: EventEmitter<any>;

  selected: any;

  constructor() {
    this.className = Utils.getRandomString();
    this.selectionChange = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    this.selected = value;
  }

  ngAfterViewInit(): void {
    const baseContext = this;
    jQuery('.' + baseContext.className).on('change', function () {
      baseContext.selectionChange.emit(baseContext.selected ? 0 : 1);
    });
  }


}
