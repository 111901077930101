import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import {PaymentService} from '../../services/payment.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  search = '';
  @Output() searchOut = new EventEmitter();
  @Output() filterConfig = new EventEmitter();
  imgUrl = environment.imgUrl;  
  @Input() options = {
    name: 'PAGE NAME',
    search: true,
    filter: [
      {
        name: 'start_date',
        label: 'start_date',
        type: 'date',
        date: ''
      },
      {
        name: 'end_date',
        label: 'end_date',
        type: 'date',
        date: ''
      },
      {
        name: 'status',
        label: 'status',
        type: 'select',
        values: [
          {value: 'test', label: 'test'},
        ],
        selected_label: '',
      },
      {
        name: 'method_payment',
        label: 'method_payment',
        type: 'select',
        values: [
          {value: 'test', label: 'test'},
        ],
        selected_label: '',
      },
      {
        name: 'price_less_than',
        label: 'price_less_than',
        type: 'number',
        price: 0,
      },
      {
        name: 'congress',
        label: 'congress',
        type: 'text',
        congress_name: '',
      },
    ]
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  filterData() {
    this.filterConfig.emit(this.options);
  }

  searchData() {
    this.searchOut.emit(this.search);
  }

}

// Example

// @Input() options = {
//   name : 'Page name',
//   search : true,
//   filter : [{
//     label :  'par status/ par evenement / par méthode de payment / start date / end date/ prix inférieur',
//     type : 'input/date/select',
//     values : [
//       {value: '0', label: 'CONGRESS.ENDED'},
//       {value: '1', label: 'CONGRESS.ONGOING'},
//       {value: '2', label: 'CONGRESS.SOON'},
//     ]
//   }
//   ]
// };