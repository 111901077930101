import { StorageService } from './../../services/storage.service';
import { CongressService } from './../../services/congress.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Tracking } from '../../models/Tracking';
import { Utils } from '../../utils';
import { User } from '../../models/user';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit, OnDestroy  {
  tracking: Tracking = new Tracking();
  user: User = new User();
  @Input() congressId: string;
  @Input() type: string;
  @Input() name: string;
  @Input() action: string;

  constructor(private congressService: CongressService,
    private storageService: StorageService) {
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.tracking.entry_date = new Date();
   }

  ngOnInit(): void {
    this.tracking.entry_date_string = Utils.setDateFormat(this.tracking.entry_date);
  }

  saveTracking() {
    this.congressService.saveTracking(this.action, this.type, this.name, this.tracking.entry_date_string, this.tracking.leave_date_string, this.user.user_id, this.tracking.duration, parseInt(this.congressId)).subscribe(data => {

    });
  }

  ngOnDestroy(): void {
    this.tracking.leave_date = new Date();
    this.tracking.leave_date_string = Utils.setDateFormat(this.tracking.leave_date);
    this.tracking.duration = this.tracking.leave_date.getTime() - this.tracking.entry_date.getTime();
    this.saveTracking();
  }

}
