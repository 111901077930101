<div class="inner-box  animated fadeInLeft" [ngBusy]="busy">
  <h2 class="title-2"><i class="icon-docs"></i> {{'SUBMISSION.SUBMISSION_DETAIL' | translate}}
  </h2>

  <ul class="nav nav-tabs nav-tabs-highlight nav-justified">
    <li class="nav-item"><a href="#highlighted-justified-tab1" class="nav-link active show" data-toggle="tab"><h4>
      {{'CONGRESS.STATUS' | translate}} </h4></a>
    </li>
    <li class="nav-item"><a href="#highlighted-justified-tab2" class="nav-link" data-toggle="tab"><h4>
      {{'SUBMISSION.INFORMATIONS_GENERALES' | translate}} </h4>
    </a></li>
    <li class="nav-item"><a href="#highlighted-justified-tab6" class="nav-link" data-toggle="tab"><h4>Abstract</h4></a>
    </li>
    <li class="nav-item"><a href="#highlighted-justified-tab3" class="nav-link" data-toggle="tab"><h4> {{'Congress' | translate}}</h4></a>
    </li>
    <li class="nav-item"><a href="#highlighted-justified-tab4" class="nav-link" data-toggle="tab"><h4>Documents</h4></a>
    </li>
  </ul>

  <div class="tab-content">

    <div class="tab-pane fade active show" id="highlighted-justified-tab1">
      <div class="card text-center  text-white  " [ngClass]="{'bg-info': submission?.status==0,
                     'bg-success': submission?.status==1 || submission?.status==2,
                     'bg-danger': submission?.status==-1 ||submission?.status==-2 }">
        <div class="card-body">
          <p
            class="card-text h5">{{submission?.status == 0 ? "En cours" : submission?.status == -1 ?
              ('CONGRESS.REFUSED_BY_THE_SC_COMMITTEE' | translate)
              : submission?.status == -2 ?('CONGRESS.REFUSED_BY_THE_ORGANIZER' | translate) : submission?.status == 1 ?
              ('CONGRESS.ACCEPTED_BY_THE_SC_COMMITTEE' | translate) : ('CONGRESS.ACCEPTED_TO_CONGRESS' | translate) }}</p>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="highlighted-justified-tab2">
      <div class="card ">
        <div class="card-body">
          <div class="list-group form-group">
            <h4 class="list-group-item list-group-item-action "> {{'Titre' | translate }} : {{submission?.title}}</h4>
            <h4 class="list-group-item list-group-item-action " *ngFor="let author of submission?.authors; let i = index"> 
              {{'SUBMISSION.Author' | translate }} {{i + 1}} : {{author?.first_name}} {{author?.last_name}}  
            </h4>
          </div>
        </div>
      </div>
    </div>


    <div class="tab-pane fade" id="highlighted-justified-tab6">
      <div class="card ">
        <div class="card-body">
          <div class="list-group form-group">
            <h4 class="list-group-item list-group-item-action " style="white-space: pre-wrap;" [innerHTML]="submission?.description">
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="highlighted-justified-tab3">
      <div class="card ">
        <div class="card-body">
          <div class="list-group form-group">
            <h4 class="list-group-item list-group-item-action "> {{'Titre' | translate }} : {{submission?.congress?.name}}</h4>
            <h4 class="list-group-item list-group-item-action ">{{'HOME.CONGRESS_DATE_START' | translate}}
              : {{submission?.congress?.start_date|date:'d/M/yyyy, H:mm'}} </h4>
            <h4 class="list-group-item list-group-item-action ">{{'HOME.CONGRESS_DATE_END' | translate}}
              : {{submission?.congress?.end_date |date:'d/M/yyyy, H:mm'}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="highlighted-justified-tab4">
      <div class="card text-center">
        <div class="card-body" *ngIf="submissionReady">
          <app-ng-files [resources]="submission?.resources" [multiple]="true"></app-ng-files>
        </div>
      </div>
    </div>
  </div>
</div>

