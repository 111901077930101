<div class="inner-box animated fadeInLeft">
  <div class="list-box">
   <app-filter
               [options]="options"
               (searchOut)="setKeyword($event)"
               (filterConfig)="setFilter($event)"


   ></app-filter>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="onScroll()">
      <div class="row">
          <div class="category-list">
            <div class="tab-content">
              <div class="tab-pane  active " id="alladslist">


                <div *ngIf='congresses' [@fadeIn]="perPage"
                     class="adds-wrapper row no-margin property-list">


                  <div class="congress mb-3" *ngFor="let congress of congresses; let i = index">
                      <app-item-congress congress="congress" [attestations]="true" [price]="false" [rencontres]="true" [user_buttons_question_packs]="true"></app-item-congress>
                  </div>
                  <!--/.item-list-->
                  <div class="col-lg-4 col-md-3 col-sm-12 mr-auto">
                    <ngx-spinner
                      bdColor="rgba(0,0,0,0)"
                      size="medium"
                      color="#393939"
                      type="ball-pulse"
                    ></ngx-spinner>
                  </div>

                </div>

                <!--/.adds-wrapper-->
              </div>

            </div>

          </div>

          <!--/.pagination-bar -->


        </div>
        <!--/.page-content-->


    </div>

  </div>
</div>

