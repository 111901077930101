import { Component, Input, OnInit } from '@angular/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-landing-page',
  templateUrl: './footer-landing-page.component.html',
  styleUrls: ['./footer-landing-page.component.css']
})
export class FooterLandingPageComponent implements OnInit {
  @Input() configLandingPage: ConfigLandignPage;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl; 
   
  

  name : string;
  link : string;
  logo : string;
  year:number;

  constructor() { }

  ngOnInit(): void {
    this.name = this.getName();
    this.link = this.getLink();
    this.logo = this.getLogo();
    const d = new Date();
    this.year = d.getFullYear();
  }

  toTop(){
    document.getElementById("home").scrollIntoView({behavior:"smooth"});
  }

  getName () {
    if (!this.configLandingPage?.name_partenaire) {
      return 'Eventizer.';
    }

    return this.configLandingPage?.name_partenaire + '.';
  }

  getLink () {
    if (!this.configLandingPage?.name_partenaire || !this.configLandingPage?.link_partenaire) {
      return 'https://app.eventizer.io/';
    }

    return this.configLandingPage?.link_partenaire;
  }

  getLogo () {
    if (!this.configLandingPage?.name_partenaire || !this.configLandingPage?.header_logo_event) {
      return 'imgUrl + A4Xk85bBePkD8apee8Xu24dcoaJlwxr9kQU74Rvd.png';
    }

    return this.filesUrl + this.configLandingPage?.header_logo_event;
  }
}