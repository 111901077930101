import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.css']
})
export class SpeakersComponent implements OnInit {
  @Input() speakers;
  @Input() configLandingPage: ConfigLandignPage;
  url = environment.filesUrl;
  @Input() lang: string;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  getValueTranslateSpeaker (columnName: string, speaker) {
    return Utils.getValueTranslate(this.lang, speaker, columnName);
  }

  transformHtml(htmlTextWithStyle): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

}
