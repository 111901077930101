import {User} from './user';
import {Resource} from './Resource';

export class Organization {
  organization_id: number;
  name: string;
  description: string;
  email: string;
  mobile: number;
  users: User[] = [];
  resource_id: number;
  is_sponsor: number;
  logo_position: string;
  website_link: string;
  twitter_link: string;
  linkedin_link: string;
  fb_link: string;
  insta_link: string;
  logo: string;
  banner: string;
  resource: Resource;
  membres: User[] = [];


}
