<div class="text-center">
    <img *ngIf="user?.profile_img" src="{{filesUrl + user?.profile_img?.path}}"
        class="avatar avatar-medium shadow-lg rounded-pill" alt="{{user?.first_name}} {{user?.last_name}}"   (click)="colapse(user.user_id)">
    <div class="userLetters avatar m-auto shadow-lg rounded-pill" *ngIf="!user?.profile_img">
        <p *ngIf="user?.first_name && user?.last_name">{{firstLetters(user)}}</p>
    </div>
    <div class="content mt-3">
        <a href="javascript:void(0)"  (click)="colapse(user.user_id)" class="h5 text-dark"> {{user?.first_name}} {{user?.last_name}} </a>    
        <a href="javascript:void(0)"  (click)="colapse(user.user_id)" >
            <i class="fa fa-solid fa-eye eye-color" aria-hidden="true"></i>
        </a>
        <ul class="list-unstyled social-icon social mb-0 mt-2">
        
            <a href="javascript:void(0)" class="attendee-list-item_username__2CJh4 test-id-attendee-name"
                *ngIf="user.user_congresses[0].chat_info !== null">
                <div *ngFor="let chatInfo of user.chatInfoArray">
                    {{chatInfo}}
                </div>
         </a>
        </ul>
        <!--end icon-->
        <a href="javascript:void(0)" class="btn btn-primary meeting-btn" 
        data-toggle="modal" [attr.data-target]="'#'+user.user_id" id=#U{{+user.user_id}}>
            {{'RequestMeeting' | translate}}
        </a>
    </div>
</div>

<div class="collapse mt-2" id="{{'tocollapse'+user.user_id}}">
    <div class="card card-style no-effect mb-2">
        <div class="content">
            <div class="d-flex">
                <div class=" ml-2">
                    <h1 class="ml-2  collapse-title">
                        {{user?.first_name}}
                        {{user?.last_name}}</h1>
                </div>
            </div>
            <div class="row mb-3 mt-4">
                <h5 class="col-4  font-15 ml-4 collapse-label" *ngIf="user.email">{{'Email' | translate }}</h5>
                <h5 class="col-6  font-14 opacity-60 text-muted  font-400 collapse-text" *ngIf="user.email">
                    {{user.email}}</h5>
                <h5 class="col-4 text-start font-15 ml-4 collapse-label" *ngIf="user.mobile">{{'PhoneNumber' | translate }}</h5>
                <h5 class="col-6 text-end font-14 opacity-60 text-muted font-400 collapse-text" *ngIf="user.mobile">
                    {{user.mobile}}</h5>
                <h5 *ngIf="user?.country_id" class="col-4 collapse-label ml-4 font-15">{{'Country' | translate }}</h5>
                <h5 *ngIf="user?.country_id"
                    class="col-6 text-end collapse-text text-muted font-14 opacity-60 font-400">
                    {{user.country.name}}</h5>

                <ng-container *ngFor="let response of user?.responses">
                    <ng-container *ngIf="(response.response != null && response.response != '') || response.values.length>0 ">
                    <h5 *ngIf="(!hasPublicLabel(response) && response.response != null && !isChoices(response)) || (isChoices(response) && response.values.length > 0 && !hasPublicLabel(response))"
                        class="col-4 text-start font-15  ml-4 collapse-label ">{{response.form_input.label}}</h5>
                    <h5 *ngIf="(hasPublicLabel(response) && response.response != null && !isChoices(response)) || (isChoices(response) && response.values.length > 0 && hasPublicLabel(response))"
                        class="col-4 text-start font-15 ml-4 collapse-label">{{response.form_input.public_label}}</h5>
                    <h5 *ngIf="response.response != null && response.response != '' && response.form_input.type.name != 'image'  ; else custom"
                        class="col-6 text-end font-14 text-muted collapse-text opacity-60 font-400"
                        style="float: right;"> 
                        <div *ngIf="!response.response.includes('http')">{{response.response}} </div>
                        <a *ngIf="response.response.includes('http')" href="{{response.response}}" style="color: #4563ac !important;">
                            {{response.response}}
                        </a>
                    </h5>
                    <h5 class="col-6 text-end font-14 text-muted collapse-text opacity-60 font-400"
                        *ngIf="response.form_input.type.name == 'image'" style="float: right;">
                        <a class="FilesImages" href="{{filesUrl + response.response}}" target="_blanc"> {{'FilesImages' |
                            translate}}
                        </a>
                    </h5>
                    <ng-template #custom>
                        <ng-container
                            *ngIf="response.form_input.form_input_type_id== 6 || response.form_input.form_input_type_id== 8  ; else others">
                            <ng-container *ngFor="let value of response.values; let i=index">
                                <span
                                    class="col-6 text-end font-14 text-muted collapse-text opacity-60 font-400"
                                    style="float: right;">
                                    {{value.val.value}}
                                </span>
                                <span *ngIf="i < response.values.length - 1" class="col-4 text-start font-15  ml-4 collapse-label ">-</span>
                            </ng-container>
                        </ng-container>
                        <ng-template #others>
                            <h5 class="col-6 text-end font-14 opacity-60 text-muted collapse-text font-400" *ngIf="response.form_input.type.name != 'image'" 
                                style="float: right;"> {{ response.values[0]?.val.value }}</h5>
                        </ng-template>
                    </ng-template>
                    
                   
                </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>