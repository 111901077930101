import { User } from './../../models/user';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Utils } from '../../utils';
declare var jQuery: any;

@Component({
  selector: 'app-item-user',
  templateUrl: './item-user.component.html',
  styleUrls: ['./item-user.component.css']
})
export class ItemUserComponent implements OnInit {
  @Input() user: User
  filesUrl= environment.filesUrl;
  @Input() congressId: number;

  constructor() { 
  }

  ngOnInit(): void {
    if (this.user.user_congresses[0].chat_info) {
      this.showChatInfo();
    }
    this.user.last_name = this.user.last_name.toUpperCase();
    this.user.first_name = Utils.capitalizeFirstLetters(this.user.first_name);
  }
    
showChatInfo(){
 let chatInfo;
 chatInfo= this.user.user_congresses[0].chat_info.slice(0,-1);
 this.user.chatInfoArray=chatInfo.split(';');
}

  colapse(id) {
    jQuery('#tocollapse' + id).slideToggle("medium", function () {
    });
  }

  firstLetters(user) {
    return Utils.getUserFirstLetters(user);
  }

  isChoices(response) {
    return Utils.withChoices(response);
  }

  hasPublicLabel(response){
    return !this.isChoices(response) && response.response != null && response.form_input.public_label != null || (this.isChoices(response) && response.values.length > 0 && response.form_input.public_label != null ) ;
  }
    
}
