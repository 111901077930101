import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Service} from '../models/assignment_service';
import {Establishment} from '../models/establishment';
import { Country } from '../models/Country';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllCountries(congressId = null){
    const url = environment.baseUrl + "/countries?congressId=" + congressId;
    return this.http.get<Array<Country>>(url)
      .pipe(catchError(this.handleErrors));
  }

  uploadFile(file: File) {
    const data: FormData = new FormData();
    data.append('files', file, file.name);
    return this.http.post<any>(environment.baseUrl + '/files/upload-resource', data);
  }

  
  convertCurrencyRates(convertFrom, convertTo) {
    const url = environment.baseUrl + '/currency/convert-rates?convertFrom=' + convertFrom + '&convertTo=' + convertTo;
    return this.http.get(url);
  }

  getEtablissementByCongressId(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/etablissements/all';
    return this.http.get<Establishment[]>(url,{ headers: headers });
  }

  getEtablissementByCongressIdPublic(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/etablissements/all/public';
    return this.http.get<Establishment[]>(url, { headers: headers });
  }

  getServiceByCongressId(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/services/all';
    return this.http.get<Service[]>(url,{ headers: headers });
  }

  getServiceByCongressIdPublic(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/services/all/public';
    return this.http.get<Service[]>(url, { headers: headers });
  }

  convertCurrencyRates2() {
    const url = 'https://openexchangerates.org/api/latest.json?app_id='+ environment.changerates
    return this.http.get(url);
  }

  getIpAddress(): Promise<string> {
    return this.http.get<{ ip: string }>('https://jsonip.com')
      .toPromise()
      .then(response => response.ip)
      .catch(error => {
        throw error;
      });
  }

  async storeNewLogs(congress_id: number, form_id: number): Promise<any> {
    try {
      const ip_address = await this.getIpAddress();
      const url = environment.baseUrl + '/logs/store-logs';
      return this.http.post(url, {
        "congress_id": congress_id,
        "form_id": form_id,
        "ip_address": ip_address
      }).toPromise();
    } catch (error) {
      throw error;
    }
 }

 async updateLog (congress_id, form_id,  steps, user, storage){
  let body;
  try {
  const ip_address = await this.getIpAddress();
  if(steps==1){
    body = {
      congress_id: congress_id,
      form_id: form_id,
      ip_address: ip_address,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      mobile: user.mobile,
      gender: user.gender,
      country_id: user.country_id,
      steps: steps
    };
  }else{
    body = {
      congress_id: congress_id,
      form_id: form_id,
      ip_address: ip_address,
      email: user.email,
      storage: storage,
      steps: steps
    };
  }
  const url = environment.baseUrl + '/logs/update-logs';
  return this.http.post(url, body).toPromise();
} catch (error) {
  throw error;
}
 }
  addStat(congress_id, form_id, errorType){
    const url = environment.baseUrl + "/congress/" + congress_id +"/dashboard-stats/set-dashboard-stats";
    return this.http.post(url, {form_id : form_id, error_type: errorType});
}
}
