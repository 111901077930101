<section [ngClass]="{'section': !isRoom}" *ngIf="configLandingPage?.prg_title || configLandingPage?.prg_title_en || configLandingPage?.prg_title_ar || isRoom" style=" background-color: #fff;" id="programme">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 mt-2">{{getValueTranslate('prg_title')}}</h4>
          <button *ngIf="configLandingPage?.prg_file" type="button" class="btn btn-primary download-btn" (click)="downloadPrgFile()">{{'DownloadProgram' | translate}}</button>
          <p class="text-muted para-desc mx-auto mb-0">{{getValueTranslate('prg_description')}}</p>
        </div>
      </div>
    </div>
    <div class="row mt-4 pt-2 justify-content-center">
      <ul *ngFor="let day of dateOfEvent; let index = index" class="nav nav-tab" id="myTab" role="tablist" style="inline-size: 355px;"
        class="nav nav-pills rounded nav-justified flex-column flex-sm-row col-lg-2 text-center">
        <li class=" nav-item tablinks" (click)="showDay(day.getDate(), index)">
          <a class="nav-link " id="home-tab" data-toggle="tab" href="#first" role="tab" aria-controls="firts"
            aria-selected="true" [class.active]="selected_day == index">
            <div class="text-center pt-1 pb-1 ">
              <h4 class="title font-weight-normal mb-0">
                {{ index + 1 }}{{ lang != 'ar' ? (index + 1 | dateSuffix | translate) : '' }}
                {{ "CONGRESS.DAY" | translate }}
              </h4>
            </div>
          </a>
        </li>

      </ul>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6 mt-4 pt-2 single-access tabcontent" *ngFor="let acees of one_day_accesss">
        <div class="card event-schedule rounded border">
          <div class="card-body">
            <div class="media">
              <ul class="date text-center text-primary mr-3 mb-0 list-unstyled">
                <li class="day font-weight-bold mb-2">{{acees?.start_date | date:'dd'}}</li>
                <li class="month font-weight-bold">{{acees?.start_date | date:'MMMM':''}}</li>
              </ul>
              <div class="media-body content">
                <h4><a href="javascript:void(0)" class="text-dark title">{{getValueTranslateAccess('name', acees)}}</a>
                </h4>
                <p class="text-muted location-time"><span class="text-dark h6"
                    *ngIf="acees?.speaker?.first_name!==null">{{'CONGRESS.SPEAKERS'|translate}}:</span>
                  {{acees?.speaker?.first_name}} {{acees?.speaker?.last_name}} <br> <span
                    class="text-dark h6">{{'CONGRESS.Time'|translate}}:</span> {{acees?.start_date |
                  date:'HH:mm'}} {{'CONGRESS.TO'|translate}} {{acees?.end_date | date:'HH:mm'}}</p>
                <a *ngIf="configLandingPage?.is_inscription===1" (click)="redirectToRegister()"
                  class="btn btn-sm btn-outline-primary mouse-down">{{'HOME.SIGN UP'|translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
