import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {StorageService} from './storage.service';
import {catchError} from 'rxjs/operators';
import {Message} from '../models/message';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  getMessagesBySubmissionId(id: string) {
    const url = environment.baseUrl + '/messages/getBySubmission/' + id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }


  sendMessage(message: Message) {

    const url = environment.baseUrl + '/messages/';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, message, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  consultedMessages(id: string) {
    const url = environment.baseUrl + '/messages/consulted/' + id + '/?isAuthor=1';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
}

