import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Access } from 'src/app/shared/models/Access';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Congress } from 'src/app/shared/models/congress';
import { LandingPageOrganizer } from 'src/app/shared/models/LandingPageOrganizer';
import { LandingPageSpeaker } from 'src/app/shared/models/LandingPageSpeaker';
import { Organization } from 'src/app/shared/models/organization';
import { AccessService } from 'src/app/shared/services/access.service';
import { CongressService } from 'src/app/shared/services/congress.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Company } from 'src/app/shared/models/Company';
import { LandingPageSponsorPack } from 'src/app/shared/models/LandingPageSponsorPack';
import { SEO } from 'src/app/shared/models/Seo';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-landing-page-event',
  templateUrl: './landing-page-event.component.html',
  styleUrls: ['./landing-page-event.component.css'],

})
export class LandingPageEventComponent implements OnInit {
  navClass = 'nav-light';
  busy: Subscription;
  congress: Congress;
  configLandingPage: ConfigLandignPage;
  ready = false;
  speakersReady = false;
  sponsorsReady = false;
  organizersReady = false;
  speakers: LandingPageSpeaker[] = [];
  organizers: LandingPageOrganizer[] = [];
  sponsors: Organization[] = [];
  accesses: Access[] = [];
  programReady = false;
  eventLocation: Location;
  congress_id;
  selectedLang = 'fr';
  lang: string;
  partners: Organization[] = [];
  partnersReady: boolean = false;
  companies: Company[] = [];
  companiesReady: boolean = false;
  sponsorPacks: LandingPageSponsorPack[] = [];
  sponsorPacksReady: boolean = false;
  seo:SEO;

  constructor(private congressService: CongressService, private accessService: AccessService, private activatedRoute: ActivatedRoute,private router: Router,
    private storageService: StorageService, private translate: TranslateService,private meta: Meta,
    private titleService: Title,) {
    const lang = this.storageService.read<string>('lang');
    this.selectedLang = lang ? lang : 'fr';
    this.activatedRoute.params.subscribe(params => {
      this.congress_id = params['id_congress'];
    });
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.getCongress();
    this.getConfigLandingPage();
    this.getLandingPageSpeakers();
    this.getLandingPageOrganizers();
    this.getSponsors();
    this.getAccesses();
    this.getPartners();
    this.getCompanies();
    this.getSponsorPacks();
    this.busy = this.congressService.getSeoByPage(parseInt(this.congress_id),"landing-page")
      .subscribe(
        (data: SEO) => {
          this.seo = data;
          this.titleService.setTitle(this.seo?.title);
          this.meta.updateTag(
            {
              name: 'description',
              content: this.seo?.meta_description
            });
            this.meta.updateTag(
              {
                name: 'keywords',
                content: this.seo?.meta_keywords
              });
        });
  }
  
  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congress_id).subscribe((data: any) => {
      if (data.config_landing_page) {
        if(data.config_landing_page && data.config_landing_page.theme_color !== 'default'){
          this.changeColor(data.config_landing_page.theme_color);
          // TODO sombre mode this.changeTheme(data.config_landing_page.theme_mode);
        }
        this.configLandingPage = data.config_landing_page;
        if (data.configLocation) {
          this.eventLocation = data.configLocation;
        }
        if (this.configLandingPage.page_title_ar && this.lang == 'ar') {
          this.titleService.setTitle(this.configLandingPage.page_title_ar);
        } else if (this.configLandingPage.page_title) {
          this.titleService.setTitle(this.configLandingPage.page_title);
          
        }
      }
      this.ready = true;
    });
  }
  getLandingPageSpeakers() {
    this.busy = this.congressService.getLandingPageSpeakers(this.congress_id).subscribe((data: LandingPageSpeaker[]) => {
      this.speakers = data;
      this.speakersReady = true;
    });
  }
  getLandingPageOrganizers() {
    this.busy = this.congressService.getLandingPageOganizers(this.congress_id).subscribe((data: LandingPageOrganizer[]) => {
      this.organizers = data;
      this.organizersReady = true;
    });
  }
  getSponsors() {
    this.busy = this.congressService.getSponsorsByCongress(this.congress_id, 0).subscribe((data: Organization[]) => {
      this.sponsors = data;
      this.sponsorsReady = true;
    });
  }
  getAccesses() {
    this.busy = this.accessService.getAllAccessByCongress(this.congress_id).subscribe((data: any) => {
      this.accesses = data;
      this.programReady = true;
    });
  }
  changeTheme(theme: string) {
    document.getElementById('theme-opt').setAttribute('href', './assets/css/' + theme + '.css');
  }
  changeColor(color: string) {
    document.getElementById('color-opt').setAttribute('href', './assets/css/colors/' + color + '.css');
  }
  toRegister() {
    if (this.configLandingPage.register_link === null) {
         this.router.navigate(['/inscription-event/' + this.configLandingPage.congress_id]);
        }
       else {
        window.open(this.configLandingPage.register_link, '_blank');
        }
      }
  toAddSubmission() {
    if(this.storageService.isExist(StorageService.USER_TOKEN_KEY)) {
      this.router.navigate(['/user-profile/submission/congress/'+this.configLandingPage.congress_id+'/add']);
    } else {
      this.storageService.write(StorageService.SEND_SUBMISSION,true);
      this.router.navigate(['/landingpage/'+this.configLandingPage.congress_id+'/login']);
    }
  }

  langChange(event: string) {
    this.lang = event;
  }

  getPartners() {
    this.busy = this.congressService.getSponsorsByCongress(this.congress_id, 1).subscribe((data: Organization[]) => {
      this.partners = data;
      this.partnersReady = true;
    });
  }

  getCompanies() {
    this.busy = this.congressService.getAllCompanies(this.congress_id).subscribe((data: Company[]) => {
      this.companies = data;
      this.companiesReady = true;
    });
  }

  getSponsorPacks() {
    this.busy = this.congressService.getAllSponsorPacks(this.congress_id).subscribe((data: LandingPageSponsorPack[]) => {
      this.sponsorPacks = data;
      this.sponsorPacksReady = true;
    });
  }

  getCongress() {
    this.busy = this.congressService.getCongressDetailsById(this.congress_id).subscribe(data => {
      this.congress = data;
    })
  }
}
