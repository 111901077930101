import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.css']
})
export class PriceDisplayComponent implements OnInit {
  @Input() congress;
  @Input() forSponsors = false;
  @Input() user;
  @Input() currency;
  @Input() convertedPrice;
  @Output() nextStep: EventEmitter<Boolean> = new EventEmitter();
  @Output() previousStep: EventEmitter<Boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  emitNextStep() {
    this.nextStep.emit();
  }

  emitPreviousStep() {
    this.previousStep.emit();
  }
}
