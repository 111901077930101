<section class="section bg-light" *ngIf="configLandingPage?.sponsor_pack_title || configLandingPage?.sponsor_pack_title_en || configLandingPage?.sponsor_pack_title_ar"
    id="sponsorPacks">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4"> {{getValueTranslate('sponsor_pack_title')}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{getValueTranslate('sponsor_pack_description')}}</p>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let sponsorPack of sponsorPacks">
                <div class="card team text-center rounded border-0">
                    <div class="card-body">
                        <div class="content pt-3">
                            <div class="ql-snow ql-disabled">
                                <div class="ql-editor">
                                    <div
                                        [innerHTML]="transformHtml(getValueTranslateSponsorPack('description', sponsorPack))">
                                        {{getValueTranslateSponsorPack('description', sponsorPack)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>