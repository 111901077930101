import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Credentials } from 'src/app/shared/models/credentials';
import { RequestLandingPage } from 'src/app/shared/models/RequestLandinPage';
import { User } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CongressService } from 'src/app/shared/services/congress.service';
import { LandingPageService } from 'src/app/shared/services/landingPage.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { SEO } from 'src/app/shared/models/Seo';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-auth-landing-page',
  templateUrl: './auth-landing-page.component.html',
  styleUrls: ['./auth-landing-page.component.css']
})
export class AuthLandingPageComponent implements OnInit {
  busy: Subscription;
  configLandingPage: ConfigLandignPage;
  requestLandingPage : RequestLandingPage;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl;  
  congress_id;
  credentials: Credentials = new Credentials();
  isSubmitted = false;
  redirectUrl: string;
  data;
  selectedLang:string="fr";
  today = new Date();
  seo:SEO;
  loader = true;
  constructor(private congressService: CongressService, private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private landingPageService : LandingPageService,private meta: Meta,
    private titleService: Title,) {
      const lang = this.storageService.read<string>('lang');
      this.selectedLang = lang ? lang : 'fr';
    this.activatedRoute.params.subscribe(params => {
      this.congress_id = params['id_congress'];
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params['token'] && params['compte'] == 'non') {
        Utils.swalMessage('SHARED.ERROR', 'VerifyAccount', 'error', this.translate);
        router.navigateByUrl('/landingpage/' + this.congress_id + '/login');
        return;
      }
      if (params['token']) {
        let url;
        this.userService.getUserByEmail(params['user']).subscribe(data => {
          this.storageService.write(StorageService.USER_KEY, data);
          if(this.userService.isUserRegistred(this.congress_id) ){
            this.storageService.remove(StorageService.REDIRECT_URL);
            this.userService.loggedUser = data;
            this.storageService.write(StorageService.USER_TOKEN_KEY, params['token']);
            if ( this.configLandingPage && new Date(this.configLandingPage.opening_date) > this.today) {
              router.navigateByUrl('/landingpage/' + this.congress_id + '/waiting-room')
            } else {
              url = this.redirectUrl ? this.redirectUrl : '/room/' + params['id'] + '/event-room';
              this.router.navigate([url]).then(() => {
                location.reload();
              });
            }
          } else {
            Utils.swalMessage('SHARED.ERROR', 'VerifyInscription', 'error', this.translate);
            router.navigateByUrl('/landingpage/' + this.congress_id + '/login')
          }
        });
      }

    });

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 2000);
    this.getConfigLandingPage();
    this.getRequestLandingPageConfig();
    this.busy = this.congressService.getSeoByPage(parseInt(this.congress_id),"login-landing-page")
      .subscribe(
        (data: SEO) => {
          this.seo = data;
          if (this.seo) {
            this.titleService.setTitle(this.seo.title);
            this.meta.updateTag(
              {
                name: 'description',
                content: this.seo.meta_description
              });
            this.meta.updateTag(
              {
                name: 'keywords',
                content: this.seo.meta_keywords
              });
          }
        });

    
  }
  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congress_id).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
      }
    });
  }

  loginSubmit() {
    this.isSubmitted = true;
    if (!this.credentials.password || !this.credentials.email) {
      return;
    }
    var innerWidth = window.innerWidth;
    var user: User = this.storageService.read(StorageService.USER_KEY);
    this.busy = this.authService.login(this.credentials)
      .subscribe(
        (data: any) => {
          this.storageService.write(StorageService.USER_KEY, data.user);
          if (this.userService.isUserRegistred(this.congress_id)) {
            if (this.storageService.isExist(StorageService.SEND_SUBMISSION) && this.storageService.read(StorageService.SEND_SUBMISSION)) {
              this.storageService.remove(StorageService.SEND_SUBMISSION);
              this.router.navigate(['/user-profile/submission/congress/' + this.congress_id + '/add']);
            } else if ( this.configLandingPage && new Date(this.configLandingPage.opening_date) > this.today) {
              this.router.navigateByUrl('/landingpage/' + this.congress_id + '/waiting-room')
            } else {
              var innerWidth = window.innerWidth;
              var user: User = this.storageService.read(StorageService.USER_KEY);
              if (innerWidth < 900 && this.configLandingPage && (this.configLandingPage.redirect_to_pwa == true)) {
                if (this.requestLandingPage?.dns_pwa) {
                  window.location.href = this.requestLandingPage.dns_pwa + "#/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + data.token;
                }
                else {
                  window.location.href = environment.pwaUrl + "/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + data.token;
                }
              }
              else if (this.requestLandingPage?.dns) {
                window.location.href = this.requestLandingPage?.dns + '#/room/' + this.congress_id + '/event-room';
              }
              else {
                this.router.navigate(['/room/' + this.congress_id + '/event-room']);
              }
            } 
            this.storageService.remove(StorageService.REDIRECT_URL);
            this.userService.loggedUser = data.user;
            this.storageService.write(StorageService.USER_TOKEN_KEY, data.token);
          }
          else {
            Utils.swalMessage('SHARED.ERROR', 'VerifyInscription', 'error', this.translate);
          }
        },
        (error) => {
          Utils.swalMessage('SHARED.ERROR', 'VerifyAccount', 'error', this.translate);
        }
      );
  }
  facebook() {
    window.location.href = environment.baseUrl + '/login/facebook?&id=' + this.congress_id;
  }
  registerUsingGoogle() {
    window.location.href = environment.baseUrl + '/login/google?&id=' + this.congress_id;
  }

  getRequestLandingPageConfig() {
    this.landingPageService.getRquestLandingPageWithCongressId(this.congress_id).subscribe((data: any) => {
      if (data.status != 0) {
      this.requestLandingPage = data;
      }
    });
  }
}
