<div class="main-container">
  <div class="container">
    <div class="row">
      <div class="col-md-3 page-sidebar animated fadeInLeft" style="padding: 0;">
        <aside>
          <div class="inner-box">
            <div class="user-panel-sidebar">
              <div class="collapse-box">
                <h5 class="collapse-title"><a
                  routerLink="/user-profile/user-info">{{'UserInfo' | translate}}</a></h5>
              </div>
              <div class="collapse-box">
                <h5 class="collapse-title"><a
                  routerLink="./submission/list">{{'SUBMISSION.SUBMISSIONS_LIST' | translate}}</a></h5>
              </div>
              <div class="collapse-box">
                <h5 class="collapse-title"><a
                  routerLink="/user-profile/my-events">{{'HOME.MY_CONGRESSES'| translate}}</a></h5>
              </div>
              <div class="collapse-box">
                <h5 class="collapse-title"><a
                  routerLink="./payment/list">{{'HOME.My_Paiements' | translate}}</a></h5>
              </div>
              <div class="collapse-box">
                <h5 class="collapse-title"><a
                  routerLink="/user-profile/my-contacts">{{'HOME.MY_CONTACTS'|translate}}</a></h5>
              </div>
              <!-- /.collapse-box  -->
            </div>
          </div>
          <!-- /.inner-box  -->
        </aside>
      </div>
      <!--/.page-sidebar-->
      <div class="col-md-9 page-content">
        <router-outlet></router-outlet>
        <!--/.page-content-->
      </div>
    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</div>
<!-- /.main-container -->


