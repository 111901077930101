import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Gender } from '../../models/Gender.model';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
   @Input() user = new User();
   @Input() showPhoto : boolean;
   @Input() genders : Gender[];
   isSubmitted = false;
   confirmMode: boolean;
   photoUploaded: boolean;
   uploadCrop: any;
   lastAutofillEmail = "";
   busy: Subscription;
   currentLanguage: string;

  constructor(private userService:UserService, private translate:TranslateService) {
    setInterval(()=> {
      this.currentLanguage = this.translate.currentLang;
    }, 1000);
  }

  ngOnInit(): void {
    
  }

  submitForm() {
    this.isSubmitted = true;
  }

  uploadedPhoto(e) {
    this.photoUploaded = true; 
    this.uploadCrop = e;
  }

  setPhoto(event, path = null) {
    let file = null;
    if (this.uploadCrop !== undefined) {
      this.uploadCrop.croppie('destroy');
    }
    if (event !== null) {
      file = event.target.files[0]
    }
    this.uploadCrop = Utils.initCroppie('upload-demo', file, path);
    if (!path) {
      this.photoUploaded = true;
    }
  }

  autofillUser() {
    if (!this.user.email || !Utils.isEmail(this.user.email) || this.lastAutofillEmail === this.user.email) {
      return;
    }
    this.busy = this.userService.getUserByEmail(this.user.email).subscribe(data => {
      this.user.last_name = data.last_name;
      this.user.first_name = data.first_name;
      this.user.gender = data.gender;
      this.user.mobile = data.mobile;
      this.user.password=data.password
      this.lastAutofillEmail = this.user.email;
    });

  }

  getValueTranslate(item, colName = 'label') {
    return Utils.getValueTranslate(this.currentLanguage, item, colName);
  }
}
