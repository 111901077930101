<div class="inner-box">
  <h2 class="title-2"><i class="icon-mail"></i> {{ 'DiscussCorrector' | translate }} 
  </h2>
  <div class="inbox-wrapper">

    <hr>
    <div class="row">

      <div class="col-md-12 col-lg-12 chat-row">
        <div class="message-chat">

          <div #scrollMe ngBusy="busy" class="message-chat-history">


            <div *ngFor="let message of messages; let i = index;let last= last"
                 ngClass="{'object-unsent': message.isauthor==1&& message.status==-1,
                            'object-sending': message.isauthor==1&&message.status==1,
                            'object-user': message.isauthor==0,
                            'object-me': message.isauthor==1 || message.status==2 }"
                 class="chat-item ">

              <div class="chat-item-content">

                <div class="chat-item-content-inner">
                  <div class="msg">
                    <p>{{message.content}}</p>
                  </div>


                  <span class="time-and-date"> {{message.createdDate | date:'HH:mm, M/d/yy'}}
                  </span>

                </div>
              </div>
              <img *ngIf="message.status==1" [src]="imgUrl+'LebWZMZKlYYSaJ80eP8zNn0zmD3LOkZVZdo4QOkq.gif'" width="3%">

            </div>


          </div>
        </div>
        <div>
          <div class="type-message">
            <form>
              <div class="type-form">
              <textarea (ngModel)="message.content" class="input-write form-control" name="message"
                        placeholder="Type a message..." type="text"></textarea>

              </div>


            </form>

          </div>
          <div class="button-wrap">
            <button (click)="addMessage()" class="btn btn-primary" type="submit">
              {{ 'Send' | translate }}  <i aria-hidden="true" class="fas fa-paper-plane"></i>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
<!--/. inbox-wrapper-->
