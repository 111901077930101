import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-specific-banner',
  templateUrl: './specific-banner.component.html',
  styleUrls: ['./specific-banner.component.css']
})
export class SpecificBannerComponent implements OnInit {

  @Input() configLandingPage: ConfigLandignPage;
  @Input() lang: string;
  @Input() bannerTwo: boolean = false;
  screenWidth: number;
  filesUrl = environment.filesUrl;
  busy: Subscription;


  constructor() { 
    this.screenWidth = window.screen.width;

  }

  ngOnInit(): void {
  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

}
