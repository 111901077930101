import { StorageService } from './../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-languages',
  templateUrl: './header-languages.component.html',
  styleUrls: ['./header-languages.component.css']
})
export class HeaderLanguagesComponent implements OnInit {
  selectedLang: string = 'fr';
  imgUrl = environment.imgUrl; 
  flags = {
    'fr': environment.imgUrl +"dSoN6se9r6JyDOotJjdN218dYH7ZC0q3mRaHfMZE.svg",
    'en': environment.imgUrl +'vYz99hGCBMQJcR2NWsjo4w3WfpnMcYPScVc1R49u.svg',
    'ar': environment.imgUrl +'GQWLNJlPmnbYXkzLRY733ABKZulDZjgrlMTChjm6.svg'
  };
  @Input() isCondensed: boolean = false;
  @Output() menuCondesned = new EventEmitter<boolean>();
  @Output() languageChanged = new EventEmitter<string>();

  constructor(private translate: TranslateService,
    private storageService: StorageService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showLanguages()
  }

  onMenuClick(event) {
    const nextEl = document.getElementById('languages-menu');
    if (nextEl && !nextEl.classList.contains("open")) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("open");
      }
      nextEl.classList.add("open");
    } else if (nextEl) {
      nextEl.classList.remove("open");
    }
    return false;
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.translate.use(lang);
    this.storageService.write('lang', lang);
    this.toggleMenu();
    this.languageChanged.emit(this.selectedLang);
  }

  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    this.menuCondesned.emit(this.isCondensed);
    if (this.isCondensed) {
      document.getElementById("navigation").style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
    }
  }

  showLanguages() {
    if (window.screen.width > 991) {
      let languageName = document.getElementById("language-name");
      languageName.addEventListener("mouseleave", function (event) {
        document.getElementById("languages-menu").style.opacity = "0";
        document.getElementById("languages-menu").style.visibility = "hidden";
      }, false);
      languageName.addEventListener("mouseover", function (event) {
        document.getElementById("languages-menu").style.opacity = "1";
        document.getElementById("languages-menu").style.visibility = "visible";
      }, false);
      let languageMenu = document.getElementById("languages-menu");
      languageMenu.addEventListener("mouseleave", function (event) {
        document.getElementById("languages-menu").style.opacity = "0";
        document.getElementById("languages-menu").style.visibility = "hidden";
      }, false);
      languageMenu.addEventListener("mouseover", function (event) {
        document.getElementById("languages-menu").style.opacity = "1";
        document.getElementById("languages-menu").style.visibility = "visible";
      }, false);
    }
  }

}
