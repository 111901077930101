<div id="video-session-container" [ngStyle]="{'background':congress.config.agora_primary_background != null ? 'linear-gradient(-90deg,' + congress.config.agora_primary_background + ' 0, ' +congress.config.agora_secondary_background+ ' 50%,' + congress.config.agora_primary_background +' 100%)' : 'linear-gradient(-90deg, #4563ac 0, #396f7b 50%, #4563ac 100%)'}">
  <div id="video-session-container_video-layout" class="d-flex justify-content-around">
    <div id="Dish">
      <div id="pin-div"></div>
      <div id="scrolly" style="overflow: auto;">
        <div id="first-player"></div>
      <div id="local-player" class="Camera local-player"></div>
      <div id="second-player"></div>
      <div *ngFor="let c of cameras; let i=index" class="Camera player-{{i}}" id="player-{{c.uid}}"></div>
    </div>
    </div>
  </div>

  <div class="feed-control_feed-controls__1II3P">
    <div aria-hidden="true" style="width: 2.5rem; margin-right: auto; user-select: none;">&nbsp;</div>
    <button type="button" class="icon-button_button__1IfDQ feed-control_button__3DmCw" aria-label="stop sharing video."
      (click)="muteVideo()">
      <img
        src="{{localTrackState.videoTrackEnabled ?imgUrl + 'P5xGlYG5AbJxED9VhIyMuxCZs6PUDpYhaXanSlYn.svg':imgUrl + 'RGb9SWg6cG85NUmX9yS21467dxZZI15Msr2PGnrP.svg'}}" />
    </button>

    <button class="icon-button_button__1IfDQ feed-control_button__3DmCw" aria-label="Mute audio" (click)="muteAudio()">
      <img
        src="{{localTrackState.audioTrackEnabled ?imgUrl + '5ms6Ko00xm218zUon4ycoKsZKWrW84UP3J77L440.svg':imgUrl + '0hPnSdl3anCUd342frXIbqDz14caO2u1v1Fk8GVY.svg'}}">
    </button>

    <button class="icon-button_button__1IfDQ feed-control_button__3DmCw" aria-label="share screen"
      (click)="shareScreen()">
      <img
        src="{{localTrackState.screenTrackEnabled ?imgUrl + 'ajdSnyw5TunntHofzgAjC5OT8ZPH5SviYTy1FzA4.txt':imgUrl + 'lBctTEis16DbOsGow1BoFqixwnaHg8OpxQQxB9EN.svg'}}">
    </button>

    <button class="sc-fXoxut jTikPF hpn-Control sc-Fyfyc sc-jXktwP gnjchK hdiogm mr-2" (click)="fullScreen()">
      <div class="sc-gKsewC fXxBtI hpn-Icon sc-iBPRYJ iwddHR">
        <img
          src="{{localTrackState.fullScreenEnabled ?imgUrl + 'vVQGvpurE1s45ZsRzgMrCvzgHBcxvVTCqm2htSDg.txt':imgUrl + 'h2jAP9dg6t94URusNhWaczm9YlSQlMxgENotRZ1B.svg'}}">
      </div>
    </button>
    <!-- Leave -->
    <!-- 
      <button type="button" class="col-1 join" (click)="agoraJoined?leave():joinAgora()">
        <img src="{{agoraJoined ?'../../../assets/agora/icon-exit-hover.png':'../../../assets/agora/icon-exit.png'}}">
      </button>
    -->

  </div>

</div>