<div class="date-filter-styles__DateFilter-sc-1gsncjr-0 hiUda">
    <div class="calendar-styles__CalendarContainer-vpiw01-0 bJsTeI">
        <div class="date-filter-styles__TextFieldContainer-sc-1gsncjr-3 FamOt">
            <div class="sc-fzoyAV cqFVmL hpn-TextField sc-fzoLag dMnAMc">
                <div class="hpn-TextField__Label"><label class="sc-Axmtr aVHPv sc-AxmLO ebFpbq"
                        for="Date--date">{{'HOME.NameEvent' | translate}}</label></div>
                <div class="sc-AxgMl sc-AxheI dYhQvR"><input class="sc-fzqNJr bQzujk hpn-TextField__Input" name="nom"
                        type="text" autocomplete="off" aria-label="date" value="" [(ngModel)]="options.filter[0].nom"
                        id="filter"></div>
            </div>
            <hr class="search-filters-component-styles__Separator-sc-46ysgf-1 TCyew" />
            <div class="sc-fzoyAV cqFVmL hpn-TextField sc-fzoLag dMnAMc">
                <div class="hpn-TextField__Label"><label class="sc-Axmtr aVHPv sc-AxmLO ebFpbq"
                        for="Date--date">{{'CONGRESS.DATE_START' | translate}}</label></div>
                <div class="">
                    <app-ng-datepicker [(selection)]="options.filter[1].date" [withTime]="false"></app-ng-datepicker>
                </div>
                <div class="hpn-TextField__Label"><label class="sc-Axmtr aVHPv sc-AxmLO ebFpbq"
                        for="Date--date">{{'CONGRESS.DATE_END' | translate}}</label></div>
                <div class="">
                    <app-ng-datepicker [(selection)]="options.filter[2].date" [withTime]="false"></app-ng-datepicker>
                </div>
            </div>
        </div>
        <div class="date-filter-styles__Tags-sc-1gsncjr-2 hINVMd"><button aria-pressed="false"
                class="sc-fzqPZZ dCkKAb sc-fzoxKX lbAhMt" (click)="getMonth()">{{'HOME.ThisMonth' |
                translate}}</button><button aria-pressed="false" class="sc-fzqPZZ dCkKAb sc-fzoxKX lbAhMt"
                (click)="getNextMonth()">{{'HOME.NextMonth' | translate}}</button></div>
    </div>