import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Congress } from 'src/app/shared/models/congress';
import { AbstractElement, Author, Submission, SubmissionRequest } from 'src/app/shared/models/submission';
import { Theme } from 'src/app/shared/models/theme';
import { Service } from 'src/app/shared/models/assignment_service';
import { Establishment } from 'src/app/shared/models/establishment';
import { CongressService } from 'src/app/shared/services/congress.service';
import { UserService } from 'src/app/shared/services/user.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SubmissionService } from 'src/app/shared/services/submission.service';
import { CommunicationTypeService } from 'src/app/shared/services/communicationType.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Utils } from 'src/app/shared/utils';
import { Resource } from 'src/app/shared/models/Resource';
import { CommunicationType } from 'src/app/shared/models/CommunicationType';
import { FormGroupDirective } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Diff from 'diff';

declare let swal: any;
declare let jQuery: any;

@Component({
  selector: 'app-submission-form',
  templateUrl: './submission-form.component.html',
  styleUrls: ['./submission-form.component.css'],
})
export class SubmissionFormComponent implements OnInit, AfterViewInit {
  congress: Congress = new Congress();
  submission: Submission = new Submission();
  submissionRequest: SubmissionRequest = new SubmissionRequest();
  @Output() hasSubmissionEvent = new EventEmitter<boolean>();
  @Output() submission_idEvent = new EventEmitter<string>();
  @Output() submission_Event = new EventEmitter<Submission>();
  @ViewChild('editForm') editForm: FormGroupDirective;

  hasSubmission: boolean;
  isSubmitted = false;
  isEditMode = false;
  busy: Subscription;
  busy2: Subscription;
  themes: Theme[] = [];
  authors: Author[] = [];
  firstAuthor = new Author();
  resourceIds: number[] = [];
  showInputTextEstb = false;
  showInputTextService = false;
  canEdit = true;
  communicationTypes: CommunicationType[];
  submission_id: any;
  congress_id: any;
  addExternalFiles = false;
  code: string = null;
  filesUrl = environment.filesUrl;
  ready = false;
  congressId:any;
  /**
   * 0 => Serie
   * 1 => Cas Clinique
   */
  abstractType = 0;
  abstractVariants: Array<Array<AbstractElement>> = [];

  services: Service[] = [];
  establishments: Establishment[] = [];
  toUpperCase = true;

  authorPositions: string[] = [];
  isLoaded: boolean;
  emptyFileLabel: string;
  pathsResouces = [];
  filesLoaded = false;
  currentLang: string;
  establishmentsReady: boolean;
  servicesReady: boolean;
  description : string;
  imgUrl = environment.imgUrl;

  constructor(
    private route: ActivatedRoute,
    private congressService: CongressService,
    private userService: UserService,
    private router: Router,
    private submissionService: SubmissionService,
    private translate: TranslateService,
    private sharedService: SharedService,
    private communicationTypeService: CommunicationTypeService,
    private sanitizer: DomSanitizer
  ) {

    this.submission_id = parseInt(this.route.snapshot.paramMap.get('submission_id'), 0);
    this.addExternalFiles = this.router.url.indexOf('submit-resources') !== -1 ? true : false;
    this.congress_id = parseInt(this.route.snapshot.paramMap.get('congressId'), 0);
    // this.code = this.route.snapshot.queryParamMap.get('code');
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit() {
    this.firstAuthor = {
      first_name: this.userService.loggedUser.first_name,
        last_name: this.userService.loggedUser.last_name,
        email: this.userService.loggedUser.email,
        mobile: this.userService.loggedUser.mobile,
        rank: 1,
        services: [],
        etablissement_id: null,
        customService: '',
        customEstablishment: '',
        profile_pic: this.userService.loggedUser.profile_img?.path,
        otherService : '',
        otherEstablishment : '',
        author_services : [],
        etablissment: null,
        serviceLabel: "",
        country_id: "TUN",
        status: ''
    };
    const abstractSerie = [];
    abstractSerie.push(new AbstractElement('Introduction & Objectif'));
    abstractSerie.push(new AbstractElement('Méthode'));
    abstractSerie.push(new AbstractElement('Résultats'));
    abstractSerie.push(new AbstractElement('Conclusion'));
    this.abstractVariants.push(abstractSerie);
    const abstractCasClinique = [];
    abstractCasClinique.push(new AbstractElement('Inroduction'));
    abstractCasClinique.push(new AbstractElement('Observation'));
    abstractCasClinique.push(new AbstractElement('Conclusion'));
    this.abstractVariants.push(abstractCasClinique);
    this.getAllCommunicationType();
    if (this.submission_id) {
      this.initSelects();
      this.isEditMode = true;
      this.getSubmissionById();
    } else {
      this.firstAuthor = {
        first_name: this.userService.loggedUser.first_name,
        last_name: this.userService.loggedUser.last_name,
        email: this.userService.loggedUser.email,
        mobile: this.userService.loggedUser.mobile,
        rank: 1,
        services: [],
        etablissement_id: null,
        customService: '',
        customEstablishment: '',
        profile_pic: this.userService.loggedUser.profile_img?.path,
        otherService : '',
        otherEstablishment : '',
        author_services : [],
        etablissment: null,
        serviceLabel: "",
        country_id: "TUN",
        status: ''
      };
      this.authors.push(this.firstAuthor);
      this.ready = true;
      this.submissionRequest.abstract = this.abstractVariants[
        this.abstractType
      ];
      this.getCongressThemes(this.congressId);
      this.getEtablissements(this.congressId);
      this.getServices(this.congressId);
      this.initEmptyDocuments();
      this.submission.congress_id = this.congressId;
      this.busy = this.congressService
        .getCongressDetailsById(this.congressId)
        .subscribe((congress) => {
          this.congress = congress;
          if (!this.congress.config_submission) {
            Utils.swalMessage('SHARED.ERROR', 'CONGRESS.NoSubmission', 'warning', this.translate);
            this.router.navigate(['room/' + this.congressId +'/list-my-submissions']);
            return;
          }
          if (new Date(this.congress.config_submission.end_submission_date) < new Date()) {
            Utils.swalMessage('SHARED.ERROR', 'SUBMISSION.DEADLINE', 'warning', this.translate);
            this.router.navigate(['room/' + this.congressId +'/list-my-submissions']);
            return;
          }
        });
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.currentLang = event.lang;
    });
  }

  ngAfterViewInit() {
    this.initSelects();
  }


  private initSelects() {
    const baseContext = this;
    Utils.initSelect('select-estb');

    jQuery('.select-estb').on('select2:select', function (e) {
      if (e.params.data.id === '-1') {
        baseContext.showInputTextEstb = true;
      }
      baseContext.firstAuthor.etablissement_id = e.params.data.id;
      baseContext.firstAuthor.customEstablishment = e.params.data.text;
    });

    Utils.initSelect('select-serv');

    jQuery('.select-serv').on('select2:select', function (e) {
      if (e.params.data.id === '-1') {
        baseContext.showInputTextService = true;
      }
      baseContext.firstAuthor.services.push(e.params.data.id);
      baseContext.firstAuthor.customService = e.params.data.text;
    });
  }

  getCongressThemes(congress_id) {
    this.congressService
      .getThemesByCongressId(congress_id)
      .subscribe((themes) => {
        this.themes = themes;
        this.isLoaded = true;
      });
  }

  getEtablissements(congressId) {
    this.sharedService.getEtablissementByCongressId(congressId).subscribe((establishments) => {
      this.establishments = establishments;
      const establishment = new Establishment();
      establishment.etablissement_id = -1;
      establishment.label = 'Autre';
      this.establishments.push(establishment);
      this.establishmentsReady = true;
    });
  }

  getServices(congressId) {
    this.sharedService.getServiceByCongressId(congressId).subscribe((services) => {
      this.services = services;
      const service = new Service();
      service.service_id = -1;
      service.label = 'Autre';
      this.services.push(service);
      this.servicesReady = true;
    });
  }

  getSubmissionById() {
    this.busy2 = this.submissionService
      .getSubmissionById(this.submission_id, this.code)
      .subscribe(
        (data) => {
          if (!data || (data.user_id != this.userService.loggedUser.user_id)) {
            this.router.navigate(['room/' + this.congressId +'/list-my-submissions']);
          }
          this.hasSubmission = true;
          this.submission = data;
          if(this.submission.key_words){
          this.submission.key_words=this.submission.key_words.replace(/<br>/g, "\n");
          }
          this.abstractType = this.submission.type === 'Série' ? 0 : 1;
          this.congress = data.congress;
          this.retreiveDescription();
          data.authors.forEach((author: Author) => {
            author.author_services.forEach((authorservice: any) => {
              if (!author.services) {
                author.services = [];
              }
              author.services.push(authorservice.service.service_id);
            })
          });
          this.authors = data.authors;
          this.setAuthorsSubmissionsAndEstablishments(this.authors);
          this.getCongressThemes(data.congress_id);
          this.getEtablissements(data.congress_id);
          this.getServices(data.congress_id);
          this.initEmptyDocuments(data.resources);
          if (data.resources) {
            this.initializeResourcesIds(data.resources);
          }
          this.ready = true;
        },
        () => {
          this.initEmptyDocuments();
        }
      );
  }


  setAuthorsSubmissionsAndEstablishments(authors) {
    if (authors.length !== 0) {
      for (let i = 0; i < authors.length; i++) {
        for (let j = 0; j < this.establishments.length; j++) {
          if (authors[i].etablissement_id === this.establishments[j].etablissement_id) {
            authors[i].customEstablishment = this.establishments[j].label;
          }
        }
      }
      for (let k = 0; k < authors.length; k++) {
        for (let l = 0; l < this.services.length; l++) {
          for (let s = 0; s < authors[k].author_services.length; s++) {
            if (authors[k].author_services[s].service.service_id === this.services[l].service_id) {
              authors[k].customService = this.services[l].label;
            }
          }
        }
      }
    }
  }

  retreiveDescription() {
    this.abstractVariants[this.abstractType].forEach((submission, index) => {
      if (index + 1 < this.abstractVariants[this.abstractType].length) {
        // prendre la partie entre le titre present et le prochain titre
        submission.value = this.submission.description.slice(
          submission.title.length + 2,
          this.submission.description.indexOf(
            this.abstractVariants[this.abstractType][index + 1].title
          )
        );
        submission.value = submission.value.replace('<br>', '');
        // enlever la partie entre le titre present et le prochain titre
        this.submission.description = this.submission.description.substr(
          this.submission.description.indexOf(
            this.abstractVariants[this.abstractType][index + 1].title
          )
        );
      } else {
        // la deriniere partie prend directement le text après le titre
        submission.value = this.submission.description.slice(
          submission.title.length + 2
        );
        submission.value = submission.value.replace('<br>', '');
      }
    });
    this.submission.description = '';
  }

  initializeResourcesIds(resources: Resource[]) {
    resources.forEach((resource) => {
      this.resourceIds.push(resource.resource_id);
    });
  }



  getAllCommunicationType() {
    this.communicationTypeService.getAllCommunicationType().subscribe((data) => {
      this.communicationTypes = data;
    });
  }

  isEmptySubmission() {
    return (
      !this.submission.title ||
      !this.submission.type ||
      !this.submission.communication_type_id ||
      !this.submission.description ||
      !this.submission.theme_id 
    );
  }

  onAddSubmission() {
    this.isSubmitted = true;
    this.submission.description = '';
    this.abstractVariants[this.abstractType].forEach((abstract) => {
      this.submission.description +=
        abstract.title + ' :' + abstract.value + '<br>';
    });
    this.submission.type = this.abstractType === 0 ? 'Série' : 'Cas Clinique';
    if ((this.isEmptySubmission() && !this.addExternalFiles) || (this.addExternalFiles && this.resourceIds.length === 0)) {
      Utils.swalMessage('SHARED.ERROR', 'SUBMISSION.SUBMISSION_ADD_VALIDATE', 'warning', this.translate);
      return;
    }
    if (this.authors[this.authors.length - 1].services === undefined ||
      this.authors[this.authors.length - 1].etablissement_id === undefined ||
      this.authors[this.authors.length - 1].first_name === undefined ||
      this.authors[this.authors.length - 1].last_name === undefined) {
      this.authors.splice(this.authors.length - 1, 1);
    }
    if (this.getNumberOfWord() > this.congress.config_submission.max_words) {
      this.translate.get('SUBMISSION.CONTENT_MAX', { value: this.congress.config_submission.max_words }).subscribe((res: string) => {
        swal({
          title: 'Attention!',
          text: res,
          icon: 'warning',
          type: 'warning'
        });
      });
      return;
    }
    if (this.isEditMode) {
      this.busy2 = this.submissionService
        .editSubmission(this.submission, this.authors, this.resourceIds, this.addExternalFiles)
        .subscribe(
          () => {
            Utils.swalMessage('SHARED.SUCCESS', 'SUBMISSION.SUBMISSION_ADD_SUCCESS', 'success', this.translate);
            this.router.navigateByUrl('room/' + this.congressId +'/list-my-submissions');
          },
          () => {
            Utils.swalMessage('SHARED.ERROR', 'SUBMISSION.SUBMISSION_ADD_ERROR', 'error', this.translate);
          }
        );
    } else {
      this.busy2 = this.submissionService
        .addSubmission(this.submission, this.authors, this.resourceIds)
        .subscribe(
          () => {
            Utils.swalMessage('SHARED.SUCCESS', 'SUBMISSION.SUBMISSION_ADD_SUCCESS', 'success', this.translate);
            this.router.navigateByUrl('room/' + this.congressId +'/list-my-submissions');
          },
          () => {
            Utils.swalMessage('SHARED.ERROR', 'SUBMISSION.SUBMISSION_ADD_ERROR', 'error', this.translate);
          }
        );
    }

  }

  initEmptyDocuments(resources?: Resource[]) {
    const baseContext = this;
    if (resources) {
      resources.forEach((resource) => {
        this.pathsResouces.push(environment.filesUrl + resource.path);
      });
    }
    baseContext.submissionRequest.documents = [];
    baseContext.translate
      .get('SHARED.EMPTY_FILE')
      .subscribe((emptyFileLabel: string) => {
        baseContext.emptyFileLabel = emptyFileLabel;
      });
    baseContext.filesLoaded = true;
  }

  onUploadFile(e) {
    this.resourceIds.push(e.resource.resource_id);
    Utils.swalMessage('SHARED.SUCCESS', 'SHARED.PHOTO_ADDED_SUCCESS', 'success', this.translate);
  }

  onDeleteFile(e) {
    const index = this.resourceIds.indexOf(
      e.responseJSON.resource_id
    );
    this.resourceIds.splice(index, 1);
    Utils.swalMessage('SHARED.SUCCESS', 'SHARED.PHOTO_DELETED_SUCCESS', 'success', this.translate);
  }

  collectSubmissionAbstract(): string {
    let abstractContent = '';
    this.abstractVariants[this.abstractType].forEach((element) => {
      abstractContent += `${element.title}:\n${element.value}\n\n`;
    });
    return abstractContent;
  }

  fetchSubmissionAbstractType() {
    let abstractType = 0;
    this.abstractVariants.every((variant) => {
      let valideVariant = true;
      variant.every((abstractElement) => {
        if (
          !this.submission.abstract.find(
            (el) => el.title === abstractElement.title
          )
        ) {
          valideVariant = false;
          return false;
        }
        return true;
      });
      if (valideVariant) {
        return false;
      } else {
        abstractType++;
        return true;
      }
    });

    this.abstractType = abstractType;
    for (let i = 0; i < this.submissionRequest.abstract.length; i++) {
      this.abstractVariants[abstractType][
        i
      ].value = this.submissionRequest.abstract[i].value;
    }
  }

  getNumberOfWord() {
    let count = 0;
    this.abstractVariants[this.abstractType].forEach((element) => {
      count += element.value.split(' ').length - 1;
    });
    return count;
  }

  titleFocusOut() {
    if (this.toUpperCase && this.submission.title) {
      this.toUpperCase = false;
      this.submission.title = this.submission.title.toUpperCase();
    }
  }

  titleChange(e: any) {
    if (e.length === 0) {
      this.toUpperCase = true;
    }
  }

  onCancel() {
    const congress_id = this.congress_id ? this.congress_id : this.submission.congress_id;
    this.router.navigate(['/congress/details/' + congress_id]);
  }

  translateTheme(theme) {
    return Utils.getValueTranslate(this.currentLang, theme, 'label');
  }

  transformHtml(explanatory_paragraph, explanatory_paragraph_en): SafeHtml {
    return (this.currentLang == 'fr' || (this.currentLang == 'en' && !explanatory_paragraph_en)) ? this.sanitizer.bypassSecurityTrustHtml(explanatory_paragraph) : this.sanitizer.bypassSecurityTrustHtml(explanatory_paragraph_en);
  }


  public correction() {
    this.description = ''
    this.abstractVariants[this.abstractType].forEach((abstract) => {
      if (abstract.value )
      this.description += abstract.value + '\n';
    });
    if (this.description.length == 0) {
      Utils.swalMessage('SHARED.ERROR', 'HOME.FieldsError', 'error', this.translate);
      return;
    }
    this.busy2 = this.submissionService.correction(this.description).subscribe(
      (data: any) => {
        jQuery('#decriptionModal').modal('show');
        // Generate HTML elements to represent the changes
        const diff = Diff.diffChars(this.description, data.text);
        const diffHtml = diff.map((part) => {
          if (part.added) {
            return `<span style="background-color: #aaffaa">${part.value.replace(/\n/g, '<br>')}</span>`;
          }
          if (part.removed) {
            return `<span style="background-color: #ffaaaa">${part.value.replace(/\n/g, '<br>')}</span>`;
          }
          return part.value.replace(/\n/g, '<br>') + '';
        }).join('');

        // Display the HTML elements in a container
        setTimeout(() => {
          const container = document.getElementById('diff-container');
          container.innerHTML = ''
          container.innerHTML = diffHtml;
        }, 750);
      },
      () => {
        Utils.swalMessage('SHARED.ERROR', 'SHARED.ERROR', 'error', this.translate);
      }
    );
  }
  rtn() {
    window.history.back();
 }
}

   
