<div class="price-filter-styles__PriceFilter-sc-1ifp7ao-0 kFFhTN">
    <div class="sc-AxhCb eqpuL sc-AxhUy jfLVKY" mb="1">{{'HOME.PRICE' | translate}}</div>
    <div role="radiogroup" class="sc-fzoiQi ozSmQ hpn-radio-buttons__radio-group__fieldset sc-fznxKY hqtOlF"
        aria-labelledby="">
        <div class="sc-fzqNqU kikdAh hpn-radio-buttons__radio-group__buttons-wrapper">
            <div class="sc-fzoXWK iQzihW hpn-radio-buttons__radio-button__wrapper sc-fzpmMD epVrHh"><input
                    [(ngModel)]="options.filter[5].type" class="sc-fzoyTs jZUSDr hpn-radio-buttons__radio-button__input"
                    type="radio" (change)="checkStauts()" id="price-filter-type-any" name="price-filter-type" value="3"
                    checked=""><label
                    class="sc-fznJRM dEjFfy sc-fznWqX sc-fzoNJl bmRogW hpn-radio-buttons__radio-button__label"
                    for="price-filter-type-any">{{'HOME.Anyprice' | translate}}</label></div>
            <div class="sc-fzoXWK iQzihW hpn-radio-buttons__radio-button__wrapper sc-fzpmMD epVrHh"><input
                    [(ngModel)]="options.filter[5].type" class="sc-fzoyTs jZUSDr hpn-radio-buttons__radio-button__input"
                    type="radio" id="price-filter-type-free" name="price-filter-type" value="2"
                    (change)="checkStauts()"><label
                    class="sc-fznJRM dEjFfy sc-fznWqX sc-fzoNJl bmRogW hpn-radio-buttons__radio-button__label"
                    for="price-filter-type-free">{{'CONGRESS.FREE' | translate}}</label></div>
            <div class="sc-fzoXWK iQzihW hpn-radio-buttons__radio-button__wrapper sc-fzpmMD epVrHh"><input
                    [(ngModel)]="options.filter[5].type" class="sc-fzoyTs jZUSDr hpn-radio-buttons__radio-button__input"
                    type="radio" id="price-filter-paid" name="price-filter-type" value="1"
                    (change)="checkStauts()"><label
                    class="sc-fznJRM dEjFfy sc-fznWqX sc-fzoNJl bmRogW hpn-radio-buttons__radio-button__label"
                    for="price-filter-paid">{{'HOME.Paid' | translate}}</label></div>
        </div>
        <div *ngIf="hidePrice">
            <div class="rangeslider">
                <input class="min" name="range_1" type="range" min="0" max="999"
                    [(ngModel)]="options.filter[3].price" />
                <input class="max" name="range_2" type="range" min="0" max="999"
                    [(ngModel)]="options.filter[4].price" />
            </div>
            <div class="range-filter__dropdown show">
                <div class="range-filter__container">
                    <div class="range-filter__min"><label>{{'HOME.MinPrice' | translate}}</label><input type="number"
                            class="dsc-fzqNJr bQzujk hpn-TextField__Input" [(ngModel)]="options.filter[3].price"
                            name="min" min="0" value=""></div>
                    <div class="range-filter__max"><label>{{'HOME.MaxPrice' | translate}}</label><input type="number"
                            class="sc-fzqNJr bQzujk hpn-TextField__Input" [(ngModel)]="options.filter[4].price"
                            name="max" min="0" value=""></div>
                </div>
            </div>
        </div>
        <div>
            <hr class="search-filters-component-styles__Separator-sc-46ysgf-1 TCyew" />
            <div class="text">
                <a style="text-align: right;" (click)="Reset()">{{'HOME.Reset' | translate}}</a>
                <a  [ngStyle]="{'margin-left': lang !='ar' ? '125px' : '' ,'margin-right': lang =='ar'  ? '125px' : ''}" (click)="searchData()">{{'HOME.Apply' | translate}}</a>
            </div>
        </div>
    </div>
</div>