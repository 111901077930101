import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserInfoComponent} from './user-info/user-info.component';
import {UserProfileComponent} from './user-profile.component';
import {MessageNomodalComponent} from './message-nomodal/message-nomodal.component';
import {UserCongressComponent} from './user-congress/user-congress.component';
import {UserContactsComponent} from './user-contacts/user-contacts.component';


export const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent,
    children: [
      {
        path: 'user-info',
        component: UserInfoComponent
      },
      {
        path: 'my-events',
        component: UserCongressComponent
      },
      {
        path: 'my-contacts',
        component: UserContactsComponent
      },
      {
        path: 'submission',
        loadChildren: () => import('../submission/submission.module').then(m => m.SubmissionModule)
      },
      {
        path: 'messages/:submission_id',
        component: MessageNomodalComponent
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserProfileRoutingModule {
}
