<!-- Hero Start -->
<section class="bg-home  d-flex align-items-center"
  [ngStyle]="{'background-image': configLandingPage?.home_banner_event!==null ?  'url(' +filesUrl + configLandingPage?.home_banner_event+ ')':'url( \'imgUrl + sMcMajV6SBaUG1eCVvl6p2ETsMQCP0RW90hNw0TD.jpg  \')' }"
  style="background-image:center center;" [ngBusy]="busy">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center"> {{'HOME.RESET_PASSWORD'|translate}}</h4>

            <form class="login-form mt-4" [formGroup]="RequestConfirmResetForm" (ngSubmit)="RequestConfirmResetUser()">
              <div class="row">
                <div class="col-lg-12">

                  <div class="form-group position-relative">
                    <label>{{'HOME.EMAIL'|translate}}:<span class="text-danger">*</span></label>
                    <div class="input-icon"> <i class="icon-user fa"></i>
                      <input class="form-control pl-5" placeholder="{{'HOME.EMAIL'|translate}}:" name="email" disabled
                        formControlName="email" type="text">
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label>{{'HOME.PASSWORD'|translate}} <span class="text-danger">*</span></label>
                      <div class="input-icon"><i class="icon-lock fa"></i>
                        <input type="password" class="form-control pl-5" placeholder="{{'HOME.PASSWORD'|translate}}" required=""
                          formControlName="password" name="password">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label>{{'HOME.CONFIRM_PASSWORD'|translate}}<span class="text-danger">*</span></label>
                      <div class="input-icon"> <i class="icon-lock fa"></i>
                        <input type="password" class="form-control pl-5" placeholder="{{'HOME.CONFIRM_PASSWORD'| translate}}" required=""
                          formControlName="confirm-password" name="password">
                      </div>
                    </div>
                  </div>


                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-primary btn-block">{{'HOME.RESET' | translate}}</button>
                </div>
                <div class="mx-auto">
                  <p class="mb-0 mt-3 text-center"><a class="text-dark font-weight-bold"
                      [routerLink]="'/landingpage/'+congress_id+'/login' "> {{'HOME.BACK TO LOGIN'|translate}} </a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
