<footer class="footer footer-bar">
  <div class="container text-center">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="text-sm-left">
          <p class="mb-0" style="    font-size: 16px;">© {{year}} Powered By <a
              [href]="link"
              target="_blank" style="color:#fff;"><span>{{name}}</span>
            </a></p>
        </div>
      </div>
      <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ul class="list-unstyled text-sm-right mb-0">
          <li class="list-inline-item"><a
              [href]="link"
              target="_blank"><img
                [src]="logo"
                class="avatar avatar-ex-sm" title="Eventizer" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<a (click)=" toTop()" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top">
  <i class="fas fa-arrow-up"></i>
</a>
