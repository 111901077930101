<!-- Hero Start -->
<section class="bg-home  d-flex align-items-center"
  [ngStyle]="{'background-image': configLandingPage?.home_banner_event!==null ?  'url(' +filesUrl + configLandingPage?.home_banner_event+ ')':'url( \'imgUrl + sMcMajV6SBaUG1eCVvl6p2ETsMQCP0RW90hNw0TD.jpg  \')' }"
  style="background-image:center center;" [ngBusy]="busy">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">{{'HOME.TROUBLE LOGGING IN?'|translate}}</h4>
            <form class="login-form mt-4" role="form" [formGroup]="RequestResetForm" (ngSubmit)="RequestResetUser()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>{{'HOME.EMAIL'|translate}}: <span class="text-danger">*</span></label>
                    <div class="input-icon"> <i class="icon-user fa"></i>
                      <input type="email" class="form-control pl-5" placeholder="{{'HOME.EMAIL'|translate}}:" name="email"
                        required formControlName="email">
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-primary btn-block">{{'HOME.SEND ME MY PASSWORD' |
                    translate}}</button>
                </div>
                <div class="mx-auto text-center">
                  <p class="mb-0 mt-3 "><a class="text-dark font-weight-bold"
                      [routerLink]="'/landingpage/'+congress_id+'/login' "> {{'HOME.BACK TO LOGIN'|translate}} </a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
