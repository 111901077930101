import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SubmissionService} from '../../shared/services/submission.service';
import {Submission} from '../../shared/models/submission';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/utils';
import {environment} from '../../../environments/environment';


declare var jQuery: any;

@Component({
  selector: 'app-detail-submission',
  templateUrl: './detail-submission.component.html',
  styleUrls: ['./detail-submission.component.css']
})
export class DetailSubmissionComponent implements OnInit {
  submission_id: string;
  submission: Submission = new Submission();
  busy: Subscription;
  baseFiles = environment.baseUrl;
  utils = Utils;
  submissionReady = false;

  constructor(private route: ActivatedRoute,
              private submissionService: SubmissionService) {
    this.route.params.subscribe(params => {
      this.submission_id = params['submission_id'];
    });
  }

  ngOnInit() {
    this.getSubmissionById();
  }

  getSubmissionById() {
    this.busy = this.submissionService.getSubmissionById(this.submission_id).subscribe(
      (data) => {
        this.submission = data;
        this.submissionReady = true;
      }
    );

  }
}
