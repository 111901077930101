import {User} from './user';
import {Congress} from './congress';
import {Submission} from './submission';


export class Message {

  id: string;
  isauthor: number;
  content: string;
  createdDate: Date;
  submission: string;
  status: number;
  /*
  -1:not sent
  0: default
  1 :sending
  2 :sent

   */


}

export class MessageRequest {
  content: string;
  createdDate: Date;
  isauthor: boolean;
  submission: string;
  status: number;
  /*
  -1:not sent
  1 :sending
  2 :sent

   */


}


