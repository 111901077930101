import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CongressService} from '../../shared/services/congress.service';
import {ActivatedRoute, Router} from '@angular/router';
import {trigger} from '@angular/animations';
import {fadeIn, fadeOut} from '../../shared/animations/fade-animations';
import {environment} from '../../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-user-congress',
  templateUrl: './user-congress.component.html',
  animations: [
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn())
  ],
  styleUrls: ['./user-congress.component.scss']
})
export class UserCongressComponent implements OnInit {
  busy: Subscription;
  congresses: any[];
  page = 0;
  search: boolean;
  keyword = '';
  startDate = '';
  endDate = '';
  state = '';
  filesUrl = environment.baseUrl + '/';
  perPage = 10;
  offset = 0;
  notEmptyPost = true;
  notscrolly = true;
  options = {
    name: 'HOME.MY_CONGRESSES',
    search: true,
    filter: [
      {
        name: 'SHARED.FROM',
        label: 'start_date',
        type: 'date',
        date: ''
      },
      {
        name: 'SHARED.TO',
        label: 'end_date',
        type: 'date',
        date: ''
      },
      {
        name: 'CONGRESS.STATUS',
        label: 'status',
        type: 'select',
        values: [
          {value: '0', label: 'CONGRESS.ENDED'},
          {value: '1', label: 'CONGRESS.ONGOING'},
          {value: '2', label: 'CONGRESS.SOON'},
        ],
        selected_label: '',
      },
    ]
  };

  constructor(private congressService: CongressService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.getAllUserCongresses();
  }

  getAllUserCongresses() {
    this.spinner.show();
    this.congresses = null;
    this.busy = this.congressService.getMyCongresses(this.keyword, this.offset, this.perPage, this.startDate, this.endDate, this.state)
      .subscribe(data => {
        this.congresses = data;
        this.spinner.hide();
      }, error => this.spinner.hide());
  }

  loadNextPost() {
    this.spinner.show();
    this.offset += this.perPage;
    this.congressService.getMyCongresses(this.keyword, this.offset, this.perPage, this.startDate, this.endDate, this.state)
      .subscribe(data => {
        if (data.length === 0) {
          this.notEmptyPost = false;
        }
        this.paginating(data);
        this.spinner.hide();
        this.notscrolly = true;
      }, error => this.spinner.hide());
  }

  onScroll() {
    if (this.notscrolly && this.notEmptyPost) {
      this.spinner.show();
      this.notscrolly = false;
      this.loadNextPost();
    }
  }

  paginating(data) {
    for (const congress of data) {
      this.congresses.push(congress);
    }
  }
  setKeyword($event) {
    this.keyword = $event;
    this.onSearch();
  }
  setFilter($event) {
    this.offset = 0;
    this.startDate = $event.filter[0].date;
    this.endDate = $event.filter[1].date;
    this.state = $event.filter[2].selected_label;
    this.getAllUserCongresses();
  }

  onSearch() {
    this.offset = 0;
    this.notEmptyPost = true;
    this.notscrolly = true;
    this.getAllUserCongresses();

  }


}
