export class Meeting {
    meeting_id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    user_meeting : UserMeeting;
    meeting_table_id: number;
}

export class UserMeeting {
    user_meeting_id: number;
    meeting_id: number;
    user_sender_id: number;
    user_received_id: number;
    user_canceler: number;
    status: number;
  }