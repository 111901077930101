import { User } from "./user";
import { File } from "./file";
import { Access } from "./Access";
import { Submission } from "./submission";
import { Pack } from "./Pack";
import { FormInput } from "./formInput";
import { ConfigLandignPage } from "./ConfigLandingPage";
import { Theme } from "./theme";
import { City } from "./City";

export class Congress {
  title: string;
  config: Config;
  config_submission: Config_Submission;
  config_landing: ConfigLandignPage;
  themes: string[];
  accesss: Access[];
  submission: Submission[];
  startDateSubmission: Date;
  startDateEvaluation: Date;
  startDate: Date;
  speciality: string;
  scientistComite: User[];
  responsible: User;
  organiser: User;
  id: string;
  endDateSubmission: Date;
  endDateEvaluation: Date;
  endDate: Date;
  picture: File;

  congress_id: number;
  name: string;
  start_date: Date;
  end_date: Date;
  price: number;
  description: string;
  congress_type_id: number;
  theme: Theme[];
  location: Location;
  packs: Pack[] = [];
  form_inputs: FormInput[] = [];
  path_abstract_book: string;
  name_en: string;
  description_en: string;
}

export class Config {
  config_congress_id: number;
  logo: string;
  banner: string;
  free: number = 0;
  is_online: number;
  has_payment: boolean = false;
  program_link: string;
  congress_id: number;
  access_system: string = "Workshop";
  status: number;
  currency_code: string;
  register_disabled: number;
  is_code_shown: number;
  is_sponsor_logo: number;
  nb_ob_access: number;
  nb_max_access: number;
  meeting_duration: number;
  pause_duration: number;
  lydia_token: string;
  lydia_api: string;
  agora_primary_background: string;
  agora_secondary_background: string;
  nb_meeting_table: number;
  location_link: string;
  show_free_networking: boolean;
  banner_en: string;
  banner_ar: string;
  logo_en: string;
  logo_ar: string;
  show_fix_networking: boolean;
  show_interactions: number;
  show_meesage_field: number;
  search_countries: number;
  is_phone_required: number;
  access_title: string;
  pack_title: string;
  nb_ob_packs: number;
  is_upload_user_img: number;
  mandatory_profile_img: number;
  hide_countries: number;
  promo_title_ar: string;
  promo_title_en: string;
  promo_title: string;
  promo_question_title: string;
  promo_question_title_en: string;
  promo_question_title_ar: string;
  thank_type_description: string;
  thank_type_description_ar: string;
  thank_type_description_en: string;
  title_thank_page: string;
  title_thank_page_en: string;
  title_thank_page_ar: string;
  main_form_title: string;
  main_form_description: string;
  is_closed_message: string;
  default_lang:string;
  max_access_selection:number;
  paid_access_validation: number;
}

export class Config_Submission {
  title_max_words:number;
  max_words: number;
  num_evaluators: number;
  conclusion_required:number;
  result_required:number;
  start_submission_date: Date;
  end_submission_date: Date;
  show_file_upload: number;
  explanatory_paragraph: string;
  abstract_type: number;
  nb_questions: number;
  nb_responses: number;
  is_quiz_mandatory: number;
  show_verification_step: number;
  default_lang:string;
  is_serie: number;
  configue_serie: string;
  configue_clinique: string;
  max_authors:number;
  institution: string;
  department: string;
  submission_type: string;
  general_info_desc:string;
  authors_desc:string;
  order_authors_desc:string;
  abstract_desc:string;
  final_desc:string;
  general_info_desc_en:string;
  authors_desc_en:string;
  order_authors_desc_en:string;
  abstract_desc_en:string;
  final_desc_en:string;
  paragraph_first_page: string;
  submission_format: number;
}

export class CongressRequest {
  congress_id: number;
  name: string;
  start_date: Date;
  end_date: Date;
  price: number;
  description: string;
  congress_type_id: number;
  config: Config;
  theme: Theme[];
  location: Location;
}

export class Location {
  location_id: number;
  lng: number;
  lat: number;
  address: string;
  congress_id: number;
  city: City;
}
