import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StandService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getStandByCongress(congressId,page:number,perPage:number,search:string, stag_id:string[]) {
    const url = environment.baseUrl + '/user/congress/' + congressId + `/stands?page=${page}&perPage=${perPage}&search=${search}&stag_id=${stag_id}`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  getStandById(congressId, standId) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/stand/getStand-ById/' + standId;
    return this.http.get(url, { headers: headers });
  }

  getTags(congressId) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/stags/stand-tags-list' ;
    return this.http.get(url, { headers: headers });
  }

  getProductsByStand(congressId: string, standId: number) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congressId + "/stand/" + standId + "/products";
    return this.http.get(url, {
      headers: headers
    })
  }
  
  getProducById(productId: number, congress_id) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/product/" + productId + "/get";
    return this.http.get(url, { headers: headers });
  }

  getGTags(congressId) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/' + congressId + '/gstags/stand-gtags-list' ;
    return this.http.get(url, { headers: headers });
  } 
}
