<div class="events_slider_item">
    <a class="events_item w-inline-block" [routerLink]="['/congress/details/'+ congress?.congress_id]">
        <div class="events_img_link">
            <img [src]="congress?.config?.banner ? filesUrl+congress?.config?.banner : imgUrl + 'sMcMajV6SBaUG1eCVvl6p2ETsMQCP0RW90hNw0TD.jpg'"
                loading="lazy" width="400" alt="Black Girls Tech Summit header" class="events_img">
        </div>
        <div class="event_info">
            <div class="event_title">{{ getValueTranslate('name') }}</div>
            <div class="event_date">{{congress?.start_date | date: 'dd/MM/yyyy'}}</div>
            <div class="event_data" *ngIf="congress.price==0"> {{'CONGRESS.FREE' | translate}} </div>
            <div class="event_data" *ngIf="congress.price>0">{{'HOME.PRICE'|translate}}: {{congress?.price}} DT</div>
        </div>
    </a>
</div>