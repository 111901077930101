<section class="section bg-light" *ngIf="configLandingPage?.organizers_title || configLandingPage?.organizers_title_en"
    id="organizers">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4"> {{getValueTranslate('organizers_title')}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{getValueTranslate('organizers_description')}}</p>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let organizer of organizers ">
                <div class="card team text-center rounded border-0">
                    <div class="card-body">
                        <div class="position-relative">
                            <img src="{{url}}{{organizer?.profile_img}}"
                                class="img-fluid avatar organizer-img avatar-ex-large rounded-circle shadow" alt="">
                            <!--end icon-->
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0"><a class="name text-dark">{{organizer?.full_name}}</a></h5>
                            <small class="designation text-muted">{{organizer?.role}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>