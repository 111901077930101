<div class="" >
  <div class="row"  style="justify-content: center;">
    <div class="col-lg-3 col-md-6 mt-4 pt-2 file-container"*ngFor="let f of files; let i = index">
    <div class="file-icon">
      <div [src]="types[i] == 'pdf' ? imgUrl + 'DgtwWkSNMnb1ve8k7GDCfu946Q7VkoNMHlRZxvoW.svg' : (types[i] === 'mp4' || types[i] === 'm4p' || types[i] === 'm4v') ? '../../../../../assets/ico/video_icon.svg' : '../../../../../assets/ico/img_icon.svg'"
           [setSVGAttributes]="{'width':'57.4px', 'height':'66.9px'}">
      </div>
    </div>
    <div class="file-details">
      <div class="file-name">
        <span>{{f.name}}</span>
      </div>
      <div class="file-actions" >
        <a download target="_blank" class="file-icons"
           href="{{ baseFiles}}{{f.path}}">
          <span [src]="imgUrl + 'ZF09NVes4xoua8nQ3V7nF420ZSEcMGpL0OZsHx7s.svg'"
                [setSVGAttributes]="{'width':'14px', 'height':'14px'}">
        </span>
        </a>
      </div>
    </div>
  </div>
  </div>
</div>
