import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-sponsor-pack',
  templateUrl: './sponsor-pack.component.html',
  styleUrls: ['./sponsor-pack.component.css']
})
export class SponsorPackComponent implements OnInit {
  @Input() sponsorPacks;
  @Input() configLandingPage: ConfigLandignPage;
  @Input() lang: string;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  getValueTranslateSponsorPack (columnName: string, sponsorPack) {
    return Utils.getValueTranslate(this.lang, sponsorPack, columnName);
  }

  transformHtml(htmlTextWithStyle): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

}
