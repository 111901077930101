import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) {}

  uploadFile(file: File) {
    const data: FormData = new FormData();
    data.append('files', file, file.name);
    return this.http.post<any>(environment.baseUrl + '/files/upload-resource', data);
  }


}
