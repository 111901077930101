<div *ngIf="ready" style="font-family: Nunito, sans-serif;">
  <h1>{{seo?.first_title}}</h1>
  <app-header [configLandingPage]="configLandingPage" [navClass]="navClass" [eventLocation]="eventLocation" (toRegister)="toRegister()" [lang]="lang" (langChange)="langChange($event)"></app-header>
  <app-home [configLandingPage]="configLandingPage" (toRegister)="toRegister()" (toAddSubmission)="toAddSubmission()"></app-home>
  <app-live [configLandingPage]="configLandingPage" [lang]="lang"></app-live>
  <app-about [configLandingPage]="configLandingPage" [lang]="lang" (toRegister)="toRegister()"></app-about>
  <app-organizers *ngIf="organizersReady" [configLandingPage]="configLandingPage" [organizers]="organizers" [lang]="lang"></app-organizers>
  <app-speakers *ngIf="speakersReady" [configLandingPage]="configLandingPage" [speakers]="speakers" [lang]="lang"></app-speakers>
  <app-specific-banner *ngIf="configLandingPage.specific_bnr" [configLandingPage]="configLandingPage" [lang]="lang"></app-specific-banner>
  <app-specific-banner *ngIf="configLandingPage.specific_bnr_two" [bannerTwo]="true" [configLandingPage]="configLandingPage" [lang]="lang"></app-specific-banner>
  <app-sponsors *ngIf="partnersReady" [isPartners]="true" [configLandingPage]="configLandingPage" [sponsors]="partners" [lang]="lang"></app-sponsors>
  <app-sponsors *ngIf="sponsorsReady" [configLandingPage]="configLandingPage" [sponsors]="sponsors" [lang]="lang"></app-sponsors>
  <app-sponsor-pack *ngIf="sponsorPacksReady" [configLandingPage]="configLandingPage" [sponsorPacks]="sponsorPacks" [lang]="lang"></app-sponsor-pack>
  <app-program *ngIf="programReady" [configLandingPage]="configLandingPage" [accesses]="accesses" [lang]="lang" (toRegister)="toRegister()" [congress]="congress"></app-program>
  <app-companies *ngIf="companiesReady" [configLandingPage]="configLandingPage" [companies]="companies" [lang]="lang"></app-companies>
  <app-contact-us [configLandingPage]="configLandingPage" [eventLocation]="eventLocation" [lang]="lang" (toRegister)="toRegister()"></app-contact-us>
  <app-footer-landing-page [configLandingPage]="configLandingPage"></app-footer-landing-page>
</div>
