<div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'HOME.Question' | translate}} {{questionNb + 1}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-between">
                    <h3 class="polling-header-styles_headline__2r1Bg">
                        {{questions[questionNb]?.title}}
                    </h3>
                    <h4>{{timeLeft}}</h4>
                </div>
                <div *ngFor="let response of questions[questionNb]?.responses; let i = index">
                    <div class="modal-btn-radois d-flex">
                        <div *ngIf="questions[questionNb]?.isMultiple == 0">
                            <input type="radio" [value]="response.responseId" [(ngModel)]="userResponses"
                                class="regular-radio" [disabled]="timeLeft == 0">
                        </div>
                        <div *ngIf="questions[questionNb]?.isMultiple == 1">
                            <input type="checkbox" [value]="response.responseId" (change)="addResponse($event)"
                                class="regular-checkbox" [disabled]="timeLeft == 0">
                        </div>
                        <label class="btn-radio-label label-btns">
                            {{response.label}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{'HOME.CLOSE' | translate}}
                </button>
                <button type="button" class="btn btn-primary" (click)="saveResponse()">
                    {{'HOME.Save' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="rightResponseModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'HOME.RightResponse' | translate}}</h5>
                <button type="button" class="close" (click)="seeResponse()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h3 class="polling-header-styles_headline__2r1Bg">
                    {{questions[questionNb]?.title}}
                </h3>
                <div *ngFor="let response of questions[questionNb]?.responses; let i = index">
                    <div class="modal-btn-radois" *ngIf="response.isCorrect == 1">
                        <label class="btn-radio-label">
                            {{response.label}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="seeResponse()">{{'HOME.CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>