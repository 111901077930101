<div id="language-name">
    <a style="margin-right: 15px;" (click)="onMenuClick($event)">{{selectedLang| translate}}
        <img src="{{flags[selectedLang]}}" alt="selected-lang-flag" class="flag-icon selected-flag-icon">
    </a>
    <span class="menu-arrow"></span>
</div>
<ul class="submenu megamenu" id="languages-menu">
    <li>
        <ul>
            <li style="height: 36.5px;"><a class="nav-link-ref" (click)="changeLang('fr')">
                    <img src="{{flags.fr}}" alt="fr-lang-flag" class="flag-icon">
                    {{'fr'| translate}}
                </a>
            </li>
            <li style="height: 36.5px;"><a class="nav-link-ref" (click)="changeLang('en')">
                    <img src="{{flags.en}}" alt="fr-lang-flag" class="flag-icon">
                    {{'en'| translate}}
                </a>
            </li>
            <li style="height: 36.5px;"><a class="nav-link-ref" (click)="changeLang('ar')">
                <img src="{{flags.ar}}" alt="fr-lang-flag" class="flag-icon">
                {{'ar'| translate}}
            </a>
        </li>
        </ul>
    </li>
</ul>