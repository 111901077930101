<section class="section bg-light"
    *ngIf="configLandingPage?.companies_title || configLandingPage?.companies_title_en || configLandingPage?.companies_title_ar"
    id="companies">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4"> {{getValueTranslate('companies_title')}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{getValueTranslate('companies_description')}}</p>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let company of companies ">
                <div class="card team text-center rounded border-0">
                    <div class="card-body">
                        <div class="position-relative">
                            <img src="{{url}}{{company?.logo}}"
                                class="img-fluid organizer-img avatar avatar-ex-large rounded-circle shadow" alt="">
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0">
                                <a class="name text-dark">
                                    {{company?.name}}
                                </a>
                            </h5>
                            <small *ngIf="company?.description" class="designation text-muted">{{company?.description}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>