<div class="inner-box ">
  <div class="list-box">
    <div class="scroll-submission">
      
      <div class="form-group">
        <div class="sub">
          <input class="search-bar form-control ps-5" type="text" (change)="onSearch($event.target.value)" placeholder="{{'HOME.SEARCH' | translate}}">
  
        </div>
        <div class="dropdown btn-group btn-group-etat" style="margin-left:25px">
          <div class="pull-left ">
            <div class="input-group">
              <input type="text" [(ngModel)]="statusToShow" class=" form-control ps-5" placeholder="{{'CONGRESS.STATUS' | translate}}"
                     aria-label="Text input with dropdown button">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"></button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="selectStatus(status)" *ngFor="let status of statusValue">
                    {{status.key| translate}}
                  </a>

                </div>
              </div>
            </div>
          </div>
          <div *ngIf="submission.status==4">
            <a [routerLink]="['/user-profile/submission/submit-resources/'+ submission.submission_id ] "  [queryParams]="{code: submission.upload_file_code}" >

            <button style="background-color:#4563ac; color:white " class="btn btn-light " >{{'SUBMISSION.SUBMIT_FILES' | translate}}</button>
            </a>
          </div>
        </div>
        
        <div class="send-submission">
          <button class="button-send-submission orange"
            [routerLink]="[
              '/room/' + congressId + '/submission/add'
            ]">
            <div class="text-send-submission">
              {{ "CONGRESS.SEND_SUBMISSION" | translate }}
            </div>
          </button>
        </div>
        <div *ngIf="isSubmissions">
        <app-item-submission [allSubmissions]="submissions$" [totalItems]="totalItems" (pageChangement)="getpages($event)">
        </app-item-submission>
        </div>
      </div>
    </div>

  </div>
</div>
