import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


declare var swal: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
  }


}
