import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListSubmissionsComponent} from './list-submissions/list-submissions.component';
import {SubmissionRoutingModule} from './submission.routing';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {GhostListSubmissionComponent} from './list-submissions/ghost-list-submission/ghost-list-submission.component';
import {TranslateModule} from '@ngx-translate/core';
import { SubmissionFormComponent } from './submission-form/submission-form.component';
import { AuthorListComponent } from './submission-form/author-list/author-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    imports: [
        CommonModule,
        SubmissionRoutingModule,
        FormsModule,
        SharedModule,
        RouterModule,
        NgxSpinnerModule,
        TranslateModule,
        InfiniteScrollModule
    ],
  declarations: [
    ListSubmissionsComponent,
    GhostListSubmissionComponent,
    SubmissionFormComponent,
    AuthorListComponent
  ],
  exports: [
    SubmissionFormComponent,
    AuthorListComponent,
    ListSubmissionsComponent
  ]
})
export class SubmissionModule {
}
