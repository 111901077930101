<body>
    <main
        [ngStyle]="{'background-image': congress?.config?.backg_form ?  'url(' +filesUrl + congress?.config?.backg_form+ ')':'url('+ backgroundImageUrl+')' }">
        <div class="inner">
            <header>
                <div class="logo">
                    <div class="logo-icon">
                        <img *ngIf="congress?.config?.logo" class="event-logo"
                            [src]="filesUrl + getValueTranslate('logo')" alt="BeRifma">
                    </div>
                    <div class="logo-text">
                        {{congress?.name}}
                          <br>
              <div class="logo" style="float: left;">
                {{congress?.start_date | date: 'dd/MM/YYYY'}} - {{congress?.end_date | date: 'dd/MM/YYYY'}}
              </div>
                    </div>
                </div>
                <div class="bar-end">
                </div>
            </header>

            <div class="container" *ngIf="congress?.config?.status == 1 ">
                <div class="wrapper steps">
                    <article>
                        <div class="main-heading">
                            {{ congress?.config?.access_title ? getValueTranslate(congress?.config, 'access_title') :
                            ('Ateliers' |
                            translate)}}
                        </div>
                    </article>
                    <!-- form -->
                    <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
                        <div class="lightSpeedIn form " id="step2" style="padding-left: 0%;">
                            <div class="check-field row d-flex" style="margin-bottom: 0%;"
                                *ngFor="let access of accesses; let i =index">
                                <span class="insurance-type mb-3" style="z-index: 10; position: relative;">
                                    {{displayDate(access[0])}} {{access[0].substr(11, 5)}}
                                </span>
                                <div class="tab-33 col-md-6 mb-3" *ngFor="let accessObject of access[1]">
                                    <div class="check-field-single" (click)="addAccessToUser(accessObject, i)">
                                        <div class="d-flex">
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative; font-weight: bold;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{'Label' | translate}} :</span>
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{accessObject?.name}}</span>
                                            <input type="radio" name="accessObject{{i}}"
                                                value="{{accessObject?.access_id}}">
                                        </div>
                                        <div class="d-flex">
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative; font-weight: bold;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{'AccessFees' | translate}} :
                                            </span>
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{ (accessObject?.price > 0 ? accessObject?.price : 'FreeInscription') |
                                                translate}}
                                            </span>
                                        </div>
                                        <div class="d-flex">
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative; font-weight: bold;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{'AccessStatut' | translate}} :</span>
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{ !accessObject.max_places || accessObject.max_places >
                                                accessObject.nb_participants ? ('Available'| translate) : ('Complet' |
                                                translate)}} </span>
                                        </div>
                                        <div class="d-flex">
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative; font-weight: bold;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{'AccessTiming' | translate}} :</span>
                                            <span class="insurance-type mx-2"
                                                style="z-index: 10; position: relative;font-size: .8125rem;"
                                                [ngStyle]="{'color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'white' : '' }">
                                                {{getAccesseTime(accessObject.start_date)}} {{'-' + ' ' +
                                                getAccesseTime(accessObject.end_date)}}
                                            </span>
                                        </div>
                                        <input type="radio" name="accessObject{{i}}"
                                            id="accessObject{{accessObject?.access_id}}"
                                            value="{{accessObject?.access_id}}" #radio{{i}}
                                            [ngStyle]="{'background-color': (accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )) ? 'rgba(225, 0, 0, 0.9)' : '' }"
                                            [disabled]="accessObject.max_places && ( accessObject.max_places <= accessObject.participant_count ||  accessObject.max_places <= accessObject.nb_participants )">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                              <button type="button" id="step2btn" class="btn btn-primary next circle-button" (click)="addUserAccess()">
                                {{ 'Validate' | translate }}
                                <i class="fa-solid fa-arrow-right"></i>
                              </button>
                            </div>                      
                    </form>
                </div>
                <div class="container" *ngIf="form?.status == 0">
                    <div class="wrapper" style="width: 74%!important;">
                        <div class="show-section">
                            <section class="steps">
                                <article>
                                    <div class="main-heading" style="text-align: center!important;">
                                        <div *ngIf="form?.is_closed_form_message === null" class="title mb-4">
                                            {{'FormClosed' | translate}}
                                        </div>
                                        <div *ngIf="form?.is_closed_form_message !== null" class="title mb-4">
                                            <div class="message" [innerHTML]="form?.is_closed_form_message"
                                                [style.color]="getColorFromStyle(form?.is_closed_form_message)"></div>
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="congress?.config?.status == 0">
                <div class="wrapper" style="width: 74%!important;">
                    <div class="show-section">
                        <section class="steps">
                            <article>
                                <div class="main-heading" style="text-align: center!important;">
                                    <div *ngIf="congress?.config?.is_closed_message === null" class="title mb-4">
                                        {{'RegisterClosed' | translate}}
                                    </div>
                                    <div *ngIf="congress?.config?.is_closed_message !== null" class="title mb-4">
                                        <div class="message" [innerHTML]="congress?.config?.is_closed_message" #message
                                            [style.color]="getColorFromStyle(congress?.config?.is_closed_message)">
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </div>
            </div>
            <footer>
                <div class="bar-end ml-auto">
                    <h3 style="color:black;">
                        <i class="fa-solid fa-square-phone-flip">
                        </i> {{'NeedHelp' | translate}} ?
                        <span> {{'CallExpert' | translate}}{{congress?.config?.mobile_technical}}</span>
                    </h3>
                </div>
            </footer>
        </div>
    </main>
</body>