import { ChatService } from './../../shared/services/chat.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/shared/utils';
import { first } from 'rxjs/operators';  

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponent implements OnInit {
  @Input() configLandingPage: ConfigLandignPage;
  @Input() navClass: string;
  @Input() eventLocation;
  @Output() toRegister: EventEmitter<null> = new EventEmitter();
  filesUrl = environment.filesUrl;
  @Input() lang: string;
  isCondensed: boolean = false;
  @Output() langChange: EventEmitter<string> = new EventEmitter();
  chatId: string;
  nbVisitors: number = 0;
  nbVisitorsKey: string;
  justLoggedIn: boolean = true;
  
  constructor(private chatService: ChatService) {

  }

  toElement(elementId) {
    document.getElementById(elementId).scrollIntoView({ behavior: "smooth" });
  }

  ngOnInit(): void {
    this.chatId = Utils.getRoomName(this.configLandingPage.congress_id.toString());
    if (this.justLoggedIn)
    this.getChatGeneralData();
  }

  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      if (this.configLandingPage.is_background_white != 1) {
        document.getElementById('topnav').classList.remove('nav-sticky');
      }
    }
    if (document.getElementById('back-to-top')) {
      if (document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100) {
        document.getElementById('back-to-top').style.display = 'inline';
      } else {
        document.getElementById('back-to-top').style.display = 'none';
      }
    }
  }

  redirectToRegister() {
  this.toRegister.emit();
  }

  onMenuClick(event) {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains("open")) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("open");
      }
      nextEl.classList.add("open");
    } else if (nextEl) {
      nextEl.classList.remove("open");
    }
    return false;
  }

  changeLang(lang) {
    this.lang = lang;
    this.langChange.emit(this.lang);
  }

  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById("navigation").style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
    }
  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  menuCondesned(event: boolean) {
    this.isCondensed = event;
  }

  getChatGeneralData() {
    if (this.justLoggedIn) {
      this.chatService.getLandingPageData(this.chatId).pipe(first()).subscribe((actions: any[]) => {
        this.justLoggedIn = false;
        if (actions.length == 0) {
          this.addLandingPageData();
        } else {
          this.nbVisitors = actions[0].payload.val().nbVisitors;
          this.nbVisitorsKey = actions[0].key;
          this.updateUsersConnected();
        }
      });
    }
    
  }

  addLandingPageData() {
    this.chatService.addLandingPageData(this.chatId, { nbVisitors: 1 });
  }

  updateUsersConnected() {
    this.nbVisitors += 1;
    this.chatService.updateLandingPageData(this.chatId, this.nbVisitorsKey, { nbVisitors: this.nbVisitors });
  }


  
}
