
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgBusyModule } from 'ng-busy';
import { ListButtonComponent } from './list-button/list-button.component';
import { DetailSubmissionComponent } from '../submission/detail-submission/detail-submission.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DateRemainingPipe } from './pipes/date-remaining.pipe';
import { NgGenericComponent } from './shared-components/ng-generic/ng-generic.component';
import { NgSelect2Component } from './shared-components/ng-select2/ng-select2.component';
import { NgCheckboxComponent } from './shared-components/ng-checkbox/ng-checkbox.component';
import { NgDatepickerComponent } from './shared-components/ng-datepicker/ng-datepicker.component';
import { NgRadioboxComponent } from './shared-components/ng-radiobox/ng-radiobox.component';
import { NgFileComponent } from './shared-components/ng-file/ng-file.component';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import localeFr from '@angular/common/locales/fr';
import { FilterComponent } from './shared-components/filter/filter.component';
import { ItemSubmissionComponent } from './item-submission/item-submission.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafePipe } from './pipes/safe.pipe';
import { RegisterFormComponent } from './shared-components/register-form/register-form.component';
import { UploadFileComponent } from './shared-components/upload-file/upload-file.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TrackingComponent } from './shared-components/tracking/tracking.component';
import { NgFilesComponent } from './shared-components/ng-files/ng-files.component';
import { ItemEventComponent } from './shared-components/item-event/item-event.component';
import { FilterDateComponent } from './shared-components/filter-date/filter-date.component';
import { FilterPriceComponent } from './shared-components/filter-price/filter-price.component';
import { ScheduleMeetingComponent } from './shared-components/schedule-meeting/schedule-meeting.component';
import { HeaderLanguagesComponent } from './shared-components/header-languages/header-languages.component';
import { ItemUserComponent } from './shared-components/item-user/item-user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PriceDisplayComponent } from './shared-components/price-display/price-display.component';

registerLocaleData(localeFr);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    RouterModule,
    TranslateModule,
    NgxPaginationModule,
    PdfViewerModule,
    NgSelectModule
  ],
  declarations: [ListButtonComponent,
    DetailSubmissionComponent,
    DateRemainingPipe,
    NgGenericComponent,
    NgSelect2Component,
    NgCheckboxComponent,
    NgDatepickerComponent,
    NgRadioboxComponent,
    NgFileComponent,
    ListButtonComponent,
    FilterComponent,
    DateSuffixPipe,
    ItemSubmissionComponent,
    SafePipe,
    RegisterFormComponent,
    UploadFileComponent,
    TrackingComponent,
    NgFilesComponent,
    ItemEventComponent,
    FilterDateComponent,
    FilterPriceComponent,
    ScheduleMeetingComponent,
    HeaderLanguagesComponent,
    ItemUserComponent,
    PriceDisplayComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    DetailSubmissionComponent,
    DateRemainingPipe,
    DateSuffixPipe,
    FilterComponent,
    NgGenericComponent,
    NgSelect2Component,
    ItemSubmissionComponent,
    SafePipe,
    RegisterFormComponent,
    UploadFileComponent,
    NgSelect2Component,
    TrackingComponent,
    NgFilesComponent,
    ItemEventComponent,
    FilterDateComponent,
    FilterPriceComponent,
    ScheduleMeetingComponent,
    HeaderLanguagesComponent,
    ItemUserComponent,
    NgxPaginationModule,
    NgSelectModule,
    PriceDisplayComponent
  ]
})
export class SharedModule {
}
