import { Component, OnInit } from '@angular/core';
import { CongressService } from 'src/app/shared/services/congress.service';
import { Congress } from 'src/app/shared/models/congress';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user.service';
import { Contact } from 'src/app/shared/models/contact';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import {Utils} from '../../shared/utils';
import { environment } from 'src/environments/environment';

declare var swal;
@Component({
  selector: 'app-user-contacts',
  templateUrl: './user-contacts.component.html',
  styleUrls: ['./user-contacts.component.css']
})
export class UserContactsComponent implements OnInit {
  congresses: Congress[] = [];
  contacts: Contact[] = [];
  congress = new Congress();
  busy: Subscription;
  search = '';
  perPage = 10;
  offset = 0;
  notEmptyPost = true;
  notscrolly = true;
  congresId: number = null;
  imgUrl = environment.imgUrl; 
   
  constructor(private congressService: CongressService, private spinner: NgxSpinnerService ,private translate: TranslateService,
    private userService: UserService ) { }

  ngOnInit(): void {
    this.getAllCongress();
    this.getContacts();
  }
  getContacts() {
    this.busy = this.userService.getAllContacts(
      this.offset,
      this.perPage,
      this.search,
      this.congresId
    ).subscribe((data: Contact[]) => {
      this.contacts = data;
    })
  }

  deleteContact(user_viewed_id: number, congress_id: number , index: number) {
    const baseContext = this;
      const params = {
        buttons: true,
        dangerMode: true
      }

      Utils.swalMessageWithCallback('SWAL.Sure', 'SWAL.OnceDeleted', 'warning', params, this.translate, function(isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.userService.deleteContact(user_viewed_id, congress_id).subscribe(data => {
            baseContext.contacts.splice(index, 1);
            Utils.swalMessage('SWAL.DELETED', 'SWAL.USERDETELED', 'success',  baseContext.translate);
          }, error => {
            Utils.swalMessage('SWAL.NONDELETED', 'SWAL.USERNONDETELED', 'warning',  baseContext.translate);

          });
        }
      }
    );
  }
  onScroll(){
    if (this.notscrolly && this.notEmptyPost) {
      this.spinner.show();
      this.notscrolly = false;
      this.loadNextPost();
  }
}
loadNextPost() {
  this.spinner.show();
  this.offset += this.perPage;
  this.userService.getAllContacts(
    this.offset, this.perPage, this.search, this.congresId
    ).subscribe(data => {
      if (data.length === 0) {
        this.notEmptyPost = false;
      }
    this.paginating(data);
    this.spinner.hide();
    this.notscrolly = true;
  }, error => this.spinner.hide());
}
paginating(data: Contact[]) {
  for (const contact of data) {
    this.contacts.push(contact);
  }
}
  selectCongress(congress_id: number) {
    this.congresId = congress_id;
    this.search = '';
  }

  getAllCongress() {
  this.congressService.getMinCongressData().subscribe((data: Congress[]) => {
    this.congresses = data ;
            this.congress.congress_id = null ;
      this.translate.get('CONGRESS.All').subscribe((res) => {
        this.congress.name = res;
      });
      this.congresses.splice(0 , 0, this.congress);
  });
  }
  filter() {
    this.offset = 0 ;
    this.notEmptyPost = true;
    this.notscrolly = true;
     if (this.search.trim().length > 0) {
     this.congresId = null ;
    }

    this.getContacts();
  }
}
