import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Pipe({
  name: 'dateRemaining'
})
export class DateRemainingPipe implements PipeTransform {
  remainingDate: RemainingDate;
  constructor(private translate: TranslateService) {
    this.remainingDate = new RemainingDate();
  }
  transform(value: any, arg: any): any {
    if (value) {
      const seconds = Math.floor(( +new Date(value) - +new Date() ) / 1000);
      const intervals = {
        'HOME.YEAR': 31536000,
        'HOME.WEEK': 604800,
        'HOME.DAY': 86400,
        'HOME.HOUR': 3600,
        'HOME.MINUTE': 60,
      };
      if (arg) {
        const secondsSince = Math.floor(( +new Date(arg) - +new Date() ) / 1000);
        if (secondsSince < 0) {
          this.remainingDate.interval = 'HOME.CLOSED';
          return this.remainingDate ;
        } else if (secondsSince > 0 && seconds < 0) {
          this.remainingDate.interval = 'HOME.ONGOING';
          return this.remainingDate ;
        } else {
          let counter;
          for (const i in intervals) {
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0) {
              this.remainingDate.interval = i;
              this.remainingDate.counter = counter;
              return this.remainingDate ; }
          }
        }}
      }

    return value;
  }

}
export class RemainingDate {
  counter = 0;
  interval?: string;
}
