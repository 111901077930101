import {NgModule} from '@angular/core';
import {RouterModule, Routes, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {OnlineRoomComponent} from './online-room/online-room.component';
import {CanActivateViaAuthGuard} from '../shared/services/guards/auth-guard.service';

export const routes: Routes = [

  
  {
    path: 'room/:congress_id/access/:access_id',
    component: OnlineRoomComponent,
    canActivate: [CanActivateViaAuthGuard]
  },
  {
    path: 'room/:congress_id',
    component: OnlineRoomComponent,
    canActivate: [CanActivateViaAuthGuard]
  },
  {
    path: 'room/:congress_id/meetings/:meeting_id',
    component: OnlineRoomComponent,
    canActivate: [CanActivateViaAuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CongressRoutingModule {
}
