export class Form {
    form_id: number;
    name: string;
    url: number;
    privilege_id: number;
    show_gender:number;
    created_at: string;
    show_accesses: boolean = false;
    show_packs: boolean = false;
    is_promo: boolean = false;
    status: boolean = false;
    is_closed_form_message: boolean = false;
    currency_id: string;
    show_hotels: boolean = false;
}