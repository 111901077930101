import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LandingPageEventRoutingModule } from './landing-page-event-routing.module';
import { LandingPageEventComponent } from './landing-page-event/landing-page-event.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SpeakersComponent } from './speakers/speakers.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { ProgramComponent } from './program/program.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DatePipe } from '@angular/common';
import { FooterLandingPageComponent } from './footer-landing-page/footer-landing-page.component';
import { AuthLandingPageComponent } from './auth-landing-page/auth-landing-page.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import localeEsAr from '@angular/common/locales/ar';
import { WaitingComponent } from './waiting/waiting.component';
import { OrganizersComponent } from './organizers/organizers.component';
import { LiveComponent } from './live/live.component';
import { SpecificBannerComponent } from './specific-banner/specific-banner.component';
import { CompaniesComponent } from './companies/companies.component';
import { SponsorPackComponent } from './sponsor-pack/sponsor-pack.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { RegisterHotelsComponent } from './register-hotels/register-hotels.component';
import { RegisterAccessComponent } from './register-access/register-access.component';
import { ThankYouPageAccessComponent } from './thank-you-page-access/tank-you-page-access.component';
import { RegisterExternalQuestionsComponent } from './register-external-questions/register-external-questions.component';
import { ValidationDevisComponent } from './validation-devis/validation-devis.component';


registerLocaleData(localeEsAr, 'ar');


@NgModule({
  declarations: [

    LandingPageEventComponent,

    HeaderComponent,

    HomeComponent,

    AboutComponent,

    SpeakersComponent,

    OrganizersComponent,

    SponsorsComponent,

    ProgramComponent,

    ContactUsComponent,

    FooterLandingPageComponent,

    AuthLandingPageComponent,

    ResetPasswordComponent,

    ForgetPasswordComponent,
      WaitingComponent,
      LiveComponent,
      SpecificBannerComponent,
      CompaniesComponent,
      SponsorPackComponent,
      ThankYouPageComponent,
      RegisterAccessComponent,
      ThankYouPageAccessComponent,
      RegisterExternalQuestionsComponent,
      ValidationDevisComponent,
      ],
  imports: [
    CommonModule,
    LandingPageEventRoutingModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ProgramComponent],
  providers: [DatePipe]
})
export class LandingPageEventModule { }
