<section class="section bg-light" *ngIf="configLandingPage?.speaker_title || configLandingPage?.speaker_title_en" id="speakers">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4"> {{getValueTranslate('speaker_title')}}</h4>
          <p class="text-muted para-desc mx-auto mb-0">{{getValueTranslate('speaker_description')}}</p>
        </div>
      </div>
        </div>
    <div class="row" style="justify-content: center;">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let speaker of speakers ">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img src="{{url}}{{speaker?.profile_img}}" class="img-fluid organizer-img avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item"><a target="_blank" *ngIf="speaker?.fb_link!== null"
                    href="{{speaker?.fb_link}}" class="rounded">
                    <i class="fab fa-facebook-f"></i>
                  </a></li>
                <li class="list-inline-item ml-1"><a target="_blank" *ngIf="speaker?.instagram_link!== null"
                    href="{{speaker?.instagram_link}}" class="rounded">
                    <i class="fab fa-instagram"></i>
                  </a></li>
                <li class="list-inline-item ml-1"><a target="_blank" *ngIf="speaker?.twitter_link!== null"
                    href="{{speaker?.twitter_link}}" class="rounded">
                    <i class="fab fa-twitter-square"></i>
                  </a></li>
                <li class="list-inline-item ml-1"><a target="_blank" *ngIf="speaker?.linkedin_link!== null"
                    href="{{speaker?.linkedin_link}}" class="rounded">
                    <i class="fab fa-linkedin"></i>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a class="name text-dark">{{getValueTranslateSpeaker('first_name', speaker)}} {{getValueTranslateSpeaker('last_name', speaker)}}</a></h5>
              <div class="ql-snow ql-disabled">
                <div class="ql-editor">
                  <div [innerHTML]="transformHtml(getValueTranslateSpeaker('role', speaker))">
                  </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->


      <!--end col-->


      <!--end col-->


      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</section>
