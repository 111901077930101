<div ngBusy="busy" class="eventizer-main-content">
  <form>

    <fieldset>

      <div class="profile-container">
        <label for="fileInputField">
          <img *ngIf="user.resource_id" class="profile-pic"
               src="{{filesUrl + user?.profile_img?.path}}"
          >
          <div class="edit-picture">
            <app-upload-file #uploadCroppie [uploadType]="'normal'" [profilePic]="true" (onPhotoUploaded)="uploadedPhoto($event)"></app-upload-file>
            <i class="fa fa-pencil pencil-color edit-icon" aria-hidden="true"></i>
          </div>
        </label>
      </div>

      <br>

      <!-- Nom-->
      <div class="form-group column1 required">
        <label class="formulaire-label control-label">{{'HOME.SURNAME'|translate}} </label>

        <input (ngModel)="user.last_name"
               ngClass="{'is-invalid': isSubmitted && !user.last_name}"
               type="text" placeholder="{{'HOME.SURNAME'|translate}}"
               class="form-control" name="last_name">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
        <div class="invalid-feedback">
          {{'ChampObligatoire'|translate}}
        </div>
      </div>
      <!-- Prenom-->
      <div class="form-group required column2">
        <label class="formulaire-label control-label">{{'HOME.NAME'|translate}}</label>

        <input (ngModel)="user.first_name"
               ngClass="{'is-invalid': isSubmitted && !user.first_name}"
               type="text" placeholder="{{'HOME.NAME'|translate}}"
               class="form-control" name="first_name">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
        <div class="invalid-feedback">
          {{'ChampObligatoire'|translate}}
        </div>
      </div>


      <div class="form-group  required">
        <label class="formulaire-label  control-label">{{'HOME.EMAIL'|translate}}</label>

        <input (ngModel)="user.email"
               ngClass="{'is-invalid': isSubmitted && email.invalid && (email.dirty || email.touched)}"
               ngClass="{'email-green-box': !isSubmitted && !email.invalid &&!email.dirty && !email.touched}"
               #email="ngModel"
               pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
               type="email" class="form-control" id="inputEmail3"
               placeholder="Email" name="email">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
        <div class="invalid-feedback">
          {{'HOME.EMAIL_FORMAT_INVALID'|translate}}
        </div>
      </div>

      <!-- tel-->
      <div class="form-group column1 required">
        <label class="formulaire-label control-label">{{'HOME.PHONE'|translate}}</label>

        <input (ngModel)="user.mobile"
               ngClass="{'is-invalid': isSubmitted && !user.mobile}"
               type="text" placeholder="e.g. (+216) 55 555 555"
               class="form-control" name="mobile">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
        <div class="invalid-feedback">
          {{'ChampObligatoire'|translate}}
        </div>
      </div>

      <!-- pays-->
      <div class="form-group required column2 pays">
        <label class="formulaire-label control-label">{{'HOME.COUNTRY'|translate}}</label>
        <div class="form-control formulaire-box ">
          <select (ngModel)="user.country_id" name="country" placeholder="e.g. TUN"
                  ngClass="{'is-invalid': isSubmitted && !user.country_id}"
                  class="formulaire-box  wrap-pencil input-md"
                  required="" type="text">
            <option selected="selected" disabled>{{user?.country_id}}</option>
            <option *ngFor="let country of countries"
                    value="{{country?.alpha3code}}">{{country?.name}}
            </option>
          </select>
        </div>
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
      </div>

      <div class="form-group required">
        <label for="inputPassword3" class=" formulaire-label control-label">{{'HOME.PASSWORD'|translate}} </label>
        <input id="inputPassword3" (ngModel)="user.password"
               type="password" placeholder="************"
               class="form-control" name="password">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
      </div>

      <div class="form-group required">
        <label for="inputPassword4" class="formulaire-label control-label">{{'HOME.CONFIRM_PASSWORD'|translate}}</label>

        <input (ngModel)="passwordConfirm"
               ngClass="{'is-invalid': isSubmitted && user.password!=passwordConfirm}"
               name="password" type="password" class="form-control" id="inputPassword4"
               placeholder="************">
        <i class="fa fa-pencil pencil-color" aria-hidden="true"></i>
        <i *ngIf="user.password===passwordConfirm" class="fa fa-check green-check" aria-hidden="true"></i>
        <div class="invalid-feedback">
          {{'HOME.PASSWORDS_ARENT_THE_SAME'|translate}}
        </div>
      </div>

      <label for="inputPassword4" class="formulaire-label control-label">{{'HOME.GENDER'|translate}}</label>
      <br>
      <div class="gender-wrapper first-column">
        <input id="toggle-on-{{user?.gender}}" class="toggle toggle-left " name="gender"
               value="1" type="radio" (ngModel)="user.gender" [checked]="user.gender === 1">
        <label for="toggle-on-{{user?.gender}}" class="btn" [class.checked]="user.gender === 1 "><i
          class="fas fa-mars mars"></i></label>
        <input id="toggle-off-{{user?.gender}}" class="toggle toggle-middle " name="gender"
               value="2" type="radio" (ngModel)="user.gender" [checked]="user.gender === 2">
        <label for="toggle-off-{{user?.gender}}" class="btn" [class.checked]="user.gender === 2 "><i
          class="fas fa-venus"></i></label>
      </div>

      <br>
      <div class="form-group column2" style="margin-top:40px;">
        <a class="btn btn-primary confirm-button" (click)="updateProfile()">{{'HOME.UPDATE' | translate}}</a>
      </div>
    </fieldset>
  </form>
</div>
