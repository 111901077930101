import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-list-button',
  templateUrl: './list-button.component.html',
  styleUrls: ['./list-button.component.css']
})
export class ListButtonComponent implements OnInit {

  @Input() placeholder;
  itemToAdd: any;
  @Input() items: string[] = [];
  @Output() itemsChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    if (!this.items) {
      this.items = [];
    }
  }

  addItem() {
    if (this.itemToAdd.trim() !== '') {
      this.items.push(this.itemToAdd);
      this.itemsChange.emit(this.items);
    }
    this.itemToAdd = String('');
  }

  removeItem(i: number) {
    this.items.splice(i, 1);
    this.itemsChange.emit(this.items);
  }
}
