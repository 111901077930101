import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../shared/models/user';
import {Notification} from '../shared/models/notification';

import {UserService} from '../shared/services/user.service';
import {StorageService} from '../shared/services/storage.service';
import {AuthService} from '../shared/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Utils } from '../shared/utils';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit {
  user: User;
  notfication: Notification[];
  numberNotification: number;
  imgUrl = environment.imgUrl;
  selectedLang = 'fr';

  flags = {
    'fr': environment.imgUrl +"dSoN6se9r6JyDOotJjdN218dYH7ZC0q3mRaHfMZE.svg",
    'en': environment.imgUrl +'vYz99hGCBMQJcR2NWsjo4w3WfpnMcYPScVc1R49u.svg',
    'ar': environment.imgUrl +'Ug9aaCFmhqoDoKHX3Z34VtTeIkFJ8NxXCAw6xFZs.svg'
  };
  direction:any;
  congressId: string;
  constructor(private storageService: StorageService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private authService: AuthService,
              private translate: TranslateService) {



  }

  ngOnInit() {
    const lang = this.storageService.read<string>('lang');
    this.selectedLang = lang ? lang : 'fr';
    this.direction=Utils.getDirectiontemplate(lang)
    this.translate.onLangChange.subscribe((event: any) => {
      this.direction=Utils.getDirectiontemplate(event.lang)
    })

    if (this.userService.loggedUser) {
      this.userService.getLoggedUser().subscribe(
        (data: User) => {
          this.user = data;
          this.userService.loggedUser = data;
          this.storageService.write(StorageService.USER_KEY, data);
        }
      );
    }

  }
  navigateToLogin() {
    this.storageService.write(StorageService.REDIRECT_URL,this.router.url);
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    if (this.congressId) {
      this.router.navigate(['/landingpage/' + this.congressId + '/login']);
    }
  }

  logout() {
    this.authService.logout().subscribe(
      (data) => {
      },
      error => {
      }
    );
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.storageService.removeAll();
    if (this.congressId) {
      this.router.navigate(['/landingpage/' + this.congressId + '/login']);
    }
  }

  goUrl(url: string) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  getUserImg() {
    return 'assets/images/avatar/admin.png';
  }

  isConnected() {
    return (this.authService.isLoggedIn());
  }

  getUser(): User {
    return this.userService.loggedUser ? this.userService.loggedUser : new User();
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.translate.use(lang);
    this.storageService.write('lang', lang);
  }
}

