import {Component, EventEmitter, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import { Service } from 'src/app/shared/models/assignment_service';
import { Establishment } from 'src/app/shared/models/establishment';
import { Author } from 'src/app/shared/models/submission';
import { Utils } from 'src/app/shared/utils';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.css']
})
export class AuthorListComponent implements OnInit, AfterViewInit {

  @Input()
  services: Service[] = [];
  @Input()
  establishments: Establishment[] = [];
  @Input() placeholder;
  first_name = '';
  last_name = '';
  email = '';
  service = '';
  customService =  '';
  customEstablishment = '';
  establishement = '';
  @Input() firstAuthor: Author;
  @Input() items: Author[];
  showTextInputEstb = [false];
  showTextInputService = [false];
  @Output() itemsChange: EventEmitter<any> = new EventEmitter<any>();
  currentEstablishment: Establishment = new Establishment();
  isSubmitted = false;
  otherService: string = '';
  otherEstablishment:string ='';
  imgUrl = environment.imgUrl;  
   
  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    const that = this;
    if (!this.items) {
      this.items = [];
    }
  }

  ngAfterViewInit() {
    this.initSelects();
  }

  addNewItem() {
    this.isSubmitted = true;
    if ( (this.items.length > 0) && (this.items[this.items.length - 1].services === undefined ||
      this.items[this.items.length - 1].etablissement_id === null ||
      this.items[this.items.length - 1].first_name === undefined ||
      this.items[this.items.length - 1].last_name === undefined ||
      this.items[this.items.length - 1].email === undefined ||
      this.items[this.items.length - 1].services === undefined ||
      this.items[this.items.length - 1].rank === undefined)) {
      Utils.swalMessage(
        'SHARED.ERROR',
        'SUBMISSION.AUTHOR_LIST_ERROR',
        'warning',
        this.translate
      );
    } else if( this.items[this.items.length - 1].services === null && ! this.items[this.items.length - 1].otherService ) {
      Utils.swalMessage('SHARED.ERROR','AddOtherService','warning', this.translate );
    } else if( this.items[this.items.length - 1].etablissement_id === (-1).toString() && ! this.items[this.items.length - 1].otherEstablishment ) {
      Utils.swalMessage('SHARED.ERROR','AddOherEstablishment','warning', this.translate );
    }
     else {
      this.itemsChange.emit(this.items);      
      this.showTextInputEstb.push(false);
      this.showTextInputService.push(false);
      this.items.push(new Author());
      this.items[this.items.length - 1].rank = this.items.length ;
      setTimeout(() => {
        this.initSelects();
      }, 30);
    }
  }

  removeItem(i: number) {
    this.items.splice(i, 1);
    /*
    si on supprime un auteur d'index i , on doit donc
    dimiuner les rangs des auteurs qui se trouvent après lui de -1
    */
    for (let k = i; k < this.items.length; k++) {
      this.items[k].rank -- ;
    }

    this.itemsChange.emit(this.items);
  }

  private initSelects() {
    const baseContext = this;
    Utils.initSelect('select-establishment');

    jQuery('.select-establishment').on('select2:select', function (e) {
      if (e.params.data.id === '-1') {
        baseContext.showTextInputEstb[baseContext.items.length] = true;
      }
      baseContext.items[baseContext.items.length - 1].customEstablishment = e.params.data.text;
      baseContext.items[baseContext.items.length - 1].etablissement_id = e.params.data.id;

    });

    Utils.initSelect('select-service');

    jQuery('.select-service').on('select2:select', function (e) {
      if (e.params.data.id === '-1') {
        baseContext.showTextInputService[baseContext.items.length] = true;
      }
      baseContext.items[baseContext.items.length - 1].customService = e.params.data.text;
      baseContext.items[baseContext.items.length - 1].services.push(e.params.data.id);
    });
  }
}