<div class="col-lg-10 col-md-8" Style="font-family: Nunito, sans-serif;">
  <form class="mt-4">
    <div class="row">
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.EMAIL'|translate}} <span class="text-danger">*</span></label>
          <input [disabled]="confirmMode" [(ngModel)]="user.email" #email="ngModel" name="email" id="email" type="email"
            (blur)="autofillUser()" class="form-control" placeholder="e.g. example@gmail.com"
            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            [ngClass]="{'is-invalid': isSubmitted && (!user.email)}">
          <div class="invalid-feedback">
            {{'HOME.EMAIL_FORMAT_INVALID'|translate}}
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.NAME'|translate}} <span class="text-danger">*</span></label>
          <input [(ngModel)]="user.first_name" name="first" id="firstname" type="text" class="form-control"
            placeholder="{{'HOME.NAME'|translate}}" [ngClass]="{'is-invalid': isSubmitted && !user.first_name}">
          <div class="invalid-feedback">
            {{'HOME.FIELD_REQUIRED'|translate}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.SURNAME'|translate}} <span class="text-danger">*</span></label>
          <input [(ngModel)]="user.last_name" name="last" id="lastname" type="text" class="form-control"
            placeholder="{{'HOME.SURNAME'|translate}}" [ngClass]="{'is-invalid': isSubmitted && !user.last_name}">
          <div class="invalid-feedback">
            {{'HOME.FIELD_REQUIRED'|translate}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.PHONE'|translate}} <span class="text-danger">*</span></label>
          <input [(ngModel)]="user.mobile" type="text" name="phone" id="phone" class="form-control"
            placeholder="e.g. (+216) 55 555 555" [ngClass]="{'is-invalid': isSubmitted && !user.mobile}">
          <div class="invalid-feedback">
            {{'HOME.FIELD_REQUIRED'|translate}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.PASSWORD'|translate}} <span class="text-danger">*</span></label>
          <input [(ngModel)]="user.password" [ngClass]="{'is-invalid': isSubmitted && !user.password}" name="code"
            id="companyname" type="password" class="form-control" placeholder="************">
          <div class="invalid-feedback">
            {{'HOME.FIELD_REQUIRED'|translate}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group position-relative">
          <label>{{'HOME.CONFIRM_PASSWORD'|translate}} <span class="text-danger">*</span></label>
          <input [(ngModel)]="user.passwordConfirm"
            [ngClass]="{'is-invalid': isSubmitted && user.password!==user.passwordConfirm}" name="password"
            type="password" id="address1" class="form-control" placeholder="************">
          <i *ngIf="user.password===user.passwordConfirm" class="fa fa-check" aria-hidden="true"
            style="color:#00cc00; float:right; font-size: 20px;"></i>
          <div class="invalid-feedback">
            {{'HOME.PASSWORDS_ARENT_THE_SAME'|translate}}
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group position-relative">
          <label>
            {{'HOME.GENDER'|translate}} <span class="text-danger">*</span>
          </label>
          <div class=" custom-radio custom-control-inline">

            <div *ngFor="let gender of genders" class="col-md-6">
              <div class="form-group position-relative">
                <!-- <input type="radio" id="homme" name="homme" class="custom-control-input"  [(ngModel)]="user.gender" value=1>
                      <label class="custom-control-label" for="homme">Homme</label> -->
                <div class="custom-control custom-radio">
                  <input class="form-check-input" type="radio" for="{gender.label}" [(ngModel)]="user.gender" [value]="gender.gender_id"
                   [checked]="user.gender == gender.gender_id" [ngModelOptions]="{standalone: true}"
               >
                  <label>
                    {{getValueTranslate(gender)}}
                  </label>

                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="showPhoto">
        <div class="form-group position-relative">
          <label>{{'HOME.Photo' | translate}}</label>
          <div class="d-flex flex-column">
            <div class="custom-file">
              <app-upload-file #uploadCroppie [uploadType]="'croppie'" (onPhotoUploaded)="uploadedPhoto($event)"></app-upload-file>
              <label class="custom-file-label" for="upload">{{"HOME.ChoosePhoto" | translate}}</label>
            </div>
            <div class="displayed mt-4">
              <div id="upload-demo">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </form>
  <!--end form-->
</div>
