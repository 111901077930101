<!-- Hero Start -->
<div id="preloader" *ngIf="loader">
  <div id="status" *ngIf="loader">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div>
  </div>
</div>
<section class="bg-home  d-flex align-items-center"
  [ngStyle]="{'background-image': configLandingPage?.home_banner_event!==null ?  'url(' +filesUrl + configLandingPage?.home_banner_event+ ')':'url( \'imgUrl  + sMcMajV6SBaUG1eCVvl6p2ETsMQCP0RW90hNw0TD.jpg \')' }"
  style="background-image:center center;" [ngBusy]="busy" *ngIf="!loader">
  >
  <h1>{{seo?.first_title}}</h1>
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center"> {{'HOME.LOGIN'|translate}}</h4>
            <form class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label> {{'HOME.EMAIL'|translate}}: <span class="text-danger">*</span></label>
                    <div class="input-icon"> <i class="far fa-user"></i>
                      <input type="email" class="form-control pl-5" placeholder="{{'HOME.EMAIL'|translate}}:" name="email" required=""
                        [(ngModel)]="credentials.email" [ngClass]="{'is-invalid': isSubmitted && !credentials.email}">
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>{{'HOME.PASSWORD'|translate}} <span class="text-danger">*</span></label>
                    <div class="input-icon"><i class="icon-lock fa"></i>
                      <input type="password" class="form-control pl-5" placeholder="Password" required=""
                        [(ngModel)]="credentials.password"
                        [ngClass]="{'is-invalid': isSubmitted && !credentials.password}" name="password">
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">

                      </div>
                    </div>
                    <p class="forgot-pass mb-0"><a routerLink="/password/reset/congress/{{congress_id}}"
                        class="text-dark font-weight-bold">{{'HOME.FORGOT PASSWORD'|translate}}</a></p>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <button class="btn btn-primary btn-block" (click)="loginSubmit()">{{'HOME.LOGIN'|translate}}</button>
                </div>
                <div class="col-lg-12 mt-4 text-center">
                  <ul class="list-unstyled social-icon mb-0 mt-3">
                    <li class="list-inline-item"><a (click)=" facebook()" class="rounded rounded-fb">
                        <i class="fab fa-facebook"></i>
                      </a></li>


                    <li class="list-inline-item ml-1"><a (click)="registerUsingGoogle()" class="rounded">
                        <img src="https://img.icons8.com/color/20/000000/google-logo.png" /> </a></li>
                  </ul>

                </div>

              </div>
            </form>
          </div>
        </div>
        <!---->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
