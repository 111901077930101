import {Component, OnInit} from '@angular/core';
import {User} from '../../shared/models/user';
import {Subscription} from 'rxjs';
import {UserService} from '../../shared/services/user.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Country} from 'src/app/shared/models/Country';
import {environment} from '../../../environments/environment';
import {StorageService} from 'src/app/shared/services/storage.service';
import {SharedService} from 'src/app/shared/services/shared.service';
import {UploadFileService} from '../../shared/services/upload-file.service';
import {Utils} from '../../shared/utils';
import {TranslateService} from '@ngx-translate/core';

declare var swal: any;

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  user: User;
  busy: Subscription;
  isSubmitted = false;
  passwordConfirm: string;
  countries: Country[] = [];
  filesUrl = environment.filesUrl;


  constructor(private userService: UserService, private storageService: StorageService,
              private sharedService: SharedService, private router: Router,
              private uploadFileService: UploadFileService,
              private translate: TranslateService) {


  }

  ngOnInit() {
    this.user = this.userService.loggedUser;
    this.getAllCountries();
  }

  getAllCountries() {
    this.busy = this.sharedService.getAllCountries()
      .subscribe(response => this.countries = response);

  }

  isChampsValid() {
    if (this.user.first_name && this.user.last_name && this.user.email && this.user.country_id && this.user.mobile) {
      return true;
    }
    return false;
  }

  updateProfile() {
    this.isSubmitted = true;
    if (!this.isChampsValid()) {
      Utils.swalMessage('SWAL.ATTENTION', 'SWAL.CHAMPS', 'warning',  this.translate);
      return;
    }
    if (this.user.password && this.user.password !== this.passwordConfirm) {
      Utils.swalMessage('SWAL.ATTENTION', 'SWAL.IDENTICALPW', 'warning',  this.translate);
      this.passwordConfirm = '';
      return;
    }

    this.busy = this.userService.modifUserInfo(this.user)
      .subscribe(response => {
          this.storageService.write(StorageService.USER_KEY, response);
          this.userService.loggedUser = this.user = response;
          Utils.swalMessage('SHARED.SUCCESS', 'UpdateSuccess', 'success', this.translate);
        },
        error => {
          Utils.swalMessage('SHARED.ERROR', 'UpdateError', 'error', this.translate);
        });
  }


  uploadedPhoto(event) {
    this.busy = this.uploadFileService.uploadFile(event)
      .subscribe(data => {
        this.user.resource_id = data.resource.resource_id;
        Utils.swalMessage('SHARED.SUCCESS', 'ProfilePhotoUploadSuccess', 'success', this.translate);
      });

  }

}
