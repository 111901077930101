import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigLandignPage } from '../../shared/models/ConfigLandingPage';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-organizers',
  templateUrl: './organizers.component.html',
  styleUrls: ['./organizers.component.css']
})
export class OrganizersComponent implements OnInit {
  @Input() organizers;
  @Input() configLandingPage: ConfigLandignPage;
  url = environment.filesUrl;
  @Input() lang: string;
  constructor() { }

  ngOnInit(): void {
  }

  getValueTranslate(columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }
}
