<section class="bg-half-260 w-100 d-table" *ngIf="configLandingPage?.home_banner_event || configLandingPage?.home_banner_event_en || configLandingPage?.home_banner_event_ar" id="home" data-jarallax='{"speed": 0.5}'
[ngStyle]="{'background-image': 'url(' +filesUrl + home_banner_event + ')',
'background-size': screenWidth>991 ? screenWidth + 'px' : '', 
'background-repeat': 'no-repeat',
 height: screenWidth>991 ? '595px' : '',
 padding:screenWidth>991 ? 0 : ''}" 
 style="background-image:center center;"
 [ngBusy]="busy"
 >
  <div class="bg-overlay2"
    [ngStyle]="{'background-color':backgroundColor,'opacity':opacity}">
  </div>
  <div class="container" style="max-height: 595px;">
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <div class="title-heading" [ngClass]="{'title-margin':  configLandingPage?.home_title || configLandingPage?.home_description }">
          <h4 *ngIf="configLandingPage?.show_date" style="font-weight: 600; font-size: 1.5rem;"><span
              *ngFor="let date of dateOfEvent">{{date|date:'d '}},</span>{{lang != 'ar' ? (configLandingPage.home_end_date |date:'d MMMMyyyy':'': lang | uppercase) : (configLandingPage.home_end_date |date:'d /MM/yyyy':'' | uppercase) }}</h4>
          <p *ngIf="lang === 'fr'" [innerHTML]="configLandingPage?.home_title" #eventTitle></p>
          <p *ngIf="lang === 'en'" [innerHTML]="configLandingPage?.home_title_en" #eventTitle></p>
          <p *ngIf="lang === 'ar'" [innerHTML]="configLandingPage?.home_title_ar" #eventTitle></p>
          <p class="para-desc title-dark mx-auto text-light">
            {{getValueTranslate('home_description')}}</p>
          <div class="row">
            <div class="col-md-12 text-center">
              <div id="eventdown"></div>
            </div>
          </div>
          <div class="center-button" [ngClass]="{'banner-margin': !configLandingPage?.home_title && !configLandingPage?.home_description }">
            <div class="mt-4 pt-2" *ngIf="configLandingPage?.is_inscription===1  && !isConnected">
              <a (click)="redirectToRegister()" class="btn btn-success mt-2 my-4 btn-lg mr-2">{{'HOME.SIGN UP'|translate}}</a>
            </div>
            <div class="mt-4 pt-2"
              *ngIf="configLandingPage?.is_inscription===1  && !isConnected && configLandingPage.home_btn_text">
              <a (click)="redirectToLink()" class="btn btn-success mt-2 my-4 btn-lg ml-2">{{configLandingPage.home_btn_text | translate}}</a>
            </div>
            <div id="mobile_btn" class="mt-4 pt-2" *ngIf="configLandingPage?.is_b2b_btn===1  && isConnected">
              <a (click)="toB2B()" class="btn btn-success mt-2 my-4 btn-lg ml-2">{{'B2B'|translate}}</a>
              </div>
            <div class="mt-4 pt-2" *ngIf="configLandingPage?.is_submission===1">
              <a (click)="redirectToAddSubmission()" class="btn btn-success mt-2 my-4 btn-lg ml-2">{{'HOME.SUBMIT'|translate}}</a>
            </div>
            <div class="mt-4 pt-2" *ngIf="isConnected">
              <a (click)="toEvent()" class="btn btn-success mt-2 my-4 btn-lg ml-2">{{'AccessToEvent'|translate}}</a>
              </div>
              <div class="mt-4 pt-2">
                <a *ngIf="configLandingPage.live_title_btn || configLandingPage.live_title_btn_en" (click)="toLive()" class="btn btn-success mt-2 my-4 btn-lg ml-2"> {{getValueTranslate('live_title_btn')}}</a>
              </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>