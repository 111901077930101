import { Congress } from 'src/app/shared/models/congress';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})
export class ProgramComponent implements OnInit {
  @Input() accesses;
  @Input() configLandingPage: ConfigLandignPage;
  @Input() isRoom = false;
  resultData = [];
  lang;
  @Output() toRegister: EventEmitter<null> = new EventEmitter();
  dateOfEvent: any[] = [];
  selected_day = 0;
  one_day_accesss = [];
  @Input() congress: Congress;

  constructor( private _translate: TranslateService) {
    this.lang = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
  }

  ngOnInit(): void {
    this.getDateBetweenTwoDate();
    this.showDay(this.dateOfEvent[0]?.getDate(), 0); // deault first day will be selected  
  }
  getDateBetweenTwoDate() {
    if (this.configLandingPage?.home_start_date && this.configLandingPage?.home_end_date) {
      this.dateOfEvent = Utils.nombreJourEvent(this.configLandingPage?.home_start_date, this.configLandingPage?.home_end_date);
    } else {
      this.dateOfEvent = Utils.nombreJourEvent(this.congress?.start_date, this.congress?.end_date);
    }
  }

  redirectToRegister() {
    this.toRegister.emit();
  }
  showDay(current_day: number, index: number) {
    this.selected_day = index;

    this.accesses.filter(i => i.show_in_register === 0).forEach(element => {
      var dateObj = new Date(element.start_date);
      
      if (dateObj.getDate() == current_day) {
        
        
        this.one_day_accesss.push(element);
      }

    });
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
  }

  getValueTranslate (columnName: string) {
    if (this.configLandingPage) {
      return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
    }
  }

  downloadPrgFile() {
    window.open(environment.filesUrl + this.configLandingPage.prg_file);
  }

  getValueTranslateAccess (columnName: string, access) {
    return Utils.getValueTranslate(this.lang, access, columnName);
  }
}
