import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { FullLayoutComponent } from "./full-layout/full-layout.component";

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StorageService } from "./shared/services/storage.service";
import { UserService } from "./shared/services/user.service";
import { AuthService } from "./shared/services/auth.service";
import { SharedModule } from "./shared/shared.module";
import { AngularFireModule } from "@angular/fire/compat";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CanActivateViaAuthGuard } from "./shared/services/guards/auth-guard.service";

import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TokenInterceptor } from "./shared/interceptor/token.interceptor";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { environment } from "src/environments/environment";
import { MessagingService } from "./shared/services/messaging.service";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { ChatService } from "./shared/services/chat.service";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { StandService } from "./shared/services/stand.service";
import { LandingPageEventModule } from "./landing-page/landing-page-event.module";
import { QuizService } from "./shared/services/quiz.service";
import {
  ApmErrorHandler,
  ApmModule,
  ApmService,
} from "@elastic/apm-rum-angular";
import { AppRoutingModule } from "./app-routing.module";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderService } from "./shared/services/loader.service";
import { SuccessPageComponent } from "./success-page/success-page.component";
import { SubmissionModule } from "./submission/submission.module";
import { UserProfileModule } from "./user-profile/user-profile.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    TermsAndConditionsComponent,
    LoaderComponent,
    SuccessPageComponent,
  ],
  imports: [
    BrowserModule,
    LandingPageEventModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    HttpClientModule,
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    SharedModule,
    NgxSpinnerModule,
    ApmModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SubmissionModule,
    UserProfileModule,
  ],
  providers: [
    QuizService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    CanActivateViaAuthGuard,
    UserService,
    StorageService,
    AuthService,
    GoogleAnalyticsService,
    MessagingService,
    ChatService,
    AngularFireDatabase,
    LoaderService,
    ApmService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    StandService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    // API is exposed through this apm instance
    /* TODO desactivate
    const apm = service.init({
      serviceName: 'eventizer-frontoffice-' + environment.environment,
      serverUrl: environment.elasticRUMUrl
    });

    apm.setUserContext({
      'username': 'eventizer-' + environment.environment,
      'id': environment.environment
    });
    */
  }
}
