import { UserDetailsComponent } from './user-details/user-details.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserProfileRoutingModule} from './user-profile.routing';
import {UserInfoComponent} from './user-info/user-info.component';
import {FormsModule} from '@angular/forms';
import {UserProfileComponent} from './user-profile.component';
import {MessageNomodalComponent} from './message-nomodal/message-nomodal.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import { UserCongressComponent } from './user-congress/user-congress.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CongressModule} from '../congress/congress.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { UserContactsComponent } from './user-contacts/user-contacts.component';


@NgModule({

  imports: [
    CommonModule,
    UserProfileRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    NgxSpinnerModule,
    CongressModule,
    InfiniteScrollModule,
    ],
  declarations: [
    UserInfoComponent,
    UserProfileComponent,
    MessageNomodalComponent,
    UserCongressComponent,
    UserContactsComponent,
    UserDetailsComponent
  ],
  exports: [
    UserDetailsComponent
  ]
})
export class UserProfileModule {
}
