import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { CongressService } from 'src/app/shared/services/congress.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

declare var swal: any;

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})

export class ForgetPasswordComponent implements OnInit {
  RequestResetForm: FormGroup;
  busy: Subscription;
  congress_id;
  configLandingPage: ConfigLandignPage;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl; 
  selectedLang:string="fr";
  constructor(private userService: UserService,private congressService: CongressService,private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService) {
      const lang = this.storageService.read<string>('lang');
      this.selectedLang = lang ? lang : 'fr';
      this.activatedRoute.params.subscribe(params => {
        this.congress_id = params['id_congress'];
      });
  }
  ngOnInit(): void {
    this.getConfigLandingPage();
    this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'id':new FormControl(this.congress_id,[])
    });
  }
  RequestResetUser() {
    if (this.RequestResetForm.valid) {
      this.busy = this.userService.forgetPassword(this.RequestResetForm.value['email'],this.RequestResetForm.value['id']).subscribe(data => {
        Utils.swalMessage('SWAL.SUCCESS', 'SWAL.REINSTALL', 'success',  this.translate);
      }, error => {
        Utils.swalMessage('SWAL.ERROR', 'SWAL.RESAISIR', 'error',  this.translate);
      });

    }
  }
  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congress_id).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
      }
    });
  }

}
