import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CongressRoutingModule } from './congress.routing';
import { FormsModule } from '@angular/forms';
import { MessageComponent } from '../submission/message/message.component';
import { SharedModule } from '../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OnlineRoomComponent } from './online-room/online-room.component';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import {AgoraModule} from '../agora/agora.module';
import { OnlineRoomQuizComponent } from './online-room-quiz/online-room-quiz.component';


@NgModule({
    imports: [
        CongressRoutingModule,
        CommonModule,
        FormsModule,
        SharedModule,
        NgxSpinnerModule,
        TranslateModule,
        InfiniteScrollModule,
        AngularFireDatabaseModule,
        AgoraModule
    ],
  exports: [
    
  ],
  declarations: [
    
    MessageComponent,
    
    OnlineRoomComponent,
    
   
    OnlineRoomQuizComponent,
    

  ]
})
export class CongressModule {
}
