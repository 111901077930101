import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = true;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  
}
