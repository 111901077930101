import { FMenu } from "../../shared/models/FMenu";


export class ConfigLandignPage {
    congress_id: number;
    header_logo_event: string;
    is_inscription = 0;
    register_link : string ;
    home_title: string ;
    home_description: string ;
    home_start_date: Date ;
    home_end_date: Date ;
    home_banner_event: string ;
    prp_banner_event: string ;
    prp_title: string ;
    prp_description: string ;
    speaker_title: string ;
    speaker_description: string ;
    sponsor_title: string ;
    sponsor_description: string ;
    prg_title: string;
    prg_description: string;
    contact_title: string;
    contact_description: string;
    event_link_fb: string;
    event_link_instagram: string;
    event_link_linkedin: string;
    event_link_twitter: string;
    theme_color: string;
    theme_mode: string;
    name_partenaire: string;
    link_partenaire: string;
    show_date:boolean;
    background_color:string;
    opacity_color:number;
    eventLocation: Location ;
    home_title_en: string;
    home_description_en: string;
    prp_title_en: string ;
    prp_description_en: string ;
    speaker_title_en: string ;
    speaker_description_en: string ;
    sponsor_title_en: string ;
    sponsor_description_en: string ;
    prg_title_en: string;
    prg_description_en: string;
    contact_title_en: string;
    contact_description_en: string;
    f_menu: FMenu[] = [];
    home_sub_title: string;
    home_sub_title_en: string;
    waiting_title: string;
    waiting_desription: string;
    opening_date: Date;
    waiting_banner: string; 
    waiting_color: string;
    waiting_opacity: string;
    organizers_title: string;
    organizers_description: string;
    organizers_title_en: string;
    organizers_description_en: string;
    partners_title: string;
    partners_title_en : string;
    partners_description: string;
    partners_description_en: string;
    page_title: string;
    prg_file:string;
    prp_link: string;
    prp_btn_text: string;
    prp_btn_text_en: string;
    is_b2b_btn: number;
    home_btn_text: string;
    home_btn_link: string;
    live_title_btn: string;
    live_title_btn_en: string;
    live_title: string;
    live_title_en: string;
    live_link: string;
    home_title_ar:string;
    home_description_ar:string;
    prp_title_ar:string;
    prp_description_ar:string;
    speaker_title_ar:string;
    speaker_description_ar:string;
    sponsor_title_ar:string;
    sponsor_description_ar:string;
    prg_title_ar:string;
    prg_description_ar:string;
    contact_title_ar:string;
    contact_description_ar:string;
    home_sub_title_ar:string;
    organizers_title_ar:string;
    organizers_description_ar:string;
    partners_title_ar:string;
    partners_description_ar:string;
    waiting_title_ar:string;
    waiting_description_ar:string;
    prp_btn_text_ar:string;
    live_title_btn_ar:string;
    live_title_ar:string;
    page_title_ar: string;
    live_link_ar: string;
    redirect_to_pwa: boolean;
    specific_bnr: string;
    specific_bnr_title: string;
    specific_bnr_description: string;
    specific_bnr_title_en: string;
    specific_bnr_description_en: string;
    specific_bnr_ar: string;
    specific_bnr_title_ar: string;
    specific_bnr_description_ar: string;
    companies_title: string;
    companies_title_ar: string;
    companies_title_en: string;
    companies_description: string;
    companies_description_en: string;
    companies_description_ar: string;
    specific_bnr_two: string;
    specific_bnr_two_title: string;
    specific_bnr_two_description: string;
    specific_bnr_two_title_en: string;
    specific_bnr_two_description_en: string;
    specific_bnr_two_ar: string;
    specific_bnr_two_title_ar: string;
    specific_bnr_two_description_ar: string;
    sponsor_pack_title: string;
    sponsor_pack_title_en: string;
    sponsor_pack_title_ar: string;
    sponsor_pack_description: string;
    sponsor_pack_description_en: string;
    sponsor_pack_description_ar: string;
    specific_bnr_en:string;
    home_banner_event_:string;
    home_banner_event_ar:string;
    prp_banner_event_en:string;
    prp_banner_event_ar:string;
    home_banner_event_en:string;
    is_background_white: number;
}
