import { GenericService } from './generic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { CommunicationType } from '../models/CommunicationType';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })
  export class CommunicationTypeService extends GenericService {
    constructor(private http: HttpClient, private storageService: StorageService) {
      super();
    }
  getAllCommunicationType(): Observable<CommunicationType[]> {
    const url = environment.baseUrl + '/communication_types';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<CommunicationType[]>(url, {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getAllCommunicationTypePublic(): Observable<CommunicationType[]> {
    const url = environment.baseUrl + '/communication_types/public';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<CommunicationType[]>(url, { headers: headers })
      .pipe(catchError(this.handleErrors));
  }


}
