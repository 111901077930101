import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiVideoconfComponent} from './ui-videoconf/ui-videoconf.component';


@NgModule({
  declarations: [UiVideoconfComponent],
  exports: [UiVideoconfComponent],
  imports: [
    CommonModule
  ]
})
export class AgoraModule {
}
