import {Stand} from './../../shared/models/Stand';
import {StandService} from './../../shared/services/stand.service';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Congress} from 'src/app/shared/models/congress';
import {ActivatedRoute, Router} from '@angular/router';
import '../../../assets/js/jitsi/external_api.js';
import {AccessService} from '../../shared/services/access.service';
import {Access} from '../../shared/models/Access';
import {UserService} from '../../shared/services/user.service';
import {User} from 'src/app/shared/models/user.js';
import {StorageService} from '../../shared/services/storage.service';
import {MessagingService} from 'src/app/shared/services/messaging.service';
import {environment} from '../../../environments/environment';
import {Utils} from '../../shared/utils';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {Organization} from '../../shared/models/organization';
import {AgoraOptions, UiVideoconfComponent} from '../../agora/ui-videoconf/ui-videoconf.component';
import { CongressService } from '../../shared/services/congress.service';
import { MeetingService } from 'src/app/shared/services/meeting.service';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { SEO } from 'src/app/shared/models/Seo';
import { Meta, Title } from '@angular/platform-browser';

declare var JitsiMeetExternalAPI: any;
declare var swal: any;

@Component({
  selector: 'app-online-room',
  templateUrl: './online-room.component.html',
  styleUrls: ['./online-room.component.css']
})
export class OnlineRoomComponent implements OnInit, OnDestroy {

  @ViewChild(UiVideoconfComponent) uiVideoconfComponent: UiVideoconfComponent;

  congressId: string;
  accessId: number;
  filesUrl = environment.filesUrl;
  domain: string;
  options: any;
  api: any;
  user: User;
  jwt: string;
  access: Access = new Access();
  body = new FireBaseKeyRequest();
  isMainRoom = false;
  url_streaming: string;
  roomName: string;
  busy: Subscription;
  organizations: Array<Organization> = [];
  congress: Congress = new Congress();
  participantNumber: number;
  standId = null;
  meetingId = null;
  isStand = false;
  stand: Stand = new Stand();
  organizerId = null;
  isOrganizer = false;
  type: string;
  name: string;
  agoraOptions: AgoraOptions;
  isMeeting: boolean = false;
  paramsIsOrganizer:number;
  configLandingPage: ConfigLandignPage;
  seo:SEO;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private accessService: AccessService,
              private userService: UserService,
              private messagingService: MessagingService,
              private standService: StandService,
              private translate: TranslateService,
              private congressService: CongressService,
              private meetingService: MeetingService,
              private meta: Meta,
              private titleService: Title,) {
    this.congressId = this.route.snapshot.paramMap.get('congress_id');
    this.isMainRoom = this.router.url.indexOf('access') === -1;
    this.accessId = parseInt(this.route.snapshot.paramMap.get('access_id'), 0);
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.domain = environment.meetUrl;
    this.standId = parseInt(this.route.snapshot.paramMap.get('stand_id'), 0);
    this.meetingId = parseInt(this.route.snapshot.paramMap.get('meeting_id'), 0);
    this.isMeeting = this.router.url.indexOf('meetings') !== -1;
    this.isStand = this.router.url.indexOf('stands') !== -1;
    this.type = this.isStand ? 'STAND' : 'ACCESS';
    this.organizerId = parseInt(this.route.snapshot.paramMap.get('user_id'), 0);
    this.isOrganizer = this.router.url.indexOf('support') !== -1;
    this.route.queryParams.subscribe(params => {
      this.paramsIsOrganizer=params.isOrganizer;
    });
  }

  ngOnInit(): void {
  
    this.busy = this.congressService.getCongressMinById(parseInt(this.congressId, 0))
      .subscribe(
        (data: Congress) => {
          this.congress = data;
        });
    this.getConfigLandingPage();
    this.messagingService.receiveMessage();
    this.roomName = Utils.getRoomName(this.congressId, this.accessId, this.standId, this.organizerId, this.meetingId);
    if (this.isMeeting) {
      this.checkMeetingRights();
      this.paramsIsOrganizer == 1 ? this.makeOrganizerPresent():this.makeParticipantPresent()
    } else if (this.isStand) {
      this.checkStandRights();
      this.getStandById();
    } else if (this.isOrganizer) {
      this.checkSupportRights();
    } else {
      this.checkRights(this.congressId, this.accessId);
      this.changeUserPresence(this.congressId, this.accessId, 1);
      if (!this.isMainRoom) {
        this.processingAccessRoom();
      } else {
        this.processingMainRoom();
        this.name = 'MAINROOM';
      }
    }
    this.busy = this.congressService.getSeoByPage(parseInt(this.congressId),"online-room")
      .subscribe(
        (data: SEO) => {
          this.seo = data;
          if (this.seo) {
            this.titleService.setTitle(this.seo.title);
            this.meta.updateTag(
              {
                name: 'description',
                content: this.seo.meta_description
              });
            this.meta.updateTag(
              {
                name: 'keywords',
                content: this.seo.meta_keywords
              });
          }
        });
  }


  @HostListener('window:beforeunload', ['$event'])
  ngOnDestroy() {
    if (this.accessId) {
      let userPresence = {
        type: 0,
        isPresent: 1,
        accessId: this.accessId,
        congressId: this.congressId
      };
      this.accessService.changeUserAccessPresenceLeftTime(userPresence, this.user.user_id);
    }
  }

  getStandById() {
    this.standService.getStandById(this.congressId, this.standId).subscribe((data: Stand) => {
      this.stand = data;
      this.name = this.stand.name;
    });
  }

  checkBottomPosision() {
    for (let organization of this.organizations) {
      if (organization.logo_position === 'Bottom') {
        return true;
      }
    }
    return false;
  }

  getAccessById() {
    this.accessService.getAccessById(this.accessId)
      .subscribe(
        (data: Access) => {
          if (!data || !data.is_online) {
            this.router.navigate(['/login']);
            return;
          }
          this.access = data;
          this.name = this.access.name;
        },
        (error) => {
          this.router.navigate(['/login']);
        }
      );
  }

  checkRights(congress_id, access_id?) {
    this.userService.checkUserRights(congress_id, access_id)
      .subscribe((response: any) => {
          if (response.allowed) {
            this.initVideoConference(response);
          } else {
            this.url_streaming = response.url_streaming;
          }
        },
        (err) => {
          this.router.navigate(['/']);
          Utils.swalMessage('SWAL.ERROR', 'SWAL.NORIGHT', 'error', this.translate);
          return;
        }
      );
  }

  initVideoConference(response) {
    if (response.type === 'agora') {
      this.agoraOptions       = new AgoraOptions();
      this.agoraOptions.token = response.token;
      this.agoraOptions.uid   = this.user.user_id;
      setTimeout( async ()=> {
        await this.uiVideoconfComponent.joinAgora();
      }, 50);
    } else {
      this.initJitsiRoom(response.token, response.is_moderator);
    }
  }

  initJitsiRoom(tokenJwt, isModerator) {
    const that = this;
    this.jwt = tokenJwt;
    this.options = {
      roomName: this.roomName,
      width: '100%',
      parentNode: document.querySelector('#meet'),
      jwt: this.jwt
    };
    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    if (isModerator) {
      this.api.on('participantJoined', function (object) {
        console.log('participantJoined');
        that.congressService.setCurrentParticipant(that.congressId, that.accessId, that.api.getNumberOfParticipants())
          .subscribe(
            (data) => {

            }
          );
      });

      this.api.on('participantLeft', function (object) {
        that.congressService.setCurrentParticipant(that.congressId, that.accessId, that.api.getNumberOfParticipants()).subscribe(
          (data) => {
          }
        );
      });
    }
    console.log(that.api.getNumberOfParticipants());

  }

  changeUserPresence(congressId, accessId, type) {
    this.congressService.makeUserPresent(congressId, accessId, type).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        this.router.navigate(['congress/details/' + this.congressId]);
      });
  }

  processingAccessRoom() {
    this.getAccessById();
    this.messagingService.currentMessage.subscribe((message) => {
      if (message !== null && message.data.title === 'collect') {
        this.router.navigate([message.data.body]);
        this.messagingService.currentMessage.next(null);
      }
    });
  }

  processingMainRoom() {
    this.messagingService.requestPermission();
    this.messagingService.currentToken.subscribe((value) => {
      if (value !== null) {
        this.body.source = 'frontOffice';
        this.body.userId = this.user.user_id;
        this.body.token = value;
        this.messagingService.saveToken(this.congressId, this.body).subscribe(
          (response) => {
            console.log(response); // pour le test et la validation
          }
        );
      }
    });
    this.messagingService.currentMessage.subscribe((message) => {
      console.log(message);
      if (message !== null && message.data.title === 'distribute') {
        this.router.navigate([message.data.body]);
        this.messagingService.currentMessage.next(null);
      }
    });
  }

  checkStandRights() {
    this.userService.checkStandRights(parseInt(this.congressId, 0), this.standId)
      .subscribe(
        (response: any) => {
          if (response.allowed) {
            this.initVideoConference(response);
          } else {
            this.url_streaming = response.url_streaming;
          }
        },
        (err) => {
          this.router.navigate(['/']);
          Utils.swalMessage('SWAL.ERROR', 'SWAL.NoAcces', 'error', this.translate);
          return;
        }
      );
  }

  checkSupportRights() {
    this.userService.checkStandRights(parseInt(this.congressId, 0), null, this.organizerId)
      .subscribe(
        (response: any) => {
          if (response.allowed) {
            this.initVideoConference(response);
          }
        },
        (err) => {
          this.router.navigate(['/']);
          Utils.swalMessage('SWAL.ERROR', 'SWAL.NORIGHT', 'error', this.translate);
          return;
        }
      );
  }

  checkMeetingRights() {
    this.userService.checkMeetingRights(parseInt(this.congressId, 0), this.meetingId)
      .subscribe(
        (response: any) => {
          if (response.allowed) {
            this.initVideoConference(response);
          }
        },
        (err) => {
          this.router.navigate(['/']);
          Utils.swalMessage('SWAL.ERROR', 'SWAL.NoAcces', 'error', this.translate);
          return;
        }
      );
  }

  makeOrganizerPresent() {
    this.meetingService.makeOrganizerPresent(this.meetingId).subscribe(
      (response) => {
        
      },
      (error) => {
        this.router.navigate(['congress/details/' + this.congressId]);
      });
  }

  makeParticipantPresent() {
    this.meetingService.makeParticipantPresent(this.meetingId, this.user.user_id).subscribe(
      (response) => {
        
      },
      (error) => {
        this.router.navigate(['congress/details/' + this.congressId]);
      });
  }

  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congressId).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
        if(this.configLandingPage!= null && Utils.compareDates(this.configLandingPage.opening_date,new Date)) {
          this.router.navigateByUrl('/landingpage/' + this.congressId + '/waiting-room'); 
    }
      }
    });
  }
}

export class FireBaseKeyRequest {
  token: string;
  userId: number;
  source: string;
}
