<div *ngFor="let it of elements" class="item-list job-item ghost-item" style="height: 140px">
  <div class="row">

    <div class="col-sm-1  col-xs-2  loading" style="min-height: 60px">

    </div>
    <!--/.photobox-->
    <div class="col-sm-10  col-xs-10  ">
      <div class="ads-details jobs-item">
        <div class="job-title loading " style="min-height: 10px ; max-width: 100px">


        </div>
        <div class="job-title">

          <span class="pull-right loading" style="min-height: 10px ; max-width: 100px"></span>

        </div>

        <div class="job-title loading" style="min-height: 10px ; max-width: 200px">
        </div>
        <div class="job-title loading " style="min-height: 10px ; max-width: 400px"></div>

        <div class="job-title loading  " style="min-height: 10px ; max-width: 400px">
        </div>

        <div class="job-title loading  " style="min-height: 10px ; max-width: 50px">
        </div>


      </div>
    </div>
    <!--/.add-desc-box-->

  </div>
</div>
