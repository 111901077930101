import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { interval ,Subscription } from 'rxjs';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { CongressService } from 'src/app/shared/services/congress.service';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.css']
})
export class WaitingComponent implements OnInit , OnDestroy{
  configLandingPage: ConfigLandignPage = new ConfigLandignPage();
  congressId: string;
  busy: Subscription;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl; 
  lang: string;
  timeDifference: number;
  subscription: Subscription;
  timer: Timer = new Timer();
  timeInSec: TimeInSec = new TimeInSec();
  constructor(private congressService: CongressService, 
    private route: ActivatedRoute,
    private translate: TranslateService,private sanitizer: DomSanitizer) {
    this.route.params.subscribe(params => {
      this.congressId = params['id_congress'];
    });
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.getConfigLandingPage(); 
    this.subscription=interval(1000).subscribe(x => { this.getTimeDifference()}); 
  }

  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congressId).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
      }
    });

  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  getTimeDifference() {
    this.timeDifference =  (new Date(this.configLandingPage.opening_date).getTime()) -  new  Date().getTime() ;
    this.allocateTimeUnits(this.timeDifference);
  }

  allocateTimeUnits (timeDifference) {
    this.timer.secondsToDday = Math.floor((timeDifference) / (this.timeInSec.milliSecondsInASecond) % this.timeInSec.secondsInAMinute);
    this.timer.minutesToDday = Math.floor((timeDifference) / (this.timeInSec.milliSecondsInASecond * this.timeInSec.minutesInAnHour) % this.timeInSec.secondsInAMinute);
    this.timer.hoursToDday = Math.floor((timeDifference) / (this.timeInSec.milliSecondsInASecond * this.timeInSec.minutesInAnHour * this.timeInSec.secondsInAMinute) % this.timeInSec.hoursInADay);
    this.timer.daysToDday = Math.floor((timeDifference) / (this.timeInSec.milliSecondsInASecond * this.timeInSec.minutesInAnHour * this.timeInSec.secondsInAMinute * this.timeInSec.hoursInADay));
  }

ngOnDestroy() {
  this.subscription.unsubscribe();
}

transformHtml(htmlTextWithStyle): SafeHtml {
  return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
}
}



export class Timer{
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;
  }
  
  export class TimeInSec{
  milliSecondsInASecond: number = 1000;
  hoursInADay: number = 24;
  minutesInAnHour: number = 60;
  secondsInAMinute: number = 60;
  }