<!-- Event snippet for Form Submission conversion page -->
<script>
    gtag('event', 'conversion', { 'send_to': 'AW-11127448695/eQCoCPj56JMYEPfI_bkp' });
</script>

<div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center" [ngBusy]="busy">
    <div class="col-12 text-center">
        <div class="section-title">
            <img src="assets/images/succes.png" width="150">
            <h4 class="title mb-4">{{'ThanksAccessPage' | translate}}</h4>
            <div class="title mb-4">
            
            </div>
            <p
                class="text-muted para-desc mx-auto">
                {{'MessageSelection' | translate}}
            </p>
            <p
                class="text-muted para-desc mx-auto">
                {{'AccessDescription' | translate}}
            </p>
        </div>
    </div><!--end col-->
</div>