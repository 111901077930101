import { Subscription } from 'rxjs';
import { UserService } from './../../shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from './../../shared/services/storage.service';
import { User } from './../../shared/models/user';
import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  user: User;
  congressId: number;
  userId: number;
  filesUrl = environment.filesUrl;
  busy: Subscription;
  chatInfo: string [] = [];
  currentLanguage: string;

  constructor(private storageService: StorageService,
    private route: ActivatedRoute,
    private userService: UserService,
    private translate:TranslateService) {
    let user: User = this.storageService.read(StorageService.USER_KEY);
    this.userId = user.user_id;
    this.route.params.subscribe(params => {
      this.congressId = params['congressId'];
    });
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.currentLanguage = this.translate.currentLang;
  }

  firstLetters(user) {
    return Utils.getUserFirstLetters(user);
  }

  getUserDetails() {
    this.busy = this.userService.getResponseUserInformations(this.userId, this.congressId).subscribe((data: User) => {
      this.user = data;
      if (this.user?.user_congresses.length > 0 && this.user?.user_congresses[0]?.chat_info) {
        this.chatInfo = this.user?.user_congresses[0]?.chat_info.split(';');
      }
    });
  }

  redirectToEdit() {
    window.location.href = environment.backOfficeUrl + 'auth/user/' + this.user.user_id +'/manage-account/' + this.congressId + "?frontOffice=true";
  }

  getValueTranslate(item, colName = 'label') {
    return Utils.getValueTranslate(this.currentLanguage, item, colName);
  }
}
