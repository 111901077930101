<section>
  <div id="step1" class="form-inner" [ngBusy]="busy">
    <div class="steps-inner">
      <div [ngBusy]="busy" *ngIf="submissions.length !== 0">
        <div class="item-list submission"
          *ngFor="let submission of submissions | paginate: { itemsPerPage: 10, currentPage: p, id: id , totalItems: totalItems}; let i = index">
          <div class="submission-header">
            <div class="col-3 col-md-3 xs-3 submission-title">
              <div (mouseover)="showTooltip(submission.submission_id)" (mouseout)="hideTooltip()" style="display: inline-block; margin-left: 10px;">
                <span class="tooltip1" [ngStyle]="{'display': tooltipVisibleId === submission.submission_id ? 'block' : 'none'}">
                  <span class="tooltip-content" [innerHTML]="getTooltipContent(submission.submission_id)"></span>
                </span>
                <i class="fa fa-info"></i>
              </div>
              {{ isXs ? (submission?.title | slice:0:15) + '...' : (submission?.title.length > 20 ? (submission?.title | slice:0:25) + '...' : submission?.title) }}
            </div>
            <div class="span-status m-2" *ngIf="isAllSubmissions">
              <ng-container [ngSwitch]='submission.status'>
                <div class="row" *ngSwitchCase="0">
                  <div class="badge-eventizer badge-validated" style="background-color: #525252;">
                    <span class="submission-status-span">{{'SUBMISSION.SUBMISSIONS_IN_PENDING' | translate}}
                    </span>
                    <div aria-label="My icon" [src]="imgUrl + 'eTUptBHv6RSSPog2rAqxYHUcS9X60fsn3TvbVufO.svg'"
                      [setSVGAttributes]="{'width':'9px', 'height':'9px'}">
                    </div>
                  </div>
                  <div *ngIf="isAllSubmissions" aria-label="My icon" style="margin-left: 3px;cursor: pointer;"
                    (click)="deleteSubmission(submission.submission_id, i)"
                    [src]="imgUrl + ' ChMczVQUWhUHdNoGsd4fuSKNllpsnItwQige2Dkt.svg'"
                    [setSVGAttributes]="{'width':'15px', 'height':'15px'}"></div>
                </div>
                <div class="badge-eventizer badge-validated bg-success" *ngSwitchCase="1">
                  <span class="submission-status-span">
                    {{'SUBMISSION.SELECTED' | translate}}
                  </span>
                </div>
                <div class="badge-eventizer badge-validated" style="background-color: #2f55d4;" *ngSwitchCase="5">
                  <span class="submission-status-span">{{'SUBMISSION.FILES_SUBMITTED' | translate}}</span>
                  <div aria-label="My icon" [src]="imgUrl + 'mz06D2tU2WWqWRu0WpTmZAKW3DagJqMjDgrVzZiD.svg'"
                    [setSVGAttributes]="{'width':'9px', 'height':'9px'}"></div>
                </div>
                <div class="badge-eventizer badge-refused " *ngSwitchCase="3"><span
                    class="submission-status-span">{{'SUBMISSION.DECLINED' |
                    translate}}</span>
                  <div aria-label="My icon" [src]="imgUrl + 'QQx9AOym4tpYuhR7JzwgV1DQdnOADL8LtnII6KLx.svg'"
                    [setSVGAttributes]="{'width':'9px', 'height':'9px'}"></div>
                </div>
                <div class="badge-eventizer badge-cours" *ngSwitchCase="2"><span
                    class="submission-status-span">{{submission.globale_note==-1 ?
                    ('SUBMISSION.PROGRESSING' | translate) : ('SUBMISSION.Evaluating' | translate)}} </span>
                  <div aria-label="My icon" [src]="imgUrl + 'TaQZIjSbApgWVZtDdToftKqR6BlTqpdNlBoDx8QT.svg'"
                    [setSVGAttributes]="{'width':'9px', 'height':'9px'}"></div>
                </div>

                <div class="badge-eventizer badge-cours" *ngSwitchCase="4"><span
                    class="submission-status-span">{{'SUBMISSION.WATING_FILES' | translate}}
                  </span>
                  <div aria-label="My icon" [src]="imgUrl + 'TaQZIjSbApgWVZtDdToftKqR6BlTqpdNlBoDx8QT.svg'"
                    [setSVGAttributes]="{'width':'9px', 'height':'9px'}"></div>
                </div>
                <div class="badge-eventizer badge-cours" *ngSwitchCase="6">
                  <span class="submission-status-span">A reviser</span>
                  <div aria-label="My icon" [src]="'../../../../assets/icons/submission/icon_done.svg'"
                    [setSVGAttributes]="{'width':'9px', 'height':'18px'}"></div>
                </div>
              </ng-container>
            </div>
            <div class="image-status"
              *ngIf="(congress.config_submission.is_quiz_mandatory === 1) && (congress.config_submission.nb_questions > 0 && congress.config_submission.nb_responses > 0 ) &&( congress.config_submission.quiz_selection === 0)">
              <a *ngIf="submission.status === 4" [routerLink]="'../config-quiz/' + submission.submission_id"><img
                  style="float: right;" [style.height.px]="40" aria-label="My icon"
                  [src]="submission.quiz_abstract.length == 0 ?'assets/img/abstract-images/quiz-1.png' : 'assets/img/abstract-images/quiz-2.png'">
              </a>
            </div>
            <div class="image-status"
              *ngIf="(congress.config_submission.quiz_selection === 1 &&  submission.quiz_selection === 1 ) && (congress.config_submission.nb_questions > 0 && congress.config_submission.nb_responses > 0 )">
              <a *ngIf="submission.status === 4" [routerLink]="'../config-quiz/' + submission.submission_id"><img
                  style="float: right;" [style.height.px]="40" aria-label="My icon"
                  [src]="submission.quiz_abstract.length == 0 ?'assets/img/abstract-images/quiz-1.png' : 'assets/img/abstract-images/quiz-2.png'"></a>
            </div>
      <div class="image-status pt-3" [ngSwitch]='submission.status' (click)="openSubmission(submission)" style="margin-left: 10px;margin-right: 5px;">
        <input *ngSwitchCase="4" (change)="addFile($event, submission)" type="file" #fileInput style="display: none;"
        [accept]="getFileAcceptType(submission)">
        <input *ngSwitchCase="5" (change)="updateFileSoumisResource($event)" type="file" #fileInput5 style="display: none;"
              accept=".pdf">
              <label class="image-status" (click)="handleLabelClick(submission, submission.status)">
                <img *ngSwitchCase="0" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/edit-button.png">
                <img *ngSwitchCase="1" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/check.png">
                <img *ngSwitchCase="2" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/edit-button.png">
                <img *ngSwitchCase="3" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/edit-button.png">
                <img *ngSwitchCase="4" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/import-1.png">
                  <img *ngSwitchCase="5" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/import-2.png" title="{{'FileChange' | translate}}">
                <img *ngSwitchCase="6" style="float: right;" [style.height.px]="30" aria-label="My icon"
                  src="assets/img/abstract-images/edit-button.png">
              </label>         
            </div>
          </div>
        </div>
        <pagination-controls *ngIf="isAllSubmissions" id="{{id}}"
          (pageChange)="loadNextSubmissions($event,submissions[0].communication_type_id)"></pagination-controls>
      </div>
      <div *ngIf="submissions.length === 0" class="mt-2 ml-2">
        {{ "SUBMISSION.NO_SUBMISSION" | translate}} !      </div>

    </div>
  </div>
</section>
