<div [ngBusy]="busy">
<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/landingpage/{{congressId}}" class="btn btn-icon btn-primary">
    <i class="icon-home"></i>
  </a>
</div>
<section class="bg-home d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  [ngStyle]="{
    'background-image': configLandingPage?.waiting_banner!==null ?  'url(' +filesUrl + configLandingPage?.waiting_banner+ ')':'url( \'imgUrl + fXlgwbM1wrgcxMhXqnyeFdZlYBlFcByGloB7wQwC.jpg\')', 
    'background-color': configLandingPage?.waiting_color, 
    'opacity':configLandingPage?.waiting_opacity,
     'background-blend-mode': 'luminosity'
   }">
  <div class="bg-overlay staticBanner"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center aaa">
        <div class="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">{{getValueTranslate('waiting_title')}}
        </div>
        <p  [innerHTML]="transformHtml(getValueTranslate('waiting_desription'))" class="text-light para-desc para-dark mx-auto"></p>
      </div>
    </div>

     <div class="row">
        <div class="col-md-12 text-center">
          <div id="countdown">
            <div class="count-down"><span class="count-number">{{timer.daysToDday}}</span><span
                class="count-head position-relative d-block">{{'DAYS' | translate}}</span>
            </div>
            <div class="count-down"><span class="count-number">{{timer.hoursToDday}}</span><span
                class="count-head position-relative d-block">{{'HOURS' | translate}}</span>
            </div>
            <div class="count-down"><span class="count-number">{{timer.minutesToDday}}</span><span
                class="count-head position-relative d-block">{{'MIN' | translate}}</span>
            </div>
            <div class="count-down"><span class="count-number">{{timer.secondsToDday}}</span><span
                class="count-head position-relative d-block">{{'SEC' | translate}}</span>
            </div>
  
          </div>
        </div>
      </div> 
    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/landingpage/{{congressId}}" class="btn btn-primary mt-4 width p-2">
          {{'GoHome' | translate}}</a>
      </div>
    </div>
  </div>
</section>
</div>