import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GenericService } from "./generic.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class LandingPageService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getRquestLandingPageWithCongressId(congressId) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/request-landing-page/' + congressId + '/get';
    return this.http.get(url, { headers: headers });
  }

  getRequestLandingPageByDnsName(dns) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/request-landing-page/dns?nameDns=' + dns;
    return this.http.get(url, { headers: headers });
  }
}