import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  mapImage: string;
  @Input() eventLocation;
  @Input() configLandingPage: ConfigLandignPage;
  @Output() toRegister: EventEmitter<null> = new EventEmitter();
  @Input() lang: string;

  constructor() { }

  ngOnInit(): void {
    this.mapImage = Utils.getUrlMap(this.eventLocation?.lng, this.eventLocation?.lat, 520, 300);
  }

  redirectToRegister() {
    this.toRegister.emit();
  }

  getValueTranslate (columnName: string) {
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

}
