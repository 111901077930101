import {AfterViewInit, Component, DoCheck, EventEmitter, Input, IterableDiffers, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Utils} from '../../utils';

declare var jQuery: any;

@Component({
  selector: 'app-ng-select2',
  templateUrl: './ng-select2.component.html',
  styleUrls: ['./ng-select2.component.css']
})
export class NgSelect2Component implements OnInit, DoCheck, AfterViewInit {

  @Input() isSubmitted: boolean = false;
  @Input() defaultValue?: string;
  @Input() objects: any;
  @Input() stringOption: any;
  @Input() idOption: string;
  @Input() makeAll?: boolean;
  @Input() isSearch?: boolean;
  @Input() multiple?: boolean;
  @Input() required = false;
  @Input() submitted = false;
  @Input() placeHolder?: string;
  @Output() selectionChange: EventEmitter<any>;
  options: string[] = [];
  ids: any[] = [];
  selected: any; // un élement ou plusieurs (select multiple ou pas)
  className: string;
  iterableDiffer: any;
  selectedItems = [];

  constructor(private _iterableDiffers: IterableDiffers, private translate: TranslateService) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    this.selectionChange = new EventEmitter<any>();
    this.className = Utils.getRandomString();
  }

  @Input()
  get selection() {
    return this.selected;
  }

  placeholder() {
    if(this.placeHolder) {
      this.translate.get(this.placeHolder).subscribe(data => {
        jQuery('.select2-hidden-accessible').select2({
          placeholder: data
        });
      })
    }
  }

  set selection(value) {
    if (this.selected) {
      if (Array.isArray(value)) {
        if (value.join() !== this.selected.join()) {
          Utils.setValuesjQueryCmp(this.className, value, 50);
        }
      } else {
        if (value !== this.selected) {
          Utils.setValuesjQueryCmp(this.className, value, 50);
        }
      }
    } else {
      Utils.setValuesjQueryCmp(this.className, value, 200);
    }
    this.selected = value;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.placeholder();
    if (this.selected) {
      this.selectedItems = this.selected;
    }
    
  }

  changeSelectValues() {
    this.selectionChange.emit(this.selectedItems);
  }

  transformData() {
    const baseContext = this;
    if (baseContext.stringOption && baseContext.idOption) {
      if (baseContext.stringOption.constructor === String) {
        this.options = this.objects
          .map(object => {
            return object[baseContext.stringOption];
          });
      } else {
        baseContext.stringOption.forEach((s, index) => {
          const options = this.objects
            .map(object => {
              return object[s];
            });
          if (index === 0) {
            this.options = options;
          } else {
            this.options = options.map((object, i) => {
              return this.options[i] + ' ' + object;
            });
          }
        });
      }
      this.ids = this.objects
        .map(object => {
          return object[baseContext.idOption];
        });
    } else {
      this.options = this.objects;
      this.ids = this.objects;
    }
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.objects);
    if (changes) {
      this.transformData();
    }
  }


}


/*

<app-ng-select2 [defaultValue]="'Choisir un niveau'"
                                [idOption]="'id_level'" [stringOption]="'label'" [objects]="levels"
                                [(selection)]="sessionChoice.id_level"
                ></app-ng-select2>

 */
