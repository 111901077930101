import { UserService } from 'src/app/shared/services/user.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Congress } from '../../models/congress';
import { Meeting, UserMeeting } from '../../models/meeting';
import { CongressService } from '../../services/congress.service';
import { MeetingService } from '../../services/meeting.service';
import { Utils } from '../../utils';
import { formatDate } from '@angular/common';
import { groupBy } from "lodash-es";
import { MeetingDates, MeetingsGroupedByTime } from '../../models/MeetingDates';
import { User } from '../../models/user';

declare var jQuery: any;

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.css']
})
export class ScheduleMeetingComponent implements OnInit {
  modal_id: any;
  meeting: Meeting = new Meeting();
  user_meeting: UserMeeting = new UserMeeting();
  meetingModel: MeetingModel = new MeetingModel();
  congressAvailableDates = [];
  congessAvailableMeetingTimes = [];
  @Input() congressId: string;
  @Input() congress: Congress = new Congress();
  @Input() receiver_id: number;
  @Input() user_meeting_canceled: UserMeeting;
  @Input() meeting_id: number;
  @Input() AdminId: number;
  @Output() endReschedule: EventEmitter<boolean> = new EventEmitter();
  @Output() redirect: EventEmitter<boolean> = new EventEmitter();
  @Output() userIdRedirection: EventEmitter<number> = new EventEmitter();
  submitted: boolean = false;
  busy: Subscription;
  startHour: string;
  endHour: string;
  @Input() meetingsDate: MeetingDates[]=[];
  timesGroups: MeetingsGroupedByTime[]=[]
  eventsDay: string[]=[]
  meetingsTimes = [];
  userBusy: Subscription;
  @Input() user: User;
  userDates = [];
  userTimesGroups = [];
  userReady: boolean = false;
  disabledTime: boolean[] = [];
  dtaesReady: boolean;
  @Input() sendMeetingsDate: boolean = false;
  @Input() loggedUser;

  constructor(private translate: TranslateService, 
    private meetingService: MeetingService, 
    private route: ActivatedRoute, 
    private userService: UserService) {
    this.congressId = this.route.snapshot.paramMap.get('congressId');

  }

  ngOnInit(): void {
    this.startHour=formatDate(new Date(this.congress.start_date),'HH:mm','en-US') ;
    this.endHour=formatDate(new Date(this.congress.end_date),'HH:mm','en-US') ;
    this.congressAvailableDates=Utils.getAvailableDates(this.congress);
    this.congessAvailableMeetingTimes=Utils.getDurationTimes(this.congress.config.meeting_duration,this.congress.config.pause_duration,this.startHour,this.endHour);
   this.setModalId() ;
   if (this.user_meeting_canceled && this.meeting_id) {
      this.meeting.meeting_id = this.meeting_id;
    }
    this.getCongressDates();
    this.getMeetingDates(this.congress.congress_id);
    //this.getUserDetails();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getMeetingDates(this.congress.congress_id);
    }, 3000);
  }

  isEmpty() {
    return !this.meeting.start_date || !this.meeting.end_date;
  }

  checkDates() {
    return this.meeting.start_date>this.meeting.end_date;
  }
  
  send(e) {
    this.meetingModel.datetimeStart = Utils.concatenation(this.meetingModel.dateStart, this.meetingModel.timeStart);
    this.meeting.start_date = this.meetingModel.datetimeStart;
    this.meetingModel.datetimeEnd = Utils.concatenation(this.meetingModel.dateStart, this.meetingModel.timeEnd);
    this.meeting.end_date = this.meetingModel.datetimeEnd;
    this.submitted = true;
 
    if (this.isEmpty()) {
      Utils.swalMessage('SHARED.ERROR', 'HOME.FieldsError', 'warning', this.translate);
      return;
    }
    if (this.checkDates()) {
      Utils.swalMessage('SHARED.ERROR', 'MeetDatesCheck', 'warning', this.translate);
      return;
    }
    if (!this.dateValidation()) {
      Utils.swalMessage('MeetDatesCheck', 'Error', 'warning', this.translate);
      return;
    }
    jQuery('#sendRequest' + this.modal_id).prop('disabled', true);
    this.busy = this.meetingService.requestMeeting(this.meeting, this.receiver_id, this.congress.congress_id)
      .subscribe(data => {
        this.meeting = new Meeting();
        this.meetingModel = new MeetingModel();
        this.getMeetingDates(this.congress.congress_id);
        this.endReschedule.emit(true);
        this.redirect.emit(true);
        this.userIdRedirection.emit(this.receiver_id);
        jQuery('#' + this.modal_id).modal('hide');
        Utils.swalMessage('SHARED.SUCCESS', 'RequestMeet', 'success', this.translate);
      },
        error => {
          if (error.status === 401) {
            jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
            Utils.swalMessage('SWAL.ERROR', 'HasSameMeeting', 'warning', this.translate);
          } else if (error.status === 406) {
            Utils.swalMessage('SWAL.ERROR', 'InvalidDates', 'warning', this.translate);
          } else {
            Utils.swalMessage('SHARED.ERROR', 'Error', 'warning', this.translate);
          }
        });

  }

  getCongressDates() {
    this.meetingModel.congressAvailableDates = Utils.getAvailableDates(this.congress);
  }

  dateValidation() {
    return  this.congressAvailableDates.includes(this.meetingModel.dateStart);
  }
  

  setEndTime(e) {
    this.meetingModel.timeEnd = Utils.addDudation(this.meetingModel.timeStart, this.congress.config.meeting_duration);
  }

  setEndDate(e) {
    this.meetingModel.dateEnd = this.meetingModel.dateStart;
    if (this.meetingsDate.length > 0) {
      for (let time of this.meetingsTimes) {
        if (time.date == this.meetingModel.dateStart) {
          this.congessAvailableMeetingTimes = time.times;
          this.disabledTime = time.disabledTime;
          this.meetingModel.timeStart = null;
        }
      }
    }
  }
  
  setModalId() {
    if (this.AdminId) {
      this.modal_id = "Admin" + this.AdminId;
    }
    else
      this.modal_id = this.meeting_id == null ? this.receiver_id : this.meeting_id;
  }

  getMeetingDates(congress_id) {
    if (!this.sendMeetingsDate) {
      this.busy = this.meetingService.getMeetingDates(congress_id).subscribe((data: any) => {
        this.meetingsDate = data;
        this.calculateTimeslots();
      });
    } else {
      this.calculateTimeslots();
    }
  }

  calculateTimeslots() {
    this.dtaesReady = true;
    if (this.meetingsDate.length == 0) {
      // Get timeslots when no Meeting dates are configured
      for (let date of this.meetingModel.congressAvailableDates) {
        this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, this.startHour, this.endHour);
        this.meetingsTimes.push({
          date: date,
          times: this.meetingModel.congessAvailableMeetingTimes
        });
      }
    } else {
      this.meetingsDate.forEach(e => {
        e.startHour = formatDate(new Date(e.start_date), 'HH:mm', 'en-US')
        e.endHour = formatDate(new Date(e.end_date), 'HH:mm', 'en-US')
        e.sDate = formatDate(new Date(e.start_date), 'YYYY-MM-dd', 'en-US')
      });
      this.groupDates();
      let dateExist;
      let timeGroup;
      for (let i = 0; i < this.meetingModel.congressAvailableDates.length; i++) {
        dateExist = false;
        timeGroup = null;
        for (let j = 0; j < this.timesGroups.length; j++) {
          if (this.timesGroups[j].start_date == this.meetingModel.congressAvailableDates[i]) {
            dateExist = true;
            timeGroup = this.timesGroups[j];
            break;
          }
        }
        if (!dateExist) {
          // Get timeslots when no Meeting date is configured for this specific date
          this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, '00:00', '00:00');
          this.meetingsTimes.push({
            date: this.meetingModel.congressAvailableDates[i],
            times: this.meetingModel.congessAvailableMeetingTimes,
            disabledTime: this.setAllDisabledToTrue(this.meetingModel.congessAvailableMeetingTimes)
          });
        } else {
          // Get timeslots for an only one configured meeting date
          if (timeGroup.dates.length == 1) {
            this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, timeGroup.dates[0].startHour, timeGroup.dates[0].endHour);
            this.meetingsTimes.push({
              date: this.meetingModel.congressAvailableDates[i],
              times: this.meetingModel.congessAvailableMeetingTimes,
              disabledTime: this.setAllDisabledToTrue(this.meetingModel.congessAvailableMeetingTimes)
            });
          } else {
            // Get timeslots for multiple configured meeting dates
            let finalDates = [];
            let result = [];
            for (let i = 0; i < timeGroup.dates.length; i++) {
              finalDates[i] = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, timeGroup.dates[i].startHour, timeGroup.dates[i].endHour);
            }
            for (let date of finalDates) {
              result.push.apply(result, date);
            }
            this.meetingsTimes.push({
              date: this.meetingModel.congressAvailableDates[i],
              times: result,
              disabledTime: this.setAllDisabledToTrue(result)
            });
          }
        }
      }
    }
    this.getUserDetails();

  }

  getUserDetails() {
    if (!this.user) {
      this.userBusy = this.userService.getResponseUserInformations(this.receiver_id, this.congress.congress_id, false).subscribe((data: User) => {
        this.user = data;
        this.userReady = true;
        this.getUserReservedMeetings(this.user);
        setTimeout(() => {
          this.getFinalDatesResults();
        }, 3000);
      });
    } else {
      this.userReady = true;
      this.getUserReservedMeetings(this.user);
      setTimeout(() => {
        this.getFinalDatesResults();
      }, 3000);
    }
  }


  groupDates() {
    const baseContext = this;
    var grouped = groupBy(baseContext.meetingsDate,
      function (date) {
        return date.sDate ? date.sDate : null;
      });
    var dates = Object.keys(grouped);
    for (let i = 0; i < dates.length; i++) {
      this.timesGroups.push(
        {
          start_date: dates[i] != "null" ? dates[i] : null,
          dates: grouped[dates[i]]
        }
      );
    }
  }

  getUserReservedMeetings(user) {
    if (user.meetings_organizer.length > 0 || user.meetings_participant.length > 0) {
      this.userDates = user.meetings_organizer.concat(user.meetings_participant);
      if (this.loggedUser) {
        this.userDates = this.userDates.concat(this.loggedUser.meetings_participant);
        this.userDates = this.userDates.concat(this.loggedUser.meetings_organizer);
      }
      this.formtUserDates();
      this.groupUserDates();
    }
  }

  formtUserDates() {
    this.userDates.forEach(e => {
      e.startHour = formatDate(new Date(e.start_date), 'HH:mm', 'en-US')
      e.endHour = formatDate(new Date(e.end_date), 'HH:mm', 'en-US')
      e.sDate = formatDate(new Date(e.start_date), 'YYYY-MM-dd', 'en-US')
    });
  }

  groupUserDates() {
    var grouped = groupBy(this.userDates,
      function (date) {
        return date.sDate ? date.sDate : null;
      });
    var dates = Object.keys(grouped);
    for (let i = 0; i < dates.length; i++) {
      if (grouped[dates[i]].length == 1) {
        this.userTimesGroups.push(
          {
            date: dates[i] != "null" ? dates[i] : null,
            times: [grouped[dates[i]][0]['startHour']]
          });
      } else {
        let startHours = [];
        for (let date of grouped[dates[i]]) {
          startHours.push(date['startHour']);
        }
        this.userTimesGroups.push(
          {
            date: dates[i] != "null" ? dates[i] : null,
            times: startHours
          });
      }
    }
  }

  setAllDisabledToTrue(results) {
    let disabledTimes = []
    for (let result of results) {
      disabledTimes.push(false);
    }
    return disabledTimes;
  }

  getFinalDatesResults() {
    if (this.userReady && this.userTimesGroups.length > 0) {
      for (let time of this.meetingsTimes) {
        for (let userTime of this.userTimesGroups) {
          if (time.date == userTime.date) {
            for (let startTime of userTime.times) {
              const index = time.times.indexOf(startTime);
              if (index > -1) {
                time.disabledTime[index] = true;
              }
            }
          }
        }
      }
    }
  }

}

export class MeetingModel {
  dateStart: string = null;
  dateEnd: string = null;
  timeStart: string = null;
  timeEnd: string = null;
  datetimeStart: any = null;
  datetimeEnd: any = null;
  congressAvailableDates: any = [];
  congessAvailableMeetingTimes: any = [];
}
