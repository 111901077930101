import { User } from './../../shared/models/user';
import { StorageService } from './../../shared/services/storage.service';
import { Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/utils';
import { LandingPageService } from 'src/app/shared/services/landingPage.service';
import { RequestLandingPage } from 'src/app/shared/models/RequestLandinPage';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  busy: Subscription;
  @Input() configLandingPage: ConfigLandignPage;
  requestLandingPage : RequestLandingPage;
  filesUrl = environment.filesUrl;
  dateOfEvent: any[] = [];
  lang;
  @Output() toRegister: EventEmitter<null> = new EventEmitter();
  @Output() toAddSubmission: EventEmitter<null> = new EventEmitter();
  backgroundColor: string = null;
  opacity: number = null;
  screenWidth: number;
  isConnected: boolean = false ;
  home_banner_event: string;
  @ViewChild('eventTitle') eventTitle!: ElementRef;
  constructor(private _translate: TranslateService,
    private storageService: StorageService,
    private router: Router,
    private landingPageService : LandingPageService
    ) {
    this.lang = this._translate.currentLang;
    this._translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
      this.getHomeBackgroundImage();
    });
    this.screenWidth = window.screen.width;
    if (this.storageService.isExist(StorageService.USER_TOKEN_KEY)) {
    this.isConnected = true;
    } 
  }


  ngOnInit(): void {
    if (this.configLandingPage.show_date) { this.getDateBetweenTwoDate();}  
    this.getBackgroundColor();
    if (this.configLandingPage.home_btn_link && this.configLandingPage.home_btn_link.includes('{congressId}')) {
      this.configLandingPage.home_btn_link = this.configLandingPage.home_btn_link.replace('{congressId}', this.configLandingPage.congress_id.toString());
    }
    this.getRequestLandingPageConfig();
   this.getHomeBackgroundImage();
  }

  removeBackslashes(inputString: string): string {
    if (this.eventTitle) {
      // Récupérer l'élément <p> à l'aide de la référence eventTitle
      const eventTitleElement = this.eventTitle.nativeElement;
      // Récupérer le style complet depuis l'API (exemple)
      const apiStyle = inputString;
      // Supprimer les "\" de la chaîne de caractères
      const cleanedApiStyle = inputString.replace(/\\/g, '');
      // Appliquer le style propre depuis l'API
      eventTitleElement.innerHTML = cleanedApiStyle;
    }
    
    const cleanedString = inputString.replace(/\\/g, '');
    return cleanedString;
  }
  
  ngAfterViewInit() {
    const apiStyle = this.configLandingPage?.home_title;
    this.removeBackslashes(apiStyle);
  }

  getHomeBackgroundImage() {
    this.home_banner_event = this.getValueTranslate('home_banner_event');
  }
  getDateBetweenTwoDate() {
    this.dateOfEvent = Utils.getDateBetweenTwoDate(this.configLandingPage?.home_start_date, this.configLandingPage?.home_end_date);
  }

  redirectToRegister() {
    this.toRegister.emit();
  }

  getBackgroundColor() {
    if (this.configLandingPage.background_color && this.configLandingPage.opacity_color) {
      this.backgroundColor = this.configLandingPage.background_color;
      this.opacity = this.configLandingPage.opacity_color;
    }
  }

  getValueTranslate (columnName: string) {   
    return Utils.getValueTranslate(this.lang, this.configLandingPage, columnName);
  }

  redirectToAddSubmission() {
    this.toAddSubmission.emit();
  }

  toB2B() {
    if (!this.storageService.isExist(StorageService.USER_TOKEN_KEY)) {
      this.router.navigate(['/landingpage/' + this.configLandingPage.congress_id + '/login']);
    }else{
      var innerWidth = window.innerWidth;
      var user: User = this.storageService.read(StorageService.USER_KEY);
      if (innerWidth < 900 && (this.configLandingPage.redirect_to_pwa == true)) {
        if (this.requestLandingPage?.dns_pwa) {
          window.location.href = this.requestLandingPage?.dns_pwa + "#/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + this.storageService.read(StorageService.USER_TOKEN_KEY);
        }
        else {
          window.location.href = environment.pwaUrl + "/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + this.storageService.read(StorageService.USER_TOKEN_KEY);
        }
      }
      else if (this.requestLandingPage?.dns) {
        window.location.href = this.requestLandingPage?.dns + "#/room/" + this.configLandingPage.congress_id + '/event-room';
      }
      else {
        this.router.navigate(['/room/' + this.configLandingPage.congress_id + '/b2b/buttons']);
      }
    }
  }
  redirectToLink() {
    var innerWidth = window.innerWidth;
    var user: User = this.storageService.read(StorageService.USER_KEY);
    if (!this.storageService.isExist(StorageService.USER_TOKEN_KEY)) {
      this.router.navigate(['/landingpage/' + this.configLandingPage.congress_id + '/login']);
    } else if(this.storageService.isExist(StorageService.USER_TOKEN_KEY) &&  this.configLandingPage.congress_id == 420){
      var innerWidth = window.innerWidth;
      if (innerWidth < 900 && (this.configLandingPage.redirect_to_pwa == true)) {
        var user: User = this.storageService.read(StorageService.USER_KEY);
        window.location.href = environment.pwaUrl + "/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + this.storageService.read(StorageService.USER_TOKEN_KEY);
      } else {
        if ( this.configLandingPage.home_btn_link == null) {
          this.router.navigate(['/landingpage/' + this.configLandingPage.congress_id + '/login']);
        } else {
          window.open(this.configLandingPage.home_btn_link);
        }      }
    } else {
      if (this.configLandingPage.home_btn_link == null) {
        this.router.navigate(['/landingpage/' + this.configLandingPage.congress_id + '/login']);
      } else {
        window.open(this.configLandingPage.home_btn_link);
      }
    }
  }

  toEvent() {
    if (this.storageService.isExist(StorageService.USER_TOKEN_KEY)) {
      var innerWidth = window.innerWidth;
      var user: User = this.storageService.read(StorageService.USER_KEY);
      if (innerWidth < 900 && (this.configLandingPage.redirect_to_pwa == true)) {
        if (this.requestLandingPage?.dns_pwa) {
          window.location.href = this.requestLandingPage?.dns_pwa + "#/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + this.storageService.read(StorageService.USER_TOKEN_KEY);
        } else {
          window.location.href = environment.pwaUrl + "/auth/login" + "?congressId=" + this.configLandingPage.congress_id + "&user=" + user.email + "&token=" + this.storageService.read(StorageService.USER_TOKEN_KEY);
        }
      } else if (this.requestLandingPage?.dns) {
        window.location.href = this.requestLandingPage?.dns + "#/room/" + this.configLandingPage.congress_id + '/event-room';
      } else {
        this.router.navigate(['/room/' + this.configLandingPage.congress_id + '/event-room']);
      }
    }
  }

  getRequestLandingPageConfig() {
    this.busy =  this.landingPageService.getRquestLandingPageWithCongressId(this.configLandingPage.congress_id).subscribe((data: any) => {
      if (data.status != 0) {
        this.requestLandingPage = data;
      }
    });
  }

  toLive() {
    document.getElementById("live").scrollIntoView({ behavior: "smooth" });
  }
}
