import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FullLayoutComponent } from "./full-layout/full-layout.component";
import { CanActivateViaAuthGuard } from "./shared/services/guards/auth-guard.service";
import { SubmissionFormComponent } from "./submission/submission-form/submission-form.component";
import { SuccessPageComponent } from "./success-page/success-page.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sponsoring/sponsoring.module').then(m => m.SponsoringModule)
  },
  {
    path: "room",
    canActivate: [CanActivateViaAuthGuard],
    loadChildren: () => import("./room/room.module").then((m) => m.RoomModule),
  },
  {
    path: "abstracts",

    loadChildren: () =>
      import("./external-abstracts/external-abstracts.module").then(
        (m) => m.ExternalAbstractsModule
      ),
  },
  {
    path: "congress/:congressId/add",
    component: SubmissionFormComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "",
    component: FullLayoutComponent,
    children: [
      {
        path: "congress",
        loadChildren: () =>
          import("./congress/congress.module").then((m) => m.CongressModule),
      },
    ],
  },
  {
    path: "",
    loadChildren: () =>
      import("./landing-page/landing-page-event.module").then(
        (m) => m.LandingPageEventModule
      ),
  },
  {
    path: "success-page",
    component: SuccessPageComponent,
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
