<div class="sub" >
  <h2 class="main-title"> {{options.name|translate}} </h2>
  <input *ngIf="options.search" class="search-bar form-control"  [(ngModel)]="search" type="text" placeholder="{{'HOME.SEARCH'|translate}}" (keyup.enter)="searchData()">

</div>
<div class="form-group" *ngIf="options.filter.length" >
  <h4>{{'SHARED.FILTERS'|translate}}</h4>
  <div class="row">
    <div class="col-4" *ngFor="let op of options.filter" >
      <label>{{op.name|translate}}</label>
      <input *ngIf="op.type === 'date'" type="date" class="form-control" [(ngModel)]="op.date">
      <input *ngIf="op.type === 'number'" type="number" class="form-control" [(ngModel)]="op.price">
      <input *ngIf="op.type === 'text'" type="text" class="form-control" [(ngModel)]="op.congress_name">
      <select *ngIf="op.type === 'select'" class="form-control" [(ngModel)]="op.selected_label">
        <option [value]="o.value" *ngFor="let o of op.values">{{o.label|translate}}</option>
      </select>
    </div>
  </div>
  <button class="btn  btn-green mt-3 pull-right filter-button" (click)="filterData()">{{'SHARED.FILTER'|translate}}
    <img aria-label="atelier"
          [src]="imgUrl+'jxkTO0EiuknzlsofgNyiQFsOEWDNiZMGiWFykks3.svg'"
          [ngStyle]="{'width':'16px', 'height':'16px'}"
    ></button>
</div>
<br/>
<br/>
<br/>
<br/>
