<section class="section border-top" *ngIf="configLandingPage?.prp_title || configLandingPage?.prp_title_en" id="about" >
  <div class="container">
    <div class="card rounded shadow border-0 bg-light overflow-hidden">
      <div class="row no-gutters align-items-center">
        <div class="col-lg-6">
          <img 
            [src]="prp_banner_event === null && prp_banner_event_en === null && prp_banner_event_ar === null ? 'imgUrl + oIR6OK8OHYJdQrjPa1zpW2DBIn5ZE08pSiV0WMQ0.jpg ' : filesUrl + getValueTranslate('prp_banner_event')"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-6">
          <div class="card-body section-title p-md-5">

            <h4 class="title mb-4">{{getValueTranslate('prp_title')}}</h4>
            <div [innerHTML]="transformHtml(getValueTranslate('prp_description'))"></div>

                <div class="mt-4 mr-1 pt-2" *ngIf="configLandingPage?.is_inscription===1">
                  <a (click)="redirectToRegister()" class="btn btn-outline-primary">
                    {{ configLandingPage.prp_btn_text === null ? ('HOME.SIGN UP'|translate) : getValueTranslate('prp_btn_text')}}
                    <i class="mdi mdi-chevron-right"></i></a>
                </div>
                </div>
        </div>
      </div>
    </div>
  </div>
</section>