import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListSubmissionsComponent} from './list-submissions/list-submissions.component';
import {DetailSubmissionComponent} from './detail-submission/detail-submission.component';
import { SubmissionFormComponent } from './submission-form/submission-form.component';
import { CanActivateViaAuthGuard } from '../shared/services/guards/auth-guard.service';

export const routes: Routes = [
  {
    path: 'congress/:congressId/add',
    component: SubmissionFormComponent,
    canActivate : [CanActivateViaAuthGuard]
  },
  {
    path: 'list-my-submissions',
    component: ListSubmissionsComponent,
    canActivate : [CanActivateViaAuthGuard]
  },
  {
    path: 'details/:submission_id',
    component: DetailSubmissionComponent
  },
  {
    path: 'edit/:submission_id',
    component: SubmissionFormComponent,
    canActivate : [CanActivateViaAuthGuard]
  },
  {
    path: 'submit-resources/:submission_id',
    component: SubmissionFormComponent,
    canActivate : [CanActivateViaAuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubmissionRoutingModule {
}
