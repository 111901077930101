import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Congress } from 'src/app/shared/models/congress';
import { AccessService } from 'src/app/shared/services/access.service';
import { CongressService } from 'src/app/shared/services/congress.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/models/user';
import { Subscription } from 'rxjs';
import { Form } from 'src/app/shared/models/form';
import { Gender } from 'src/app/shared/models/Gender.model';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/shared/utils';
import { Access } from 'src/app/shared/models/Access';
declare let swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-register-access',
  templateUrl: './register-access.component.html',
  styleUrls: ['./register-access.component.css']
})
export class RegisterAccessComponent implements OnInit, AfterViewInit {
  user: User = new User();
  congressId: number;
  congress: Congress = new Congress();
  lang = { lang: "FR", lg: "fr" };
  busy: Subscription;
  accesses: any = [];
  formId: number;
  form: Form = new Form();
  filesUrl = environment.filesUrl;
  congressReady: boolean = false;
  userId: number;
  userCode: string;
  email: string
  backgroundImageUrl: string;
  currentLang: string;
  selectedAccessIds: any = [];
  @ViewChild('message') message!: ElementRef;
  accessIdsAndDates = [];

  constructor(private route: ActivatedRoute,
    private congressService: CongressService,
    private accessService: AccessService,
    private storageService: StorageService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router) {
    this.route.queryParams.subscribe(params => {
      let formToken = params['Form_Token'];
      if (formToken) {
        this.formId = formToken.slice(0, formToken.indexOf('_'));
      }
      this.congressId = params['congressId'];
      this.userId = params['userId'];
      this.userCode = params['userCode'];
    });
    this.setBackgroundImage();

  }

  async ngOnInit(): Promise<void> {

    this.congressId = parseInt(this.route.snapshot.paramMap.get('id_congress'), 0);
    this.email = this.route.snapshot.queryParamMap.get('email');

    if (this.email) {
      this.userService.getUserByEmailPublic(this.email).subscribe(data => {
        this.user = data;
        this.user.accessIds = []
        this.storageService.write(StorageService.USER_KEY, data);
      });
    }

    await this.getFormById();
    await this.getCongressById();

    // await this.getAccesses();
    this.translate.use(this.lang.lg);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });

  }
  ngAfterViewInit(): void {
    this.getAccesses();
  }

  getValueTranslate(item, colName) {
    return Utils.getValueTranslate(this.lang.lang, item, colName);
  }

  setBackgroundImage() {
    const pixelRatio = window.devicePixelRatio || 1;
    this.backgroundImageUrl = pixelRatio > 1.5 ? "../../../assets/images/trimba/bg-dark.jpg" : "../../../assets/images/trimba/bg.jpg";
  }

  getCongressById(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.congressService.getMinCongressPublic(this.congressId).subscribe((data: Congress) => {
        this.congress = data;
        this.congressReady = true;
        if (this.congress.config.default_lang != null && this.congress.config.default_lang != this.lang.lang) {
          this.lang.lang = this.congress.config.default_lang;
          this.lang.lg = this.congress.config.default_lang.toLowerCase();
        }

        resolve();
      });
    });
  }

  getFormById(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.congressService.getFormById(this.formId).subscribe((data: Form) => {
        this.form = data;
        this.user.privilege_id = this.form.privilege_id;
        resolve();
      });
    });
  }

  getAccesses(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.busy = this.accessService.getAllAccessesForRegister(this.congressId).subscribe((data: any) => {
        this.accesses = Object.entries(data);
        resolve();
      });
    });
  }

  getAccesseTime(accesseDate) {
    return Utils.getTimeFromDate(accesseDate);
  }

  displayDate(date: string) {
    if (!date) {
      return "";
    }
    return Utils.formatDateDDMMYYYY(date);
  }

  selectAccess(access_id, start_date, price) {
    let accessAtSameDate = this.selectedAccessIds.filter(access => (access.start_date == start_date && access.access_id != access_id));
    if (accessAtSameDate.length > 0) {
      this.user.price = this.user.price - accessAtSameDate[0].price;
    }
    this.accessIdsAndDates = this.accessIdsAndDates.filter(access => access.start_date !== start_date);
    this.accessIdsAndDates.push({ access_id, start_date, price });
  }

  addAccessToUser(access, i) {
    if (access && access.max_places && (access.max_places <= access.participant_count || access.max_places <= access.nb_participants)) {
      return -1;
    }
    if (this.user.accessIds.indexOf(access.access_id) == -1) {
      jQuery("#access" + access.access_id).prop('checked', true);
    } else {
      jQuery('input[name="accessObject' + i + '"]').prop('checked', false)
      this.accessIdsAndDates = this.accessIdsAndDates.filter(selectedAccess => selectedAccess.access_id !== access.access_id);
    }
    const index = this.user.accessIds.indexOf(access.access_id);
    if (index == -1) {
      this.selectAccess(access.access_id, access.start_date, access.price);
    }
    if (this.accessIdsAndDates.length > this.congress.config.max_access_selection){
      this.translate.get('MaccAccess', { value:  this.congress.config.max_access_selection }).subscribe((res: string) => {
        swal({
          title: 'Attention!',
          text: res,
          icon: 'warning',
          type: 'warning'
        });
      });
      jQuery('input[name="accessObject' + i + '"]').prop('checked', false)
      this.accessIdsAndDates.pop();
      return
    }  
    this.user.accessIds = this.accessIdsAndDates.map(access => access.access_id);
  }

  isSelected(access: Access) {
    if (access && access.max_places && (access.max_places <= access.participant_count || access.max_places <= access.nb_participants)) {
      return -1;
    }
    if (access.access_id) {
      return this.user.accessIds.indexOf(access.access_id) !== -1 ? 1 : 0;
    }
  }

  addUserAccess(){
    this.busy = this.accessService.addUserAccess(this.congressId, this.user.user_id, this.user.accessIds).subscribe({
      next: (data: any) => {
        Utils.swalMessage('SHARED.SUCCESS', 'SuccessOperation', 'success', this.translate);
        this.router.navigate(['landingpage/' + this.user.user_id + '/thank-you-access-page/' + this.congressId]);
      },
      error: error => {
        if (error.status === 404) {
          Utils.swalMessage('SWAL.ERROR', 'UserNotFound', 'warning', this.translate);
        }
        else if (error.status === 403) {
          Utils.swalMessage('SWAL.ERROR', 'AlreadySelectedAccess', 'warning', this.translate);
        } else {
          Utils.swalMessage('SWAL.ERROR', 'ErrorAdding', 'error', this.translate);
        }
      }
    });
  }


}
