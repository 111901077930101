<!-- Event snippet for Form Submission conversion page -->
<script>
    gtag('event', 'conversion', { 'send_to': 'AW-11127448695/eQCoCPj56JMYEPfI_bkp' });
</script>
<div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
    <div class="col-12 text-start">
        <a href="#" (click)="goBack()" class="btn btn-outline-primary btn-icon w-32px h-32px rounded-pill ms-3">
            <i class="fas fa-arrow-left"></i>
        </a>
    </div>
</div>
<div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center" [ngBusy]="busy">
    <div class="col-12 text-center">
        <div class="section-title">
            <img src="assets/images/succes.png" width="150">
            <h4 *ngIf="congress?.title_thank_page == null " class="title mb-4">{{'Thanks' | translate}}</h4>
            <div *ngIf="congress?.title_thank_page !== null" class="title mb-4">
                <div class="title" *ngIf="lang === 'fr'" [innerHTML]="congress?.title_thank_page" #eventTitle
                    [style.color]="getColorFromStyle(congress?.title_thank_page)"></div>
                <div class="title" *ngIf="lang === 'en'" [innerHTML]="congress?.title_thank_page_ar" #eventTitle
                    [style.color]="getColorFromStyle(congress?.title_thank_page_ar)"></div>
                <div class="title" *ngIf="lang === 'ar'" [innerHTML]="congress?.title_thank_page_en" #eventTitle
                    [style.color]="getColorFromStyle(congress?.title_thank_page_en)"></div>
            </div>
            <p *ngIf="congress?.thank_type_description == null && congress?.thank_type_description_ar == null && congress?.thank_type_description_en == null"
                class="text-muted para-desc mx-auto">
                {{'ThankYouForInscription' | translate}}
            </p>
            <p *ngIf="congressWithConfig?.congress_type_id === 1 && congress?.thank_type_description == null && congress?.thank_type_description_ar == null && congress?.thank_type_description_en == null"
                class="text-muted para-desc mx-auto">
                {{'InscriptionPaiement' | translate}}
            </p>
            <div *ngIf="congress?.thank_type_description !== null || congress?.thank_type_description_ar !== null || congress?.thank_type_description_en !== null"
                class="text-muted para-desc mx-auto">
                <div class="description" *ngIf="lang === 'fr'" [innerHTML]="congress?.thank_type_description"
                    #eventTitle [style.color]="getColorFromStyle(congress?.thank_type_description)"></div>
                <div class="description" *ngIf="lang === 'en'" [innerHTML]="congress?.thank_type_description_en"
                    #eventTitle [style.color]="getColorFromStyle(congress?.thank_type_description_en)"></div>
                <div class="description" *ngIf="lang === 'ar'" [innerHTML]="congress?.thank_type_description_ar"
                    #eventTitle [style.color]="getColorFromStyle(congress?.thank_type_description_ar)"></div>
            </div>
            <button *ngIf="congressWithConfig?.congress_type_id != 2 && price > 0" class="btn btn-primary mt-4" (click)="redirectToPayment()">
                {{'Paiement' | translate}}
            </button>
        </div>
    </div><!--end col-->
</div>