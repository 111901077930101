import {Component, OnInit, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {Author, Submission} from '../../shared/models/submission';
import {CongressService} from '../../shared/services/congress.service';
import {trigger} from '@angular/animations';
import {fadeIn, fadeOut} from '../../shared/animations/fade-animations';
import {environment} from '../../../environments/environment';
import {SubmissionService} from '../../shared/services/submission.service';
import {User} from '../../shared/models/user';
import {UserService} from '../../shared/services/user.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Congress } from 'src/app/shared/models/congress';
import { Utils } from 'src/app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

declare let jQuery;
declare let swal;


@Component({
  selector: 'app-list-submissions',
  animations: [
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn())
  ],
  templateUrl: './list-submissions.component.html',
  styleUrls: ['./list-submissions.component.scss']
})
export class ListSubmissionsComponent implements OnInit {

  moment = moment;
  busy: Subscription;
  submissions$: Submission[];
  congresses: Congress[] = [];
  today = new Date();
  congress = new Congress();
  selectedCongress = new Congress();
  numbers: number[];
  submission: Submission = new Submission();
  perPage = 10;
  offset = 0;
  notEmptyPost = true;
  notscrolly = true;
  status: number = null ;
  statusToShow = '' ;
  statusValue = Utils.getSubmissionStatus();
  search: boolean;
  keyword = '';
  page = 0;
  submission_id;
  isSubmissions = false;
  totalItems:number;
  congressId:any;
  constructor(private congressService: CongressService,
              private submissionService: SubmissionService,
              private userService: UserService,
              private translate: TranslateService,
              private spinner: NgxSpinnerService,public router: Router, ) {
  }

  ngOnInit() {
    this.selectedCongress.congress_id = null ;
    this.getAllSubmissions();
    this.initSelect();
    this.getCongress();
  }
  private initSelect() {
    const baseContext = this;
    Utils.initSelect('select-congress');
    jQuery('.select-congress').on('select2:select', function (e) {
      baseContext.selectedCongress.name = e.params.data.text ;
      baseContext.selectedCongress.congress_id = e.params.data.id;
  });
}
  
  filterByCongress(congress_name, congress_id?) {
    this.selectedCongress.name = congress_name ;
     this.selectedCongress.congress_id = congress_id;
  }
  getAllSubmissions() {
    this.spinner.show();
    
    this.busy = this.submissionService.getSubmissionUserById(
      this.offset,
      this.perPage,
      this.keyword,
      this.congressId,
      this.status
      ).subscribe(
      (data) => {
        this.submissions$ = data.data;
         this.totalItems = data.total;
         if(this.totalItems == 0 && !this.status)
         this.router.navigate(['/room/'+ this.congressId +'/submission/add']);
          for (const submission of this.submissions$ ) {
          submission.author = this.userService.loggedUser ? this.userService.loggedUser : new User() ;
          submission.authorNames = '';
          if (submission.resources.length > 0) {
          submission.fileType = submission.resources[0].path.split('.').pop();
          }
          for ( const author of submission.authors) {
            submission.authorNames += author.first_name + ' ' + author.last_name + ' , ';
            if (author.rank === 2 ) {
              submission.author = author;
            }
          }
        }
        this.getSubmissionsCongress();
        this.spinner.hide();
        this.isSubmissions = true;
      }
    );
  }

  getSubmissionsCongress() {
    this.congressService.getMinCongressData().subscribe((data: Congress[]) => {
      this.congresses = data ;
              this.congress.congress_id = null ;
        this.translate.get('CONGRESS.All').subscribe((res) => {
          this.congress.name = res;
        });
        this.congresses.splice(0 , 0, this.congress);
    });
  }
  onSearch(value) {
   this.keyword = value;
   this.offset = 0 ;
   this.notEmptyPost = true;
   this.notscrolly = true;
   this.getAllSubmissions();
  }

  canEdit(submission: Submission) {

    // return new Date(submission.congress.endDateSubmission) >= new Date();
  }
  selectStatus(status)  {
    this.status = status.value;
    this.translate.get(status.key).subscribe((res: string) => {
      this.statusToShow = res;
    });
this.filter();
  }
  deleteSubmission(index: number) {
    const baseContext = this;
    const submission: Submission = this.submissions$[index];

    if (submission.status) {
      Utils.swalMessage('SWAL.ATTENTION', 'SWAL.SUBNONDETELED', 'warning',  this.translate);
      return;
    }

    const params = {
      buttons: true,
      dangerMode: true
    }
    Utils.swalMessageWithCallback('SWAL.Sure', 'SWAL.OnceDeleted', 'warning', params, this.translate, function(isConfirm) {
      if (isConfirm) {
          baseContext.busy = baseContext.congressService.deleteSubmission(submission.submission_id).subscribe(data => {
            baseContext.submissions$.splice(index, 1);
            Utils.swalMessage('SWAL.DELETED', 'SWAL.SUBDETELED', 'success',  baseContext.translate);
          }, error => {
            Utils.swalMessage('SWAL.NONDELETED', 'SWAL.SUBNONDETELED', 'warning',  baseContext.translate);
          });
        }
      }
    );

  }

  filter() {
      this.offset = 0;
      this.getAllSubmissions();
    }

  getpages(e) {
    this.offset = e.page;
    this.getAllSubmissions();
  }
  getCongress() {
    this.busy = this.congressService.getCongressDetailsById(this.congressId).subscribe(data => {
      this.congress = data;
    })
  }
}
