import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigLandignPage } from 'src/app/shared/models/ConfigLandingPage';
import { CongressService } from 'src/app/shared/services/congress.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
declare var swal: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  RequestConfirmResetForm: FormGroup;
  userId = null;
  verification_code = '';
  busy: Subscription;
  congress_id;
  configLandingPage: ConfigLandignPage;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl; 
  selectedLang:string="fr";
  constructor(private userService: UserService,private congressService: CongressService,private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService) {
      const lang = this.storageService.read<string>('lang');
      this.selectedLang = lang ? lang : 'fr';
      this.route.params.subscribe(params => {
      this.congress_id = params['id_congress'];
    }); }

  ngOnInit(): void {
    this.getConfigLandingPage();
    this.route.queryParams
    .subscribe(params => {
      this.userId = params.user_id;
      this.verification_code = params.verification_code;
   this.getUserById(this.verification_code, this.userId); });
  this.RequestConfirmResetForm = new FormGroup({
    'email': new FormControl(null, [Validators.required, Validators.email]),
    'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
    'confirm-password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
  }, );
  }
  getUserById(verification_code: string, userId: number) {
    this.busy = this.userService.getUserById(userId, verification_code).subscribe(data => {
      this.RequestConfirmResetForm.value['email'] = data['email'];
      this.RequestConfirmResetForm.patchValue({
        email: data['email'],

      });
    }, error => {
      console.log(error)
      Utils.swalMessage('SWAL.ERROR', 'SWAL.FORMINVALID', 'error',  this.translate);
     this.router.navigate(['/']);
    });
  }
  RequestConfirmResetUser() {
    if (this.RequestConfirmResetForm.valid) {
      if (this.RequestConfirmResetForm.value['password'] === this.RequestConfirmResetForm.value['confirm-password']) {
        this.busy = this.userService.resetPassword(this.userId , this.verification_code, this.RequestConfirmResetForm.value['password']).
        subscribe(data => {
         Utils.swalMessage('SWAL.SUCCESS', 'SWAL.PwdReset', 'success',  this.translate);
          this.router.navigate(['/landingpage/'+this.congress_id+'/login']);
        }, error => {
          Utils.swalMessage('SWAL.ERROR', 'SWAL.PWERROR', 'error',  this.translate);
        });
      } else {
        Utils.swalMessage('SWAL.ERROR', 'SWAL.PWCHECK', 'error',  this.translate);
      }

    } else {
      Utils.swalMessage('SWAL.ERROR', 'SWAL.FORMINVALID', 'error',  this.translate);
    }
  }
  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congress_id).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
      }
    });
  }
}
