<div *ngIf="!multiple" class="mb-3">
  <select class="generic-select boder-black" [(ngModel)]="selectedItems" (change)="changeSelectValues()"
    [ngClass]="{'invalid': required && submitted && selectedItems.length == 0 }">
    <option *ngFor="let option of options;let i=index" value="{{ids[i]}}">
      {{option | translate}}
    </option>
  </select>
  <span *ngIf="isSubmitted &&(!selected || selected.length==0)" class="validation-error-label ">{{'required' |
    translate}}</span>
</div>

<div *ngIf="multiple" class="mb-2">
  <ng-select [multiple]="true" [(ngModel)]="selectedItems" (change)="changeSelectValues()" class="boder-black"
  [ngClass]="{'select-height': selectedItems.length == 0,
    'invalid': required && submitted && selectedItems.length == 0}">
    <ng-option *ngFor="let option of options;let i=index" [value]="ids[i]">
      {{option | translate}}
    </ng-option>
  </ng-select>
  <span *ngIf="isSubmitted &&(!selected || selected.length==0)" class="validation-error-label ">{{'required' |
    translate}}</span>
</div>