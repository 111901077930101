<section class="section" *ngIf="configLandingPage?.contact_title || configLandingPage?.contact_title_en" id="contact">
  <div  class="container">
    <div class="card bg-light rounded border-0 overflow-hidden">
      <div class="row no-gutters align-items-center">
        <div class="col-md-6 mt-2 mt-sm-0 order-2 order-md-1">
          <div class="card-body section-title p-lg-5">
            <h2 class="mb-4 font-weight-normal" [ngClass]="{'rtl': dirMode === 'rtl'}"> {{getValueTranslate('contact_title')}}</h2>
            <h3 class="mb-4 font-weight-normal" [ngClass]="{'rtl': dirMode === 'rtl'}"> {{getValueTranslate('contact_description')}}</h3>
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group position-relative rtl" [ngClass]="{'rtl': dirMode === 'rtl'}">
                    <label>{{'HOME.EMAIL'|translate}}</label>
                    <input name="name" type="text" class="form-control pl-5">
                  </div>
                </div>

              </div>
                <div class="mt-4 pt-2" *ngIf="configLandingPage.is_inscription===1">
                  <a (click)="redirectToRegister()" class="btn btn-outline-primary">{{'HOME.SIGN UP'|translate}} <i
                      class="mdi mdi-chevron-right"></i></a>
                </div>
            </form>
          </div>
        </div>
        <div class="col-md-I order-md-4">
          <img src="{{mapImage}}" id="map">
        </div>
      </div>
    </div>
  </div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
