<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()"
[ngClass]="{'nav-sticky': configLandingPage?.is_background_white == 1}">
  <div class="container-fluid">
    <div>
      <a class="logo" href="javascript:">
        <img [src]="configLandingPage?.header_logo_event=== null ? '': filesUrl+configLandingPage?.header_logo_event" class="l-dark" height="24" alt="" >
        <img [src]="configLandingPage?.header_logo_event=== null ? '': filesUrl+configLandingPage?.header_logo_event" class="l-light" height="24" alt="" >
      </a>
    </div>
  <div class=" register-btn">
   <div class="buy-button" *ngIf="configLandingPage?.is_inscription===1">
      <ng-template [ngIf]="navClass === 'nav-light'">
        <a (click)="redirectToRegister()" class="btn btn-primary login-btn-primary">{{'HOME.SIGN UP'|translate}}</a>
      </ng-template>
    </div>
  </div>
  </div>
  <div id="navigation">
    <ul class="navigation-menu" style="flex-wrap: inherit;">
      <li class="has-submenu" *ngIf="configLandingPage?.home_sub_title || configLandingPage?.home_sub_title_en  || configLandingPage?.home_sub_title_ar">
        <a (click)="toElement('home')"> {{getValueTranslate('home_sub_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.live_title || configLandingPage?.live_title_en || configLandingPage?.live_title_ar">
        <a  (click)="toElement('live')"> {{getValueTranslate('live_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.prp_title || configLandingPage?.prp_title_en || configLandingPage?.prp_title_ar">
        <a  (click)="toElement('about')"> {{getValueTranslate('prp_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.organizers_title || configLandingPage?.organizers_title_en || configLandingPage?.organizers_title_ar">
        <a (click)="toElement('organizers')"> {{getValueTranslate('organizers_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.speaker_title || configLandingPage?.speaker_title_en || configLandingPage?.speaker_title_ar">
        <a (click)="toElement('speakers')" > {{getValueTranslate('speaker_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.partners_title || configLandingPage?.partners_title_en || configLandingPage?.partners_title_ar">
        <a (click)="toElement('partners')"> {{getValueTranslate('partners_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.specific_bnr_title || configLandingPage?.specific_bnr_title_en || configLandingPage?.specific_bnr_title_ar">
        <a (click)="toElement('specificBanner')"> {{getValueTranslate('specific_bnr_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.specific_bnr_two_title || configLandingPage?.specific_bnr_two_title_en || configLandingPage?.specific_bnr_two_title_ar || configLandingPage?.specific_bnr_two || configLandingPage?.specific_bnr_two_ar">
        <a (click)="toElement('specificBannerTwo')"> {{getValueTranslate('specific_bnr_two_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.sponsor_title || configLandingPage?.sponsor_title_en || configLandingPage?.sponsor_title_ar">
        <a (click)="toElement('sponsors')"> {{getValueTranslate('sponsor_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.sponsor_pack_title || configLandingPage?.sponsor_pack_title_en || configLandingPage?.sponsor_pack_title_ar">
        <a (click)="toElement('sponsorPacks')"> {{getValueTranslate('sponsor_pack_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.prg_title || configLandingPage?.prg_title_en || configLandingPage?.prg_title_ar">
        <a (click)="toElement('programme')"> {{getValueTranslate('prg_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.companies_title || configLandingPage?.companies_title_en || configLandingPage?.companies_title_ar">
        <a (click)="toElement('companies')"> {{getValueTranslate('companies_title')}} </a>
      </li>
      <li class="has-submenu" *ngIf="configLandingPage?.contact_title">
        <a (click)="toElement('contact')"> {{getValueTranslate('contact_title')}} </a>
      </li>
      
      <li class="has-submenu langage-menu">
				<app-header-languages [isCondensed]="isCondensed" (menuCondesned)="menuCondesned($event)" (languageChanged)="changeLang($event)"></app-header-languages>
			</li>
      <li class="has-submenu mobile-register">
        <a (click)="redirectToRegister()"> {{'HOME.SIGN UP'|translate}} </a>
      </li>
      <!-- <div class="mt-2 ml-4" *ngIf="configLandingPage?.is_inscription===1">
        <a routerLink="/landingpage/{{configLandingPage?.congress_id}}/login" class="btn btn-success mt-2 mr-2">{{'HOME.LOGIN'|translate}}</a>
      </div> -->
    </ul>
  </div>

  <div class="menu-extras mr-4">
		<div class="menu-item">
			<a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
				<div class="lines">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</a>
		</div>
	</div>
</header>
