import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {StorageService} from './storage.service';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { Access } from '../models/Access';


@Injectable({
  providedIn: 'root'
})
export class AccessService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAccessById(accessId: number) {
    const url = environment.baseUrl + '/access/get/' + accessId;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
  getAllAccessByCongress(congressId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/access/congress/" + congressId;
    return this.http.get(url, {
      headers: headers
    });
  }

  getUserAccessesByCongressId(congressId: number) {
    const url = environment.baseUrl + '/access/congress/' + congressId + '/user';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  changeUserAccessPresenceLeftTime(userPresenceRequestBody: any, userId) {
    const url = environment.baseUrl + '/user/access/presence/' + userId;
    const formData  = new FormData();
    formData.append('isPresent', userPresenceRequestBody.isPresent.toString());
    formData.append('accessId', userPresenceRequestBody.accessId.toString());
    formData.append('congressId', userPresenceRequestBody.congressId);
    formData.append('type', userPresenceRequestBody.type.toString());
    navigator.sendBeacon(url, formData);
  }


  getAllAcces(congress_id:number,search: string, offset: number, page: number, perPage: number ,date: string, startTime: string,endTime:string,isOnline:number, myAccesses = 0) {
    const url = environment.baseUrl + `/access/paginantion/congress/`+congress_id+`?offset=${offset}&page=${page}&perPage=${perPage}&search=${search}&date=${date}&startTime=${startTime}&endTime=${endTime}&isOnline=${isOnline}&myAccesses=${myAccesses}`;
    return this.http.get<Access[]>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllAccessesForRegister(congressId: number) {
    const url = environment.baseUrl + "/access/" + congressId + '/register-accesses';
    return this.http.get(url);
  }

  getAttestationEventPreview(congress_id) {
    const url = environment.baseUrl + "/attestation/" + congress_id + "/get-attestation-event-preview"
    return this.http.get(url, {});
  }

  generateAttestationEventPreview(congress_id, submissionParams) {
    const url = environment.baseUrl + "/attestation/" + congress_id + "/generate-attestation-event-preview"
    return this.http.post(url, submissionParams, {responseType: 'blob'});
  }

  addUserAccess(congressId, userId, accessIds) {
    const url = environment.baseUrl + '/users/add-user-access/';
    const body = {congressId:congressId, userId:userId, accessIds:accessIds}
    return this.http.post(url, body);
  }
}
