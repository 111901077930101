import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '../../utils';

declare var jQuery: any;

@Component({
  selector: 'app-ng-datepicker',
  templateUrl: './ng-datepicker.component.html',
  styleUrls: ['./ng-datepicker.component.css']
})
export class NgDatepickerComponent implements OnInit, AfterViewInit {
  className: string;
  @Input() withTime: boolean;
  @Output() selectionChange: EventEmitter<any>;
  selected: any;

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    const baseContext = this;
    baseContext.selected = value;
  }

  constructor() {
    this.selectionChange = new EventEmitter<any>();
    this.className = Utils.getRandomString();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const baseContext = this;
    if (this.selection) {
      this.selected = Utils.formatDate(this.selection, this.withTime);
      this.selection = Utils.formatDate(this.selection, this.withTime);
      jQuery('.' + baseContext.className).val(baseContext.selected);
    }
    jQuery('.' + baseContext.className).on('change', function () {
      if (baseContext.withTime) {
        baseContext.selected = jQuery(this).val().replace('T', ' ');
      } else {
        baseContext.selected = jQuery(this).val();
      }
      baseContext.selectionChange.emit(baseContext.selected);
    });
  }

}