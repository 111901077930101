import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ng-files',
  templateUrl: './ng-files.component.html',
  styleUrls: ['./ng-files.component.scss']
})
export class NgFilesComponent implements OnInit {
  @Input() file: string;
  @Input() multiple: boolean;
  @Input() resources: any[];
  files: any[] = [];
  baseFiles = environment.filesUrl;
  imgUrl = environment.imgUrl; 
  types: string [] = [];
  @Input()
  fileNameAttribute : string;

  constructor() { }

  ngOnInit(): void {
    if (this.multiple) {
      this.resources.forEach((resource) => {
        this.files.push(
          {
            name : this.fileNameAttribute ? resource[this.fileNameAttribute] : resource.path,
            path : resource.path
          });
        this.types.push(resource.path.split('.').pop());
      })
    } else {
      this.files.push(this.file);
    }
  }


}
