import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ghost-list-submission',
  templateUrl: './ghost-list-submission.component.html',
  styleUrls: ['./ghost-list-submission.component.scss']
})
export class GhostListSubmissionComponent implements OnInit {
  elements = new Array(5);

  constructor() {
  }

  ngOnInit() {
  }


}
