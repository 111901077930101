import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from '../../shared/models/message';
import {MessageService} from '../../shared/services/message.service';
import {ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-message-nomodal',
  templateUrl: './message-nomodal.component.html',
  styleUrls: ['./message-nomodal.component.css']
})
export class MessageNomodalComponent implements OnInit, AfterViewChecked, OnChanges {

  messages: Message[];
  message: Message = new Message();
  imgUrl = environment.imgUrl;  
  busy: Subscription;
  @Input() submission_id: string;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.submission_id = params['submission_id'];
    });
  }

  ngOnInit() {
    console.log(this.submission_id);
    this.getAllMessages(this.submission_id);
    this.consultedMessages(this.submission_id);


  }

  ngOnChanges() {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  getAllMessages(id: string) {
    this.busy = this.messageService.getMessagesBySubmissionId(id).subscribe(
      (data) => {
        this.messages = data;
        this.message = new Message();
      },
      () => {

      },
      () => {

      }
    );
  }

  consultedMessages(id: string) {
    this.busy = this.messageService.consultedMessages(id).subscribe(
      () => {

      }
    );

  }

  addMessage() {
    let msg = this.message;
    this.message = new Message();

    msg.submission = this.submission_id;
    msg.isauthor = 1;
    if (msg.content.trim() === '') {
      return;
    }
    msg.createdDate = new Date();
    msg.status = 1;
    this.messages.push(msg);


    this.messageService.sendMessage(msg).subscribe(
      (data) => {
        this.messages[this.messages.length - 1].status = 2;

      },
      () => {
        this.messages[this.messages.length - 1].status = -1;


      }
    );

  }

}


