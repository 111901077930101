<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->

    <div class="modal-content">
      <div class="modal-header">
        <h2><i class="icon-mail"></i>  {{ 'DiscussCorrector' | translate }} </h2>


        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="message-chat">

          <div [ngBusy]="busy" class="message-chat-history" #scrollMe>


            <div class="chat-item "
                 [ngClass]="{'object-unsent': message.isauthor==1&& message.status==-1,
                            'object-sending': message.isauthor==1&&message.status==1,
                            'object-user': message.isauthor==0,
                            'object-me': message.isauthor==1 || message.status==2 }"
                 *ngFor="let message of messages; let i = index;let last= last">

              <div class="chat-item-content">

                <div class="chat-item-content-inner">
                  <div  class="msg">
                    <p>{{message.content}}</p>
                  </div>


                  <span class="time-and-date"> {{message.createdDate | date:'HH:mm, M/d/yy'}}
                  </span>

                </div>
              </div>
              <img *ngIf="message.status==1" width="3%"   [src]="imgUrl+'LebWZMZKlYYSaJ80eP8zNn0zmD3LOkZVZdo4QOkq.gif'">

            </div>


          </div>


        </div>
      </div>
      <div class="modal-footer">
        <div class="type-message">
          <form>
            <div class="type-form">
              <textarea [(ngModel)]="message.content" type="text" class="input-write form-control"
                        placeholder="Type a message..." name="message"></textarea>

            </div>


          </form>

        </div>
        <div class="button-wrap">
          <button (click)="addMessage()" class="btn btn-primary" type="submit">
            Envoyer <i class="fas fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>




