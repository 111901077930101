import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from '../../utils';
import {UploadFileService} from '../../services/upload-file.service';
import { Subscription } from 'rxjs';
declare var jQuery: any;
@Component({
  selector: 'app-ng-file',
  templateUrl: './ng-file.component.html',
  styleUrls: ['./ng-file.component.css']
})
export class NgFileComponent implements OnInit {
  @Output()
  selectionChange: EventEmitter<any>;
  filePath: any;
  file = null;
  busy:Subscription;

  constructor(private uploadFileService: UploadFileService) {
    this.selectionChange = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  @Input()
  get selection() {
    return this.file;
  }

  set selection(value) {
    this.file = value;
  }

  handleFileInput(event) {
    this.file = event.target.files[0] as File;
    this.busy = this.uploadFileService.uploadFile(this.file).subscribe(data => {
        this.filePath = data.resource.path;
        this.selectionChange.emit(this.filePath);
      }, error => {});
  }


}
