import { Congress } from './../../shared/models/congress';
import { StorageService } from './../../shared/services/storage.service';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UIUtils } from '../ui-utils';
import AgoraRTC, { ClientRole, IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { environment } from 'src/environments/environment';
import { ElementRef } from '@angular/core';
import { User } from 'src/app/shared/models/user';


declare let jQuery: any;

@Component({
  selector: 'app-ui-videoconf',
  templateUrl: './ui-videoconf.component.html',
  styleUrls: ['./ui-videoconf.component.css']
})
export class UiVideoconfComponent implements OnInit, AfterViewInit, OnDestroy {

  static INSTANCE;

  @Input()
  token: string;

  @Input()
  channelName: string;

  @Input()
  uid: number;

  @Input()
  congressId: string;

  @Input()
  congress: Congress;

  localTracks = {
    videoTrack: null,
    audioTrack: null
  };

  remoteUsers = {};
  agoraJoined = false;

  agoraOptions: AgoraOptions;

  localTrackState = {
    screenTrackEnabled: false,
    videoTrackEnabled: true,
    audioTrackEnabled: true,
    fullScreenEnabled: false
  };

  private client: IAgoraRTCClient;

  cameras: Camera[] = [];

  initialHeight: number;
  initialWidth: number;
  userTrackIds: string[] = [];
  isPin: boolean = false;
  user: User;
  isLocalPlayer: boolean = false;
  idPlayer: string = null;
  fullscreenHeight: number;
  fullscreenWidth: number;
  isPinUsers: boolean[] = [];
  isPinLocalPlayer: boolean = false;
  track: string;
  userIndex: number = -1;
  userState: UserState[] = [];
  justLoggedIn: boolean = true;
  shareScreenError: boolean = false;
  clientX: number;
  clientY: number;
  filesUrl = environment.filesUrl;
  imgUrl = environment.imgUrl;   

  constructor(private el: ElementRef, private storagService: StorageService) {
    this.user = this.storagService.read(StorageService.USER_KEY);
    this.initAgoraClient();
    UiVideoconfComponent.INSTANCE = this;
  }

  ngOnInit(): void {
    this.agoraOptions = {
      appid: environment.agoraAPPID,
      channel: this.channelName,
      uid: this.uid + '_' + this.user.first_name + '_' + this.user.last_name,
      token: this.token,
      role: 'host' // TODO implement to support max 17 cucurrent users
    };
    this.fullscreenHeight = window.screen.height;
    this.fullscreenWidth = window.screen.width;
    // this.testCameras(); // MUST BE DELETED AFTER TEST
  }

  @HostListener('window:beforeunload', ['$event'])
  ngOnDestroy() {
    if (this.localTrackState.videoTrackEnabled) {
      this.muteVideo();
    }
    this.leave();
  }

  ngAfterViewInit() {
    this.initialHeight = document.getElementById("video-session-container").offsetHeight;
    this.initialWidth = document.getElementById("video-session-container").offsetWidth;
    jQuery('#scrolly').css('height', this.initialHeight - 55 + 'px');
    if (!UiVideoconfComponent.INSTANCE.hasPin()) {
      UIUtils.resizeCamera(this.initialHeight);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!UiVideoconfComponent.INSTANCE.hasPin()) {
      UIUtils.resizeCamera(this.initialHeight, null, this.cameras.length > 0, this.localTrackState.fullScreenEnabled);
    }
  }

  removeCameraByUID(uid: string) {
    UiVideoconfComponent.INSTANCE.cameras = this.cameras.filter(camera => camera.uid !== uid);
    if (!UiVideoconfComponent.INSTANCE.hasPin()) {
      UIUtils.resizeCamera(this.initialHeight);
    } else {
      UiVideoconfComponent.INSTANCE.manageScreenResize();
    }
  }

  addCamera(uid: string) {
    const camera = new Camera();
    camera.uid = uid;
    if (!UiVideoconfComponent.INSTANCE.cameras.find(item => item.uid === uid)) {
      UiVideoconfComponent.INSTANCE.cameras.push(camera);
      if (!UiVideoconfComponent.INSTANCE.hasPin()) {
        UIUtils.resizeCamera(this.initialHeight);
      } else {
        UiVideoconfComponent.INSTANCE.manageScreenResize();
      }
    }
  }

  testCameras() {
    setTimeout(() => {
      for (let i = 0; i < 20; i++) {
        this.addCamera(i + '_' + 'firstName' + '_' + 'lastName');
        this.userTrackIds.push(i + '_' + 'firstName' + '_' + 'lastName');
        this.isPinUsers.push(false);
        this.testJoinAgora(i + '_' + 'firstName' + '_' + 'lastName')
      }
    }, 2000);
  }

  testJoinAgora(id) {
    setTimeout(() => {
      jQuery("#player-" + id).append('<div id="video-off-' + id + '" style="background-color: #000000;width: 100%; height: 100%"></div>');
      UiVideoconfComponent.INSTANCE.addButtonsCameraOff(id);
    }, 2000)
  }


  /**
   * Agora APIS
   */

  private initAgoraClient() {
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

    // add event listener to play remote tracks when remote users join, publish and leave.
    this.client.on('user-published', this.handleUserPublished);
    this.client.on('user-joined', this.handleUserJoined);
    this.client.on('user-left', this.handleUserLeft);
    this.client.on('user-unpublished', this.handleUserUnpublished);
    this.client.on('user-info-updated', this.handleUserUpdates);
  }

  public async joinAgora() {
    this.shareScreenError = false;
    this.agoraJoined = true;
    // Set AgoraClient Role 'host' or 'audience'
    await this.client.setClientRole(this.agoraOptions.role);
    // join a channel and create local tracks, we can use Promise.all to run them concurrently
    const [uid, audioTrack, videoTrack] = await Promise.all([
      // join the channel
      this.client.join(this.agoraOptions.appid, this.agoraOptions.channel, this.agoraOptions.token || null, this.agoraOptions.uid),
      // create local tracks, using microphone and camera
      AgoraRTC.createMicrophoneAudioTrack(),
      this.localTrackState.screenTrackEnabled ? AgoraRTC.createScreenVideoTrack({ optimizationMode: "detail" }).catch(e => { UiVideoconfComponent.INSTANCE.createScreenTrack(e) }) : AgoraRTC.createCameraVideoTrack()

    ]);
    this.agoraJoined = true;
    if (!this.shareScreenError) {
      this.agoraOptions.uid = uid;
      this.localTracks.audioTrack = audioTrack;
      this.localTracks.videoTrack = videoTrack;

      // play local video track
      this.localTracks.videoTrack.play('local-player');

      // publish local tracks to channel
      await this.client.publish(Object.values(this.localTracks));
      this.addButtons(this.localTracks.videoTrack._ID, true);
      if (!this.localTrackState.audioTrackEnabled) {
        await this.localTracks.audioTrack.setEnabled(false);
      }
      if (!this.localTrackState.screenTrackEnabled && !this.localTrackState.videoTrackEnabled) {
        await this.localTracks.videoTrack.setEnabled(false);
      }
      if (this.localTrackState.screenTrackEnabled) {
        this.localTracks.videoTrack.addListener('track-ended', () => {
          this.shareScreen();
        });
      }
      if (this.justLoggedIn) {
        this.muteAudio();
        this.muteVideo();
        this.justLoggedIn = false;
      }
    }
  }

  async createScreenTrack(e) {
    if (e.code == 'PERMISSION_DENIED') {
      this.shareScreenError = true;
      this.shareScreen();
      return;
    }
  }

  handleUserJoined(user) {
    const id = user.uid;
    UiVideoconfComponent.INSTANCE.remoteUsers[id] = user;
    let index = UiVideoconfComponent.INSTANCE.findUserIndex(user.uid);
    if (index == -1) {
      UiVideoconfComponent.INSTANCE.userTrackIds.push(user.uid);
      UiVideoconfComponent.INSTANCE.isPinUsers.push(false);
    }
  }

  handleUserLeft(user) {
    const uid = user.uid;
    delete UiVideoconfComponent.INSTANCE.remoteUsers[uid];
    UiVideoconfComponent.INSTANCE.removeCameraByUID(uid);
  }

  async handleUserPublished(user, mediaType) {
    await UiVideoconfComponent.INSTANCE.subscribeAgora(user, mediaType);
  }

  async subscribeAgora(user, mediaType) {
    const uid = user.uid;
    // subscribe to a remote user
    await UiVideoconfComponent.INSTANCE.client.subscribe(user, mediaType);

    UiVideoconfComponent.INSTANCE.addCamera(uid);

    setTimeout(() => {
      // if the video wrapper element is not exist, create it.
      if (mediaType === 'video') {
        // play the remote video.
        user.videoTrack.play(`player-${uid}`);
        this.addButtons(user.videoTrack._ID, false, uid, this.isPinUsers.length - 1);
        if (UiVideoconfComponent.INSTANCE.userState[uid]) {
          UiVideoconfComponent.INSTANCE.userState[uid].muteVideo = 0;
        }
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
        if (UiVideoconfComponent.INSTANCE.userState[uid]) {
          UiVideoconfComponent.INSTANCE.userState[uid].muteAudio = 0;
        }
      }
      if (mediaType !== 'video') {
        this.addCameraVideoOff(uid);
      }
    }, 1000);
  }

  public async handleUserUnpublished(user, mediaType) {
    if (mediaType === "video") {
      UiVideoconfComponent.INSTANCE.addCameraVideoOff(user.uid);
    }
  }

  public async handleUserUpdates(user, msg) {
    if (!UiVideoconfComponent.INSTANCE.cameras.find(item => item.uid === user)) {
      if (!UiVideoconfComponent.INSTANCE.userState[user]) {
        UiVideoconfComponent.INSTANCE.userState[user] = new UserState();
      }
      if (msg == 'mute-video') {
        UiVideoconfComponent.INSTANCE.userState[user].muteVideo = 1
      }
      if (msg == 'mute-audio') {
        UiVideoconfComponent.INSTANCE.userState[user].muteAudio = 1
      }
      if (UiVideoconfComponent.INSTANCE.userState[user].muteAudio == 1 && UiVideoconfComponent.INSTANCE.userState[user].muteVideo == 1) {
        const camera = new Camera();
        camera.uid = user;
        UiVideoconfComponent.INSTANCE.cameras.push(camera);
        setTimeout(() => {
          UiVideoconfComponent.INSTANCE.addCameraVideoOff(user);
          if (UiVideoconfComponent.INSTANCE.hasPin()){
            UiVideoconfComponent.INSTANCE.manageScreenResize();
          } else {
            UIUtils.resizeCamera(this.initialHeight);
          }
        }, 2000);
      }
    }
  }

  addCameraVideoOff(uid) {
    jQuery("#player-" + uid).append('<div id="video-off-' + uid + '" style="background-color: #000000;width: 100%; height: 100%"></div>');
    let index = UiVideoconfComponent.INSTANCE.findUserIndex(uid);
    UiVideoconfComponent.INSTANCE.addButtonsCameraOff(uid, index);
  }

  public async muteAudio() {
    if (this.localTrackState.audioTrackEnabled) {
      await this.localTracks.audioTrack.setEnabled(false);
      this.localTrackState.audioTrackEnabled = false;
    } else {
      await this.localTracks.audioTrack.setEnabled(true);
      this.localTrackState.audioTrackEnabled = true;
    }
  }

  public async muteVideo() {
    if (this.localTrackState.videoTrackEnabled) {
      await this.localTracks.videoTrack.setEnabled(false);
      this.localTrackState.videoTrackEnabled = false;
    } else {
      await this.localTracks.videoTrack.setEnabled(true);
      this.addButtons(this.localTracks.videoTrack._ID, true);
      this.localTrackState.videoTrackEnabled = true;
    }
  }

  public async shareScreen() {
    await this.leave();
    this.localTrackState.screenTrackEnabled = !this.localTrackState.screenTrackEnabled;
    await this.joinAgora();
  }

  public async leave() {
    // tslint:disable-next-line:forin
    for (const trackName in this.localTracks) {
      const track = this.localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        this.localTracks[trackName] = undefined;
      }
    }

    // remove remote users and player views
    this.remoteUsers = {};
    this.agoraJoined = false;

    // leave the channel
    await this.client.leave();

    this.cameras = [];
    if (!UiVideoconfComponent.INSTANCE.hasPin()) {
      UIUtils.resizeCamera(this.initialHeight);
    }
  }

  /**
   * AGORA APIS END
   */

  @HostListener('document:keydown.escape', ['$event'])
  keyDown() {
    this.localTrackState.fullScreenEnabled = false;
    if (UiVideoconfComponent.INSTANCE.hasPin()) {
      UiVideoconfComponent.INSTANCE.manageScreenResize();
    }
  }

  fullScreen() {
    this.localTrackState.fullScreenEnabled = !this.localTrackState.fullScreenEnabled;
    this.localTrackState.fullScreenEnabled ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    var elem = document.getElementById("video-session-container");
    jQuery('#scrolly').css('height', this.fullscreenHeight - 55 + 'px');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      if (UiVideoconfComponent.INSTANCE.hasPin()) {
        UiVideoconfComponent.INSTANCE.manageScreenResize();
      }
    }
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      jQuery('#scrolly').css('height', this.initialHeight - 55 + 'px');
      document.exitFullscreen();
      if (UiVideoconfComponent.INSTANCE.hasPin()) {
        UiVideoconfComponent.INSTANCE.manageScreenResize();
      }
    }
  }

  addButtons(className, isLocalPlayer, id = null, index = null) {
    let d = document.getElementById('video-off-' + id)
    if (d) {
      do {
        jQuery('#video-off-' + id).remove();
        d = document.getElementById('video-off-' + id);
      } while (d != null);
    }
    const that = this;
    const name = isLocalPlayer ? 'Vous' : id.split('_')[1] + ' ' + id.split('_')[2];
    setTimeout(() => {
      var d1 = document.getElementById('agora-video-player-' + className);
      if (d1) {
        d1.insertAdjacentHTML('beforeend', '<img [src]="filesUrl+ IhVQEr8V6GlSInjVlr9X4oskRmmTl1qtvGQByoId.svg" class="mt-3 video-' + className + ' ' + index + '" style="position: absolute;width: 25px;height: 25px;background-color: #1b1f29;left: 85%;padding: 7px;border-radius: 5px;"/>');
        d1.insertAdjacentHTML('beforeend', '<div class="h6" style="position: absolute;bottom: 0.25rem; left: 0.25rem;padding: .125rem .25rem;font-weight: 500;font-size: .75rem;line-height: 1rem;border-radius: .25rem;color: #fff;background: #1b1f29;">' + name + '</div>');
        document.body.addEventListener('click', function (event: any) {
          if ((<HTMLInputElement>event.target).classList[1] == 'video-' + className) {
            if (that.clientX != event.clientX || that.clientY != event.clientY) {
              that.clientX = event.clientX;
              that.clientY = event.clientY
              that.managePinButtonClick(event, className, isLocalPlayer, id);
            }
          }
        });
      }
    }, 1000);
  }

  addButtonsCameraOff(id, index = null) {
    const that = this;
    const name = id.split('_')[1] + ' ' + id.split('_')[2];
    setTimeout(() => {
      var d1 = document.getElementById('video-off-' + id);
      if (d1) {
        d1.insertAdjacentHTML('beforeend', '<img [src]="filesUrl+ IhVQEr8V6GlSInjVlr9X4oskRmmTl1qtvGQByoId.svg" class="mt-3 camera-off-' + id + ' ' + index + '" style="position: absolute;width: 25px;height: 25px;background-color: #1b1f29;left: 85%;padding: 7px;border-radius: 5px;"/>');
        d1.insertAdjacentHTML('beforeend', '<div class="h6" style="position: absolute;bottom: 0.25rem; left: 0.25rem;padding: .125rem .25rem;font-weight: 500;font-size: .75rem;line-height: 1rem;border-radius: .25rem;color: #fff;background: #1b1f29;">' + name + '</div>');
        document.body.addEventListener('click', function (event: any) {
          if ((<HTMLInputElement>event.target).classList[1] == 'camera-off-' + id) {
            if (that.clientX != event.clientX || that.clientY != event.clientY) {
              that.clientX = event.clientX;
              that.clientY = event.clientY
              that.managePinButtonClick(event, null, false, id);
            }
          }
        });
      }
    }, 100);
  }

  managePinButtonClick(event, className, isLocalPlayer, id) {
    this.editPinStatus(event);
    this.isLocalPlayer = isLocalPlayer;
    this.idPlayer = id != null ? id : this.idPlayer;
    if (this.isPinLocalPlayer || this.isPinUsers[(<HTMLInputElement>event.target).classList[2]]) {
      UiVideoconfComponent.INSTANCE.manageScreenResize();
      if (isLocalPlayer) {
        jQuery('#local-player').insertAfter('#pin-div');
        let index = this.findUserIndex(this.idPlayer);
        if (index != -1) {
          this.pinPlayer(index, this.idPlayer);
        }
      } else {
        jQuery('#player-' + id).insertAfter('#pin-div');
        jQuery('#local-player').insertAfter('#first-player');
      }
      UiVideoconfComponent.INSTANCE.isPin = true;
    }
    else {
      jQuery('#scrolly').css('height', this.initialHeight - 55 + 'px');
      UIUtils.resizeCamera(this.initialHeight, this.track);
      let index = this.findUserIndex(this.idPlayer);
      isLocalPlayer ? jQuery('#local-player').insertAfter('#first-player') : index == 0 ? jQuery('#player-' + id).insertAfter('#second-player') : jQuery('#player-' + id).insertAfter('.player-' + (parseInt(id) - 1));
      UiVideoconfComponent.INSTANCE.isPin = false;
    }
  }

  pinPlayer(index, id) {
    if (index == 0) {
      jQuery('#player-' + id).insertAfter('#second-player')
    } else {
      jQuery('#player-' + id).insertAfter('.player-' + (parseInt(id) - 1))
    }
  }

  findUserIndex(id) {
    return this.userTrackIds.indexOf(id);
  }

  editPinStatus(event) {
    if ((<HTMLInputElement>event.target).classList[2] == 'null') {
      this.isPinLocalPlayer = !this.isPinLocalPlayer;
      if (this.isPinLocalPlayer && this.userIndex != -1) {
        this.isPinUsers[this.userIndex] = false
      }
    } else {
      this.isPinUsers[(<HTMLInputElement>event.target).classList[2]] = !this.isPinUsers[(<HTMLInputElement>event.target).classList[2]];
      this.userIndex = parseInt((<HTMLInputElement>event.target).classList[2]);
      if (this.isPinUsers[(<HTMLInputElement>event.target).classList[2]]) {
        this.isPinLocalPlayer = false;
      }
    }
  }

  manageScreenResize() {
    const isLocalPlayer = this.isLocalPlayer;
    const id = this.idPlayer;
    setTimeout(() => {
      const height = this.localTrackState.fullScreenEnabled ? this.fullscreenHeight : this.initialHeight;
      const width = this.localTrackState.fullScreenEnabled ? this.fullscreenWidth : this.initialWidth;
      const screenWidth = width * 0.8;
      isLocalPlayer ? UIUtils.editPlayersStyles('#local-player', width * 0.8 + 'px', width * 0.8 + 'px', screenWidth * 0.5 + 'px', '10%', '10%') : UIUtils.editPlayersStyles('#player-' + id, width * 0.8 + 'px', width * 0.8 + 'px', screenWidth * 0.5 + 'px', '10%', '10%');
      isLocalPlayer ? jQuery('#local-player').css('height', screenWidth * 0.5 + 'px') : jQuery('#player-' + id).css('height', screenWidth * 0.5 + 'px');
      isLocalPlayer ? jQuery('#local-player').css('background-color', 'black') : jQuery('#player-' + id).css('background-color', 'black');
      jQuery('#scrolly').css('height', height * 0.2 + 'px');
      this.resizeScreens(id);
    }, 200);
  }

  resizeScreens(id) {
    let isLocal = false;
    for (let track of this.userTrackIds) {
      if (track != id) {
        UIUtils.editPlayersStyles('#player-' + track, '180px', '180px', '110px', '2px', '2px');
      } else {
        if (!this.isLocalPlayer) {
          isLocal = true;
          this.track = track;
        }
      }
    }
    if (isLocal) {
      UIUtils.editPlayersStyles('#local-player', '180px', '180px', '110px', '2px', '2px');
    }
  }

  hasPin () {
    return this.isPin;
  }

}

export class Camera {
  uid: string;
  label: string;
}

export class AgoraOptions {
  appid: string;
  channel: string;
  uid: any;
  token: string;
  role: ClientRole;
}

export class UserState {
  muteVideo: number = 0;
  muteAudio: number = 0;
}