import { ConfigLandignPage } from './models/ConfigLandingPage';
import {Congress} from './models/congress';
/**
 * Created by Abbes on 30/06/2017.
 */
import { Author } from './models/submission';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { User } from './models/user';
import * as moment from 'moment';
import { RequestQuotationFormTitles  } from './models/RequestQuotationFormTitles';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



declare var jQuery: any;
declare var swal: any;


/**
 * Created by Vyndee on 27/03/2017.
 */
export class Utils {
  static initCroppie(id, file, path = null) {
    const that = this;
    let uploadCrop = that.setCroppie(id);
    if (!path) {
      const fr = new FileReader();
      fr.onload = function (e) {
        setTimeout(function () {
          uploadCrop.croppie('bind', {url: fr.result});
        }, 50)
      };
      fr.readAsDataURL(file);
    } else {
      const myPath = environment.filesUrl + path;
      setTimeout(function () {
        uploadCrop.croppie('bind', {url: myPath})
        .catch(err => {
          uploadCrop.croppie('destroy');
          uploadCrop = that.setCroppie(id);
        });
      }, 50)
    }
    return uploadCrop;
  }

  static setCroppie(id) {
    let uploadCrop = jQuery('#' + id).croppie({
      enableExif: true,
      viewport: {
        width: 350,
        height: 350,
        type: 'square'
      },
      boundary: {
        width: 370,
        height: 370
      }
    });
    return uploadCrop;
  }

  static convertDateServer2(date: string) {
    return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
  }

  static convertDate2(date: string) {
    if (date) {
      return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
    }
    return null;
  }

  static initSelect2(className: string, isSearch?: boolean) {
    const select = jQuery('.' + className);
    if (isSearch === false) {
      select.select2({
        minimumResultsForSearch: Infinity
      });
    } else {
      select.select2();
    }

  }

  static initCheckBox(className: string) {
    jQuery('.' + className).uniform();
  }

  static setCheckBoxValue(className: string, value) {
    setTimeout(function () {
      if (value) {
        jQuery('.' + className).prop('checked', true);
      } else {
        jQuery('.' + className).prop('checked', false);
      }
      jQuery.uniform.update();
    }, 5);

  }

  static initSimpleDatePicker(className: string, timePicker: boolean) {
    if (timePicker) {
      jQuery('.' + className).daterangepicker({
        'singleDatePicker': true,
        'timePicker': timePicker,
        'timePicker24Hour': true,
        'timePickerIncrement': 15,
        'locale': {
          'format': 'DD/MM/YYYY HH:mm'
        }
      });
    } else {
      jQuery('.' + className).daterangepicker({
        'singleDatePicker': true,
        'timePicker': timePicker,
        'timePicker24Hour': true,
        'timePickerIncrement': 15,
        'locale': {
          'format': 'DD/MM/YYYY'
        }
      });

    }
  }


  static getRandomString(n: number = 10, pos: number = 0) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < n; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text + pos;
  }

  static generateRandomNumber(x: number) {
    if (x === 1) { return Math.floor(Math.random() * 9); }
    return Math.floor(Math.pow(10, x - 1)   + Math.random() * Math.pow(10, x - 1) * 9);
  }
  public static zero(n: number) {
    if (n < 10) {
      return '0' + n;
    }
    return n;
  }

  static getSubmissionStatus() {
    return [
      { value: null, key: 'HOME.ALL' },
      { value: 0, key: 'SUBMISSION.SUBMISSIONS_IN_PENDING' },
      { value: 6, key: 'SUBMISSION.REVISED' },
      { value: 1, key: 'SUBMISSION.SELECTED' },
      { value: 2, key: 'SUBMISSION.PROGRESSING' },
      { value: 3, key: 'SUBMISSION.DECLINED' },
      { value: 4, key: 'SUBMISSION.WATING_FILES' },
      { value: 5, key: 'SUBMISSION.FILES_SUBMITTED' },
    ];
  }

  static configDataTables() {
    /* jQuery.extend(jQuery.fn.dataTable.defaults, {
     autoWidth: false,
     columnDefs: [{
     orderable: false,
     width: '100px',
     targets: [5]
     }],
     dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
     language: {
     search: '<span>Filter:</span> _INPUT_',
     lengthMenu: '<span>Show:</span> _MENU_',
     paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
     },
     drawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
     },
     preDrawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
     }
     });*/
  }


  private static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  static swalMessage(key: string, message: string, type: string, transalteService: TranslateService) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      swal(res[0], res[1], type);
    });
  }
static createQuotationMessage(messages: any, congressId: string): RequestQuotationFormTitles  {
  const { first_step_message, second_step_message, third_step_message } = messages[0];
  return {
    first_step_message,
    second_step_message,
    third_step_message,
    congress_id: congressId
  };
}

  static initializeDataTables(timout: number, columnNumber: number, orderBy?: number) {
    // Basic datatable
    const tableListStation = jQuery('.datatable-basic');
    if (jQuery.fn.DataTable.isDataTable('.datatable-basic')) {
      tableListStation.dataTable().fnDestroy();
    }
    if (orderBy) {
      setTimeout(function () {
        tableListStation.DataTable({
          order: [[orderBy, 'desc']],
          columnDefs: [{
            targets: [columnNumber - 1]
          }],
          buttons: {
            dom: {
              button: {
                className: 'btn btn-default'
              }
            },
            buttons: [
              'excelHtml5',
              'pdfHtml5'
            ],
            fixedColumns: true
          }
        });
      }, timout);
    } else {
      setTimeout(function () {
        tableListStation.DataTable({
          columnDefs: [{
            targets: [columnNumber - 1]
          }],
          buttons: {
            dom: {
              button: {
                className: 'btn btn-default'
              }
            },
            buttons: [
              'excelHtml5',
              'pdfHtml5'
            ]
          }
        });
      }, timout);
    }
  }

  static reInitializeDataTables(timeout: number, columnNumber: number, orderBy?: number) {
    // Basic datatable
    const tableListStation = jQuery('.datatable-basic');
    tableListStation.dataTable().fnDestroy();
    this.initializeDataTables(timeout, columnNumber, orderBy);
  }

  static convertDate(date: string) {
    if (date) {
      const HH = date.substring(11, 13);
      const mm = date.substring(14, 16);
      return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4) + (HH && mm ? ' ' + HH + ':' + mm : '');
    }
    return null;
  }

  static convertTime(time: string) {
    if (time) {
      return time.substring(0, 5);
    }
    return null;
  }

  static convertRealDate(date: string, time?: string) {
    if (date) {
      if (!time) {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1), +date.substring(0, 2));
      } else {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1),
          +date.substring(0, 2), +time.substring(0, 2), +time.substring(3, 5));
      }
    }
    return null;
  }

  static getModalTemplate() {
    return '<div class="modal-dialog modal-lg" role="document">\n' +
      '  <div class="modal-content">\n' +
      '    <div class="modal-header">\n' +
      '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
      '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
      '    </div>\n' +
      '    <div class="modal-body">\n' +
      '      <div class="floating-buttons btn-group"></div>\n' +
      '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>\n';
  }

  static getPreviewZoomButtonClasses() {
    return {
      toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
      fullscreen: 'btn btn-default btn-icon btn-xs',
      borderless: 'btn btn-default btn-icon btn-xs',
      close: 'btn btn-default btn-icon btn-xs'
    };
  }


  static getPreviewZoomButtonIcons() {
    return {
      prev: '<i class="icon-arrow-left32"></i>',
      next: '<i class="icon-arrow-right32"></i>',
      toggleheader: '<i class="icon-menu-open"></i>',
      fullscreen: '<i class="icon-screen-full"></i>',
      borderless: '<i class="icon-alignment-unalign"></i>',
      close: '<i class="icon-cross3"></i>'
    };
  }

  static initializeUploadFile(url: string, token: string, className: string,
    showRemove: boolean,
    showUpload: boolean,
    maxFileCount?: number,
    initialData?: any[],
    initialPreviewConfig?: InitialPreviewConfig[],
    translateEmptyFiles: string = 'Aucun fichier selectionné',
    allowedFileExtensions? : string[]) {
    jQuery(className).fileinput({
      uploadUrl: url, // server upload action
      uploadAsync: true,
      showRemove: showRemove,
      allowedFileExtensions: allowedFileExtensions,
      showUpload: showUpload,
      maxFileCount: maxFileCount,
      overwriteInitial: false,
      initialPreview: initialData,
      initialPreviewAsData: true,
      initialPreviewFileType: 'image', // image is the default and can be overridden in config below
      initialPreviewConfig: initialPreviewConfig,
      fileActionSettings: {
        removeIcon: '<i class="icon-trash-1"></i>',
        removeClass: 'btn btn-link btn-xs btn-icon',
        uploadIcon: '<i class="icon-upload"></i>',
        uploadClass: 'btn btn-link btn-xs btn-icon',
        zoomIcon: '<i class="fas fa-search-plus"></i>',
        zoomClass: 'btn btn-link btn-xs btn-icon',
        downloadIcon: '<i class="fas fa-download"></i>',
        downloadClass: 'btn btn-link btn-xs btn-icon',
        indicatorNew: '<i class="fas fa-file"></i>',
        indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
        indicatorError: '<i class="fas fa-exclamation-triangle"></i>',
        indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
      },
      purifyHtml: true, // this by default purifies HTML data for preview
      initialCaption: translateEmptyFiles,
      previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
      previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
      ajaxSettings: { headers: { 'Authorization': 'Bearer ' + token } },
    }).on('filebatchselected', function (event, files) {
      jQuery(className).fileinput('upload');
    });
  }

  static getInitialPreviewConfig(paths: string[],token) {

    const initialPreviewConfig: InitialPreviewConfig[] = [];
    const url = environment.baseUrl + '/files/delete-resource/';

    paths.forEach(path => {
      initialPreviewConfig.push({
        type: Utils.loadTypeFromExtension(path.substr(path.indexOf('.') + 1)),
        filetype: Utils.loadFileTypeFromExtension(path.substr(path.indexOf('.') + 1)),
        key: path,
        extra: {
          token: token
        },
        url: url + path.substring(path.lastIndexOf('/') + 1),
        downloadUrl: path
      });
    });
    return initialPreviewConfig;
  }

  static convertDateServer(date: string) {
    const HH = date.substring(11, 13);
    const mm = date.substring(14, 16);
    return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2) + (HH && mm ? ' ' + HH + ':' + mm : '');
  }

  static loadTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return 'video';
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return 'pdf';
    }
  }

  static loadFileTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return 'video/' + ext;
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return 'pdf';
    }
  }

  static compareDate(start_date, end_date) {
    const date = new Date();
    start_date = new Date(start_date);
    end_date = new Date(end_date);
    return (date >= start_date && date <= end_date);
  }

  static getYears(minYear: number) {
    const years: number[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }


  static initSelect(className: string) {
    const select = jQuery('.' + className);

    select.select2({
      dropdownAutoWidth: true
    }
    );
  }

  /* static getAuthorStatusBySubmission(submission: Submission, author: User): number {
     const authors = submission.authors ? submission.authors.map(user => user.id) : [];
     const acceptedAuthors = submission.acceptedAuthors ? submission.acceptedAuthors.map(user => user.id) : [];
     const rejectedAuthors = submission.rejectedAuthors ? submission.rejectedAuthors.map(user => user.id) : [];

     if (acceptedAuthors.indexOf(author.id) > -1) {
       return 1;
     } else if (rejectedAuthors.indexOf(author.id) > -1) {
       return -1;
     } else if (authors.indexOf(author.id) > -1) {
       return 0;
     }

   }*/

  static setValuesjQueryCmp(className: string, values: any, timeout: number) {

    if (values) {
      setTimeout(function () {
        const component = jQuery('.' + className);
        component.val(values).trigger('change');
      }, timeout);

    }
  }

  static setValueToRadioBox(className: string, value: any, timout: number) {
    const radioBox = jQuery('.' + className).prop('checked', false);
    jQuery.uniform.update(radioBox);
    if (value !== undefined) {
      setTimeout(function () {
        const radioBox = jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
        jQuery.uniform.update(radioBox);
      }, timout);
    }
  }

  static getRoomName(congressId: string, accessId: number = null, standId: number = null, organizerId: number = null, meetingId = null) {
    const code = meetingId ? congressId + '_m_' + meetingId : standId ? congressId + 's' + standId : accessId ? congressId + '' + accessId : organizerId ? congressId + 'support' + organizerId : congressId;
    return 'eventizer_room_' + code;
  }

  public static converSecondsToTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return [hours, minutes, seconds];
  }

  static getUrlMap(lat: number = 36.865504, lng: number = 10.161731, width = 585, height = 150) {
    return 'https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7Clabel:C%7C' + lat + ',' + lng + '&zoom=16&size=' + width + 'x' + height + '&maptype=roadmap&key=AIzaSyC5mDXxzWvU79wt24y17dJal-Rt_GC0gdg';

  }
  static isEmail(email: string) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
  }
  static setRadioBoxValue(className: string, value: any) {
    const radioSex = jQuery('.' + className);
    radioSex.prop('checked', false);
    jQuery("." + className + "[value=" + value + "]").prop('checked', true);
    jQuery.uniform.update('.' + className);
  }
  static getAllHours(x: number) {

    var times = [{ name: 'heure', value: '-1' }];
    var tt = 0;

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60);
      var mm = (tt % 60);
      times.push({ name: (("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2)), value: (("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2) + ':' + "00") });
      tt = tt + x;
    }
    return times;

  }
  static getPaymentOptions(congresses) {
    return {
      name: 'Mes transactions',
      search: true,
      filter: [
        {
          name: 'Par evenement',
          label: 'Par evenement',
          type: 'select',
          values: congresses,
          selected_label: '',
        },
        {
          name: 'Par Status',
          label: 'Par status',
          type: 'select',
          values: [
            {value: '0', label: 'Non validé ou non payé'},
            {value: '1', label: 'Validé ou payé'},
            {value: '2', label: 'En Cours'},
            {value: '3', label: 'Subventionné'},
            {value: '4', label: 'Payé en ligne'}
          ],
          selected_label: '',
        },
        {
          name: 'Par Méthode de paiement',
          label: 'Par Méthode de paiement',
          type: 'select',
          values: [
            {value: '1', label: ' Cash'},
            {value: '2', label: 'Chèque'},
            {value: '3', label: 'Transfert bancaire'},
            {value: '4', label: 'En Ligne'}
          ],
          selected_label: '',
        },
        {
          name: 'Prix inférieur à',
          label: 'Prix inférieur à',
          type: 'number',
          price: null,
        },
        {
          name: 'Prix supérieur à',
          label: 'Prix supérieur à',
          type: 'number',
          price: null,
        }
      ]
    };
  }

  public static setDateFormat(date) {
    return [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-') + ' ' + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2) + ':' + ('0' + (date.getSeconds())).slice(-2);
  }
  static getDateBetweenTwoDate(date1, date2) {

    var eventStartTime = new Date(date1);
    var eventEndTime = new Date(date2);
    var day = 1000 * 60 * 60 * 24;
    var dateOfEvent = [];
    var diff = (eventEndTime.getTime() - eventStartTime.getTime()) / day;

    for (let i = 0; i < diff; i++) {
      var xx = eventStartTime.getTime() + day * i;
      var yy = new Date(xx);
      dateOfEvent.push(yy)
    }
    return dateOfEvent;
  }
  static nombreJourEvent(date1, date2) {
    var eventStartTime = new Date(date1);
    var eventEndTime = new Date(date2);
    var day = 1000 * 60 * 60 * 24;
    var dateOfEvent = [];
    var diff = (eventEndTime.getTime() - eventStartTime.getTime()) / day;

    for (let i = 0; i < diff + 1; i++) {
      var xx = eventStartTime.getTime() + day * i;
      var yy = new Date(xx);
      dateOfEvent.push(yy)
    }
    return dateOfEvent;
  }
  static swalMessageWithCallback(key: string, message: string, type: string, params: any, transalteService: TranslateService, callback) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      let object = Object.assign({
        title: res[0],
        text: res[1],
        icon: type
      }, params);
      swal(object).then(
        function (isConfirm) {
          callback(isConfirm);
        }
      );
    });
  }
  static getIconArrowByLangLeftRight(lang) {
    return lang==='ar'?'../../../../assets/images/icons/arrow-left.svg':'../../../../assets/images/icons/arrow-right.svg';
  }
  static getIconArrowByLangRightLeft(lang) {
    return lang==='ar'?'../../../../assets/images/icons/arrow-right.svg':'../../../../assets/images/icons/arrow-left.svg';
  }
  static getDirectiontemplate(lang) {
    return lang==='ar'?'rtl':'ltr';
  }


  public static changeOrder(list) {
    var length = list.length, t, randonNumber;
    while (0 !== length) {
      randonNumber = Math.floor(Math.random() * length);
      length -= 1;
      t = list[length];
      list[length] = list[randonNumber];
      list[randonNumber] = t;
    }
    return list;
  }

  static initsilderImage() {
    var OptionsImage: OwlOptions;
    return OptionsImage = {
      loop: true,
      mouseDrag: true,
      touchDrag: false,
      pullDrag: false,
      autoplay: true,
      navSpeed: 700,
      stagePadding: 0,
      autoWidth: true,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1,

        },
        600: {
          items: 3
        },
        900: {
          items: 4,
        }
      },
      nav: false,
    };
  }

  static initsilderVideo() {
    var OptionsVideo: OwlOptions;
    return OptionsVideo = {
      loop: true,
      mouseDrag: true,
      touchDrag: false,
      pullDrag: false,
      autoplay: false,
      navSpeed: 700,
      stagePadding: 0,
      autoWidth: true,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1,

        },
        600: {
          items: 3
        },
        900: {
          items: 4,
        }
      },
      nav: false,
    };
  }
  public static getDateOfThisMonth(nbMonth) {
     return (new Date().getFullYear().toString() + '-' + (new Date().getMonth() + nbMonth).toString() + '-' + '01')
  }

  static getPrivateChatName(senderId, receiverId) {
    if (senderId < receiverId)
      return 'chat_' + senderId + '_' + receiverId;
    else
      return 'chat_' + receiverId + '_' + senderId;
  }
  
  static dateFormater(date){
    return moment(date).format('YYYY-MM-DDTHH');
  }

  static getAvailableDates(congress) {
    let today = new Date(moment().format('YYYY-MM-DD'));
    let dateEnd = new Date(moment(congress.end_date).format('YYYY-MM-DD'));
    let dateStart = new Date(moment(congress.start_date).format('YYYY-MM-DD'));
    let dates = []
    if (today > dateEnd) {
      return dates;
    }
    if (today < dateStart) {
      dates.push(moment(dateStart).format('YYYY-MM-DD'));
    }
    else { dates.push(moment(today).format('YYYY-MM-DD')); }
    let i = 0;
    let myDate;
    while (dates[i] != moment(congress.end_date).format('YYYY-MM-DD')) {
      myDate = today.setDate(today.getDate() + 1)
      dates.push(moment((new Date(myDate))).format('YYYY-MM-DD'))
      i++;
    }
    return dates;
  }

  static addDudation(timeStart, duration) {
    let time = moment(timeStart, 'HH:mm').add(duration, 'minutes').format("HH:mm");
    return time;
  }

  static validateTimePeriode(begin, end) {
    var beginningTime = moment(begin, 'h:mma');
    var endTime = moment(end, 'h:mma');
    return beginningTime.isAfter(endTime);
  }

  static concatenation(string1, string2) {
    let string = string1 + " " + string2
    return string;
  }

  static getDurationTimes(duree, pause, startTime, endTime) {
    let times = [];

    let time_start = moment(startTime, "HH:mm").format("HH:mm");
    let time_end = moment(endTime, "HH:mm").format("HH:mm");
    if (this.validateTimePeriode(startTime, endTime) || (startTime == endTime)) {
      time_start = moment('00:00', "HH:mm").format("HH:mm");
      time_end = moment('23:30', "HH:mm").format("HH:mm");
    }
    times.push(time_start);
    let new_time: any;
    let i = 0;
    while (times[i] < time_end) {
      new_time = pause == 0 ? moment(times[i], "HH:mm").add(duree, "minutes").format("HH:mm") : moment(times[i], "HH:mm").add(pause + duree, "minutes").format("HH:mm");
      if (new_time < time_end && moment(new_time, "HH:mm").add(duree, "minutes").format("HH:mm") <= time_end) {
        times.push(new_time);
      }
      i++;
    }
    return times;
  }

  static getValueTranslate (lang: string, item: any, columnName: string) {
    const engColumn = columnName + '_en';
    const arColumn = columnName + '_ar';
    lang = lang.toLocaleLowerCase();
    if ((lang == 'fr' && item[columnName]) || (lang == 'en' && !item[engColumn]) || (lang == 'ar' && !item[arColumn])) {
      return item[columnName];
    } else if ((lang == 'en' && item[engColumn]) || (!item[arColumn] && !item[columnName])) {
      return item[engColumn];
    } else {
      return item[arColumn];
    }
 }
 
 static compareDates(firstDate, secondDate) {
  return new Date(firstDate) > new Date(secondDate)
  }
 
 public static getLanguages() {
  return [{lang: "FR"}, {lang: "EN"}, {lang: "AR"}];
}

  static checkExist(items, newItem) {
    let exist = false;
    items.forEach(item => {
      if (item.label.toLocaleLowerCase() === newItem.toLocaleLowerCase()) {
        exist = true;
      }
    });
    return exist;
  }

 public static getUserFirstLetters(user: User) {
  return user?.first_name?.charAt(0).toUpperCase() + user?.last_name?.charAt(0).toUpperCase();
}

public static withChoices(response) {
  return response.form_input.form_input_type_id == 6 || response.form_input.form_input_type_id == 7 || response.form_input.form_input_type_id == 8 || response.form_input.form_input_type_id == 9;
}

public static getDirValue(lang:string) {
  return lang==='ar'? 'rtl' :'ltr';
}
public static setDirMode(lang: string) {
  return lang == 'ar' ? 'rtl' : 'ltr';
}

  public static capitalizeFirstLetters(str) {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  }

  public static convertDateToFrenchFormat(someDateFromt) {
    var arr = someDateFromt.toString().split(/[- :]/);
    return (arr[2] + '/' + arr[1] + '/' + arr[0]);
  }

  public static getTimeFromDate(someDateFromt) {
    var arr = someDateFromt.toString().split(/[- :]/);
    return (arr[3] + ':' + arr[4]);
  }

  public static getDefaultGenders() {
    return [
      {
        congress_id: null,
        gender_id: 1,
        label: "Homme",
        label_en: "Male",
        label_ar: "ذكر"
      },
      {
        congress_id: null,
        gender_id: 2,
        label: "Femme",
        label_en: "Female",
        label_ar: "أنثى"
      }
    ]
  }

  public static showQuestion(event, index, userResponses) {
    let ids = [];
    userResponses.forEach(res => {
      if (res.question_reference.length > 0) {
        for (let rep of res.question_reference) {
          this.hideConditionalQuestionsOfQuestionReferences(ids, rep, res);
          if (rep.reference_id == userResponses[index].form_input_id ) {
            ids.push(res.form_input_id);            
            res.show = false;
            res.oldResponses = Array.isArray(res.response) ? res.response : [res.response];
            res.response = res.type.form_input_type_id == 8 || res.type.form_input_type_id == 6 ? [] : null;
            this.displayQuestionsAndSaveUserResponses(rep, res, event, index, userResponses);
          }
        }
      } else {
        userResponses[index].response = event;
      }
    });
  }

   // hide conditional questions if their ref questions is hidden
   public static hideConditionalQuestionsOfQuestionReferences(ids, rep, res) {
    if (ids.length > 0) {
      for (let id of ids) {
        if (rep.reference_id == id && res.show) {
          res.show = false;
          res.oldResponses = Array.isArray(res.response) ? res.response : [res.response];
          res.response = res.type.form_input_type_id == 8 || res.type.form_input_type_id == 6 ? [] : null;
          ids.push(res.form_input_id);
        }
      }
    }
  }

  public static displayQuestionsAndSaveUserResponses(rep, res, event, index, userResponses) {    
    for (let response_ref of rep.response_reference) {
      if (event != null) {
        // save single response in case of radio-box, input text, select...
        if (event.length == undefined || !Array.isArray(event)) {
          if (response_ref.form_input_value_id == event) {
            res.show = true;
            userResponses[index].response = event;
          }
        } else {
          // save multiple responses in case of checkbox or multiselect
          for (let e of event) {            
            if (response_ref.form_input_value_id == e) {
              res.show = true;
              userResponses[index].response = event;
            }
          }
        }
      }
    }
  }

  public static withChoicesFormInput(response) {
    return response.form_input_type_id == 6 || response.form_input_type_id == 7 || response.form_input_type_id == 8 || response.form_input_type_id == 9;
  }

  static RemoveAccents(s) {
    const i = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖŐòóôõöőÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜŰùúûüűÑñŠšŸÿýŽž'.split('');
    const o = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUUuuuuuNnSsYyyZz'.split('');
    const map = {};
    i.forEach(function (el, idx) {
      map[el] = o[idx];
    });
    return s.replace(/[^A-Za-z0-9]/g, function (ch) {
      return map[ch] || ch;
    });
  }

  static formatDate(dateString, withTime = false) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (!withTime) {
      return `${year}-${month}-${day}`;
    }
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  static formatDateDDMMYYYY(date) {
    return date.substr(8, 2) + '/' + date.substr(5, 2) + '/' + date.substr(0, 4);
  }

  static ColorFromStyle(styleString: string): string {
    const regex = /color:\s*(.*?);/i;
    const match = regex.exec(styleString);
    if (match && match[1]) {
      return match[1];
    } else {
      return 'initial';
    }
  }

  static compareDateWithDateNow(date) {
    const currentDate = new Date();
    const endSubmissionDate = new Date(date);
    return currentDate > endSubmissionDate;
  }

  static getDaysBetweenDates(startDate, endDate) {
    const days = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);
  
    while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const day = ('0' + currentDate.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        days.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return days;
  }

  static getNumberData(datMin, dataMax) {
    const startDate = new Date(datMin);
    const endDate = new Date(dataMax);
    endDate.setDate(endDate.getDate());
    const differenceInMs = Math.abs(endDate.getTime() - startDate.getTime());
    const numberOfDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    return numberOfDays;
  }

  static isValidPhoneNumber(phoneNumber: string): boolean {
    const phonePattern: RegExp = /^[0-9]*$/;
    return phonePattern.test(phoneNumber);
  }

  static sortAccessByDate(elements: any[]): any[] {
    return elements.sort((a, b) => {
      const dateA = new Date(a[0]).getTime();
      const dateB = new Date(b[0]).getTime();
      return dateA - dateB;
    });
  }

  static capitalizeFirstLetter(name: string): string {
    if (!name) {
      return '';
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  static applyColorsToText(html: string, sanitizer: DomSanitizer): SafeHtml {
    const div = document.createElement('div');
    div.innerHTML = html;

    const elements = div.querySelectorAll('span, strong, p, h1');
    elements.forEach(element => {
      const el = element as HTMLElement;
      const style = el.getAttribute('style');
      const classList = el.classList;
      if (style) {
        const color = Utils.ColorFromStyle(style); // Make sure you have this utility method defined
        el.style.color = color;
      }

      if (classList.contains('ql-align-center')) {
        el.style.textAlign = 'center';
      }
    });

    return sanitizer.bypassSecurityTrustHtml(div.innerHTML);
  }
}




export class InitialPreviewConfig {
  caption?: string;
  size?: number;
  width?: string;
  type?: string;
  filetype?: string;
  url: string;
  key: string;
  downloadUrl?: string;
  extra?:any;
}

