<ul class="list-group">
  <li class="list-group-item list-group-item-action bg-light text-center animated fadeIn"
      *ngFor="let item of items; let i= index">{{item}} <a class="fa fa-times pull-right" aria-hidden="true"
                                                           (click)="removeItem(i)"></a></li>
  <input name="email" [placeholder]="placeholder" class="list-group-item form-control " type="text"
         [(ngModel)]="itemToAdd" email
         pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" #email="ngModel"
         [ngClass]="{'is-invalid':email.invalid && (email.dirty || email.touched)}">

  <div class="invalid-feedback">
     {{ 'EmailIncorrect' | translate }}
  </div>
  <button [disabled]="email.invalid || !email.dirty" class="btn btn-success" (click)="addItem()"> {{ 'Add' | translate }} +</button>
</ul>
