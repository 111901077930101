import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '../../utils';
declare var jQuery: any;

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.css']
})
export class FilterDateComponent implements OnInit {
  @Output() sendName: EventEmitter<string> = new EventEmitter();
  @Output() sendDate: EventEmitter<any> = new EventEmitter();
  @Input() options


  constructor() {
  }

  ngOnInit(): void {
    var timer = null;
    const that = this;
    jQuery('#filter').keyup(function(){
           clearTimeout(timer); 
          timer =setTimeout(function(){ that.searchWithName(); }, 750);
    });

  }

  searchWithName() {
      this.sendName.emit(this.options);
  }


  getMonth() {
    this.options.filter[1].date = Utils.getDateOfThisMonth(1);
    this.options.filter[2].date = Utils.getDateOfThisMonth(2);
    this.sendDate.emit(this.options);
  }

  getNextMonth() {
    this.options.filter[1].date = Utils.getDateOfThisMonth(2);
    this.options.filter[2].date = Utils.getDateOfThisMonth(3);
    this.sendDate.emit(this.options);
  }


}
