<div id="wrapper">
 
  <router-outlet></router-outlet>
  
</div>
<!-- /.wrapper -->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="list-group">
          <a routerLink="/user-profile/messages/{{item.id}}"
            class="list-group-item list-group-item-action flex-column align-items-start "
            *ngFor="let item of notfication">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{item.title}}</h5>
              <small>{{'HOME.LAST_MESSAGE'|translate}}
                : {{item.dateOfLastMessageByCorrector|date:'d/M/yyyy, H:mm'}}</small>
            </div>
            <p class="mb-1">{{'UNSEEN_MESSAGES'|translate}}Vous avez {{item.numberOfUnreadMessagesByAuthor}} message non
              lu(s)</p>
            <small>{{item.congress}}</small>
          </a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'HOME.CLOSE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
