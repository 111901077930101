import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Config, Congress } from 'src/app/shared/models/congress';
import { User } from 'src/app/shared/models/user';
import { CongressService } from 'src/app/shared/services/congress.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/shared/utils';
import { Location } from '@angular/common';
declare var jQuery: any;

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css']
})
export class ThankYouPageComponent implements OnInit {
  userId: number;
  congressId: number;
  busy: Subscription;
  token: string;
  congress: Config;
  lang;
  @ViewChild('eventTitle') eventTitle!: ElementRef;
  congressWithConfig: Congress = new Congress();
  price : number;
  constructor(private route: ActivatedRoute, private congressService: CongressService,
    private userService: UserService, private translate: TranslateService, private location: Location,) {
    this.userId = parseInt(this.route.snapshot.paramMap.get('userId'), 0);
    this.congressId = parseInt(this.route.snapshot.paramMap.get('congressId'), 0);
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });

  }

  ngOnInit(): void {
    jQuery(window).on('beforeunload', function () {
      jQuery(window).scrollTop(0);
    });
    this.getUserToken();
    this.loadCongress(this.congressId);
    this.getCongressById();
  }

  removeBackslashes(inputString: string): string {
    if (this.eventTitle) {
      // Récupérer l'élément <p> à l'aide de la référence eventTitle
      const eventTitleElement = this.eventTitle.nativeElement;
      // Récupérer le style complet depuis l'API (exemple)
      const apiStyle = inputString;
      // Supprimer les "\" de la chaîne de caractères
      const cleanedApiStyle = inputString.replace(/\\/g, '');
      // Appliquer le style propre depuis l'API
      eventTitleElement.innerHTML = cleanedApiStyle;
    }

    const cleanedString = inputString.replace(/\\/g, '');
    return cleanedString;
  }

  removeHtml() {
    const apiStyle = this.congress?.thank_type_description;
    this.removeBackslashes(apiStyle);
  }

  redirectToPayment() {
    const url = environment.backOfficeUrl + '/auth/user/' + this.userId + '/upload-payement?token=' + this.token + '&congressId=' + this.congressId;
    window.location.href = url;
  }

  getUserToken() {
    this.busy = this.userService.getUserByIdWithResponses(this.userId, this.congressId).subscribe((data: User) => {
      this.token = data.verification_code;
      this.price = data.payments[0].price;
    });
  }

  loadCongress(congressId: number) {
    this.busy = this.congressService.getConfigCongressById(congressId)
      .subscribe(
        (data: any) => {
          this.congress = data[0];
          if (this.congress?.thank_type_description) {
            this.removeHtml();
          }
        },
        (error) => {

        });
  }

  getColorFromStyle(styleString) {
    return Utils.ColorFromStyle(styleString);
  }

  getCongressById() {
    this.busy = this.congressService.getMinCongressPublic(this.congressId).subscribe((data: Congress) => {
      this.congressWithConfig = data;
    });
  };

  goBack(): void {
    this.location.back();
  }
}
