<ng-container *ngIf="toShow && type != 'description'">
  <ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'text'">
      <input (keyup)="onValueChange()" type="text" [name]="name" [(ngModel)]="values" class="boder-black"
        [ngClass]="{'invalid': required && submitted && !values }" [lang]="lang">
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <app-ng-select2 (selectionChange)="onValueChange()" [defaultValue]="'Single choice'"
        [idOption]="'form_input_value_id'" [stringOption]="'value'" [objects]="translatedOptions" [(selection)]="values" [lang]="lang"
        [multiple]="false" [required]="required" [submitted]="submitted"></app-ng-select2>
    </ng-container>

    <ng-container *ngSwitchCase="'multiselect'">
      <app-ng-select2 (selectionChange)="onValueChange()" [defaultValue]="'Multiple choice'"
        [idOption]="'form_input_value_id'" [stringOption]="'value'" [objects]="translatedOptions" [(selection)]="values" [lang]="lang"
        [multiple]="true" [required]="required" [submitted]="submitted"></app-ng-select2>
    </ng-container>

    <div *ngSwitchCase="'checklist'">
      <div class="checkbox-single" *ngFor="let op of translatedOptions; let i = index">
        <app-ng-checkbox (change)="onValueChange()" [(selection)]="checkListValues[i].selected" [label]="op.value" [lang]="lang"
          [required]="required" [submitted]="submitted">
        </app-ng-checkbox>
      </div>
    </div>

    <div *ngSwitchCase="'radio'">
      <div class="radio-single" *ngFor="let op of translatedOptions; let i = index">
        <input (change)="onValueChange()" name="{{op.form_input_id}}" [(ngModel)]="values" type="radio" [lang]="lang"
          [value]="op.form_input_value_id" [ngClass]="{'invalid': required && submitted && !values }">
        <span>{{op.value}}</span>
      </div>
    </div>

    <div *ngSwitchCase="'datetime'">
      <app-ng-datepicker (selectionChange)="onValueChange()" [(selection)]="values" [withTime]="true" [lang]="lang">
      </app-ng-datepicker>
    </div>

    <div *ngSwitchCase="'textarea'">
      <textarea (change)="onValueChange()" class="form-control boder-black" [name]="name" [(ngModel)]="values"
        [ngClass]="{'invalid': required && submitted && !values }">
      </textarea>
    </div>

    <div *ngSwitchCase="'date'">
      <app-ng-datepicker (selectionChange)="onValueChange()" [(selection)]="values" [withTime]="false">
      </app-ng-datepicker>
    </div>

    <div *ngSwitchCase="'time'">
      <input (change)="onValueChange()" type="time" [name]="name" [(ngModel)]="values" class="boder-black"
        [ngClass]="{'invalid': required && submitted && !values }">
    </div>

    <div *ngSwitchCase="'image'">
      <app-ng-file (selectionChange)="onValueChange()" [(selection)]="values"></app-ng-file>
    </div>
  </ng-container>
</ng-container>