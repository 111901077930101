<!-- Modal -->

<div class="modal fade" id="{{modal_id}}"  role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" [ngBusy]="busy">
  <div class="modal-dialog" role="document" style="width: 400px;" *ngIf="dtaesReady">
    <div class="modal-content popUp">
      <div >
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div style="text-align: center;"> 
        <h4 id="exampleModalLabel" style="padding-top: 15px;">{{'Schedulemeet'|translate }}</h4>
        <h5 style="padding-left: 20px;padding-right: 20px;">{{'MeetschduleMsg'|translate }}</h5></div>
      </div>
      <div class="modal-body" >
        <table>
          <tr>
            <td>
              <div class="form-group">
                <h5>{{'CONGRESS.DATE_START'|translate }}</h5>
                <div  style="z-index:1 !important">
                  <input type="date" class="form-control" (change)="setEndDate($event)" [(ngModel)]="meetingModel.dateStart"   [ngClass]="{'is-invalid': submitted && !meetingModel.dateStart}"   min={{congressAvailableDates[0]}} max={{congressAvailableDates[congressAvailableDates.length-1]}} >
                </div>
              </div>
            </td>
            <td>
              <div class="form-group ml-1">
                <h5>{{'MeetStartTime'|translate }}</h5>
                 
                <select class="form-control"  [(ngModel)]="meetingModel.timeStart" (change)="setEndTime($event)">
                  <option value="null">
                    {{'ChooseTime' | translate }}
                   </option>
                  <option *ngFor="let option of congessAvailableMeetingTimes;let i=index" value="{{option}}" [disabled]="disabledTime[i]">
                 {{option}}
                </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <h5>{{'CONGRESS.DATE_END'|translate }}</h5>
                <div  style="z-index:1 !important">
                  <input type="date" class="form-control" disabled  [(ngModel)]="meetingModel.dateEnd"   [ngClass]="{'is-invalid': submitted && !meetingModel.dateEnd}"  min={{congress.start_date}} max={{congress.end_date}}>
                   
                </div>
              </div>
            </td>
            <td>
              <div class="form-group ml-1">
                <h5>{{'MeetEndTime'|translate }}</h5>
                <input class="form-control"  [(ngModel)]="meetingModel.timeEnd" disabled />
              </div>
            </td>
          </tr>

        </table>
        <div class="form-group" *ngIf="congress?.config?.show_meesage_field == 1">
          <h5> {{'Message'|translate }}</h5>
          <input type="name" class="form-control" [(ngModel)]="meeting.name" placeholder="{{'EnterMessage' | translate}}">
        </div>
      </div>
      <div class="modal-footer">
        <button id="sendRequest{{modal_id}}" type="button" class="btn btn-primary  btn-block" (click)="send($event)">{{'Send'|translate }}</button>
      </div>
    </div>
  </div>
</div>