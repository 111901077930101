import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class ChatService extends GenericService {

  constructor(
    private db: AngularFireDatabase,
    private dbStore: AngularFirestore,
  ) {
    super();
  }

  getMessagesList(chatId: string) {
    return this.db.object('chat/' + chatId + '/messages').valueChanges();
  }

  sendMessage(user: User, currentMessage: string, chatId: string, privilegeIds) {
    const messageData = {
      senderId: user.user_id,
      messageBody: currentMessage,
      senderName: user.first_name + ' ' + user.last_name,
      privilegeIds: privilegeIds,
      timeStamp: new Date().getTime()
    };
    if (currentMessage !== '') {
      this.db.list('chat/' + chatId + '/messages').push(messageData);
    }
  }

  updateUserData(chatId, key, updates) {
    return this.db.list('chat/' + chatId + '/users_data').update(key, updates);
  }


  addUsersData(user: User, chatId: string, unreadMessages, privilegeId) {
    const userData = {
      userId: user.user_id,
      userName: user.first_name + ' ' + user.last_name,
      unreadMessages: unreadMessages,
      privilegeId: privilegeId,
      status: 'online',
      nbSession: 0
    };
    this.db.list('chat/' + chatId + '/users_data').push(userData);
  }

  getUsersData(chatId) {
    return this.db.object('chat/' + chatId + '/users_data').valueChanges();
  }

  getMyMessages(chatId) {
    return this.db.list('chat/' + chatId + '/messages').snapshotChanges()
  }

  getGeneralData(chatId) {
    return this.db.list('chat/' + chatId + '/general_data').snapshotChanges()
  }

  updateGeneralData(chatId, key, updates) {
    return this.db.list('chat/' + chatId + '/general_data').update(key, updates);
  }

  addGeneralData(chatId, data) {
    return this.db.list('chat/' + chatId + '/general_data').push(data);
  }
  
  sendMessagePrivate(senderUser: User, currentMessage: string, chatId: string, chatName: string) {
    const messageData = {
      user_id: senderUser.user_id,
      messageBody: currentMessage,
      first_name: senderUser.first_name ,
      last_name:senderUser.last_name,
      timeStamp: new Date().getTime()
    };
    if (currentMessage !== '') {
   
        this.db.list('chat/' + chatId + '/participants_chat/chats/' +  chatName).push(messageData);

    }
  }

  getAllMessages(chatId,chatName) {
      return this.db.list('chat/' + chatId + '/participants_chat/chats/' + chatName).snapshotChanges()

  }

  saveHistory(chatId, senderUser, receiverUser: User,unreadMessages,hasUnreadMessages) {
    const userData = {
      user_id: receiverUser.user_id,
      first_name: receiverUser.first_name ,
      last_name: receiverUser.last_name,
      unreadMessages: unreadMessages, 
      hasUnreadMessages:hasUnreadMessages 
          
    };
    this.db.list('chat/' + chatId + '/participants_chat/chat_history/' + senderUser.user_id).push(userData);
  }

  getHistory(chatId, user: User) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history/' + user.user_id).snapshotChanges()

  }
  getALLHistory(chatId) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history').snapshotChanges()

  }
  
  updateUserHistoryPrivateChat(chatId, key, updates,user_id) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history/' +user_id ).update(key, updates);
  }

  addUsersDataPrivateChat(user: User, chatId: string, unreadMessages,chatName) {
    const userData = {
      userId: user.user_id,
      userName: user.first_name + ' ' + user.last_name,
      unreadMessages: unreadMessages,
    };
    this.db.list('chat/' + chatId +  '/participants_chat/chats/' + chatName+'/user_data').push(userData);
  }

  getLandingPageData(chatId) {
    return this.db.list('chat/' + chatId + '/landing_page').snapshotChanges() as any as Observable<any[]>;;
  }

  updateLandingPageData(chatId, key, updates) {
    return this.db.list('chat/' + chatId + '/landing_page').update(key, updates);
  }

  addLandingPageData(chatId, data) {
    return this.db.list('chat/' + chatId + '/landing_page').push(data);
  }
}
